import { toast } from "react-toastify";
import api from "../../../helpers/jwt/apiFetch";

const getJuries = async (setState) => {
    const response = await api('/juries', 'GET');
    if(response.ok){
        setState({
            data: response.data,
            actives: response.data.filter(jury => jury.es_habilitado == 1).length,
        });
        return;
    }
    toast('Ups, ocurrió al obtener el listado de jurados', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { getJuries };