import { toast } from "react-toastify";
import FormDataRecursive from "../../../utils/FormDataRecursive";

const requestResetPassword = async (data, setIsSubmitting) => {
    setIsSubmitting(true);
    let formData = new FormDataRecursive();
    formData.appendRecursive(data);

    let response = await fetch(`${process.env.REACT_APP_API_URL}/forget-password`, {
        method: 'POST',
        body: formData,
    });

    setIsSubmitting(false);

    response = await response.json();

    if(response.ok) {
        toast('Te hemos enviado un enlace a tu correo para reestablecer tu contraseña', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        return;
    }

    Object.values(response.errors).forEach(error => {
        toast(error[0], {
            position: toast.POSITION.TOP_RIGHT,
            type: 'error'
        });
    });
}

export { requestResetPassword }