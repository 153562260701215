import { toast } from "react-toastify";
import api from "../../../helpers/jwt/apiFetch"

const getPostulations = async (setState) => {
    const response = await api('/postulations', 'GET');
    if(response.ok){
        const { data } = response;
        setState({
            actives: data.postulations.length,
            postulations: data.postulations,
            indicators: data.states_info
        });
        return;
    }
    toast('Ups, ocurrió al obtener las postulaciones', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const notifyCompanies = async () => {
    const response = await api('/send_alert_postulation_email', 'GET');
    if(response.ok){
        toast(response.mensaje, {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        return;
    }
    toast('Ups, ocurrió al notificar a los usuarios.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const generateExcelEvaluations = async(date = new Date()) => {
    let response = await fetch(`${process.env.REACT_APP_API_URL}/report/evaluations?anio=${ date.getFullYear() }`,{
        method: 'GET',
        headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        }
    });
    if(response.status == 200){
        const blob = await response.blob();
        const urlBlob = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = urlBlob;
        a.download = `Evaluaciones.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(urlBlob);
        return;
    }

    toast('Ocurrió un problema al intentar generar el excel.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { getPostulations, notifyCompanies, generateExcelEvaluations }