import { toast } from "react-toastify";
import FormDataRecursive from "../../../utils/FormDataRecursive";

const resetPassword = async (data, setIsSubmitting, history, token) => {
    setIsSubmitting(true);
    data.password = data.password.replaceAll(' ', '');
    data.password_confirmation = data.password_confirmation.replaceAll(' ', '');
    let formData = new FormDataRecursive();
    formData.append('token', token);
    formData.appendRecursive(data);

    let response = await fetch(`${process.env.REACT_APP_API_URL}/reset-password`, {
      method: 'POST',
      body: formData
    })

    setIsSubmitting(false);

    response = await response.json();

    if(response.ok){
        toast('Contraseña actualizada exitosamente', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        history.push('/login');
        return;
    }

    toast('Ups, ocurrió un error al intentar cambiar la contraseña', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { resetPassword }