import { Button, Grid, Typography, makeStyles } from '@material-ui/core'
import { Close, Edit } from '@material-ui/icons'
import React, { useContext } from 'react'
import { deleteStandard } from './Standard';
import { FormContext } from '../../../../../contexts/formContext';

const useStyles = makeStyles({
    container: {
        boxShadow: '0px 5px 25px #00000017',
        borderRadius: 25,
        padding: '2rem',
        margin: '1rem',
    },
});


const StandardView = ({ standard, setOpenDialog }) => {
    const classes = useStyles();
    const {state, dispatch} = useContext(FormContext);

    const handleDelete = async (standard) => {
        await deleteStandard(standard, state, dispatch);
    }

    const handleEdit = (standard) => {
        dispatch({type: 'SET_ACTUAL_STANDARD', payload: standard});
        setOpenDialog(true);
    }

    return (
      <Grid item xs={12} sm={10}>
        <Grid container spacing={1} className={ classes.container }>
          <Grid item xs={12} sm={10}>
            <Typography> { standard.orden }. { standard.titulo } </Typography>
          </Grid>
          {state.form.is_editable && 
          <Grid item xs={12} sm={2}>
            <Grid container justify="flex-end" spacing={1}>
              <Button variant="outlined" color="primary" style={{ margin: "0.2rem" }} onClick={() => handleEdit(standard)}>
                <Edit fontSize="small" style={{ cursor: "pointer" }} />
              </Button>
              <Button variant="outlined" color="secondary" style={{ margin: "0.2rem" }} onClick={() => handleDelete(standard)}>
                <Close fontSize="small" style={{ cursor: "pointer" }} />
              </Button>
            </Grid>
          </Grid>
          }
        </Grid>
      </Grid>
    )
}

export default StandardView