import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core';

const Checkboxes = ({ nombre, alternatives=[], required=false, callback, disabled=false }) => {
    const { control, setValue, getValues } = useFormContext();
    const [checkboxChecked, setCheckboxChecked] = useState(0);

    useEffect(() => {
        let checkboxesSelected = getValues(nombre).reduce((prev, current) => {
            if(current?.checked) return prev + 1;
            else return prev;
        }, 0);
        setCheckboxChecked(checkboxesSelected);    
    }, [ getValues(nombre) ])

    const handleClick = (alt, e) => {
        if(e.target.checked) { 
            setCheckboxChecked(checkboxChecked + 1);
        } else {
            setCheckboxChecked(checkboxChecked - 1);
        }
        if(callback){ callback(alt); }
    }

    return (
        <>
        {alternatives.map(( checkbox, index ) => (
            <Grid item xs={12} key={index}>
                <Controller 
                    name={`${nombre}.${index}`}
                    control={control}
                    rules={{
                        validate: () => {
                            if(required) return checkboxChecked != 0 || 'Al menos una alternativa debe ser seleccionada'
                            return true;
                        }
                    }}
                    defaultValue=""
                    render={({ field: { value } }) => {
                        const onChange = (e) => {
                            setValue(`${nombre}.${index}`, {id: checkbox.id , checked: e.target.checked, ...checkbox});
                        }
                        return <FormControlLabel 
                            control={<Checkbox checked={value.checked} onChange={onChange} color="primary" onClick={(e) => handleClick(value, e)} disabled={disabled}/>}
                            label={checkbox.texto}
                        />
                    }}
                />
            </Grid>
        ))}
        {required && checkboxChecked == 0 && (
            <Grid item xs={12}>
                <Typography variant="caption" style={{color: 'red'}}> Debe seleccionar al menos una alternativa </Typography>
            </Grid>
        )}
        </>
    )
}

export default Checkboxes;
