import { toast } from "react-toastify";
import api from "../../../../helpers/jwt/apiFetch"

const getData = async (formUserId, setData) => {
    const response = await api(`/evaluations_cuantitatives/${ formUserId }`);

    if(response.ok){
        setData(response.data);
        return;
    }

    toast('Ups, ocurrió un error al obtener información de la postulación.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const updateScore = async (answer, score, data, setData) => {
    const response = await api(`/evaluation_cuantitatives/update?answer_id=${ answer.id }&score=${ score }`, 'PUT');

    if(response.ok){
        const answers = data.answers.map(i => i.id == answer.id ? response.data : i);
        setData({ ...data, answers });
        toast('Puntaje actualizado con éxito.', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        return;
    }

    toast('Ups, ocurrió un error editar el ptje.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { getData, updateScore }