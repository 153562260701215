import { toast } from "react-toastify";
import api from "../../../../helpers/jwt/apiFetch";

const getJuries = async (setJuries) => {
    const response = await api(`/juries_with_evaluations`, 'GET');
    if(response.ok){
        setJuries(response.data);
        return;
    }
    toast('Ups, ocurrió al obtener los jurados.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { getJuries }