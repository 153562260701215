import React from 'react';
import HomeUserView from '../../views/HomeUser/HomeUserView';
import HomeAdminView from '../../views/HomeAdmin/HomeAdminView';
import HomeJuryView from '../../views/HomeJury/HomeJuryView';

const Home = ({role_id}) => {
  switch (role_id) {
    case 1:
      return <HomeAdminView/>
    case 2:
    case 4:
      return <HomeUserView />
    case 3:
      return <HomeJuryView />
  }
}

export default Home
