import React from 'react'
import { useEffect } from 'react'
import { download, getUserInfo } from './UserInfo';
import { useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Backdrop, Breadcrumbs, Button, CircularProgress, Divider, Grid, Link, Typography } from '@material-ui/core';
import Spinner from '../../../layout/Spinner';
import { GetApp } from '@material-ui/icons';


const Fact = ({ title, value }) => {
    return (
        <>
        <Typography variant="caption" color="initial"> { title } </Typography>
        <Typography variant="subtitle1" color="initial"> { value } </Typography>
        <Divider />
        </>
    )
  }

const UserInfoView = () => {
    const {id} = useParams();
    const [state, setState] = useState({});
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUserInfo(id, setState);
    }, []);

    if(Object.entries(state) == 0){
        return <Grid container justify='center'> 
            <Spinner size={60} />
        </Grid>
    }
    
    const handleClickBreadcumb = (event, path) => {
        event.preventDefault();
        history.push(path);
    }

    const handleClickDownload = async () => {
        await download(state.Empresa.id, setLoading);
    }

    return (
        <Grid container justify='center'>
            <Grid item xs={12} sm={10}>
                <Grid container justify='flex-end'>
                    <Breadcrumbs aria-label="breadcrumb">
                    <Link color='inherit' href="/inicio" onClick={(e) => handleClickBreadcumb(e, '/')}> Inicio </Link>
                    <Link color='inherit' href="/usuarios/listado" onClick={(e) => handleClickBreadcumb(e, '/usuarios/listado')}> Usuarios </Link>
                    <Typography color="textPrimary"> Información de usuario </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={10}>
                <Typography variant="h1" color="primary" gutterBottom> Datos usuario </Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
                <Grid container spacing={1}>
                    {Object.entries(state).map((item, index) => {
                        if(item[0].replaceAll('_', ' ') == 'Empresa'){
                            return <div key={'dato_usuario'+index}></div>;
                        }
                        return <Grid key={'dato_usuario'+index} item xs={12} sm={6}><Fact title={item[0].replaceAll('_', ' ')} value={item[1]}/> </Grid>
                    })}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={10} style={{ marginTop: '2rem' }}>
                <Typography variant="h1" color="primary" gutterBottom> Datos empresa </Typography>
                {
                    state.Empresa ? (
                        <>
                        <Grid container spacing={1}>
                            {Object.entries(state.Empresa).map((item, index) => {
                                if(['Tipos', 'Logo', 'Mercados objetivos'].includes(item[0].replaceAll('_', ' '))){
                                    return <div key={'dato_empresa'+index}></div>;
                                }
                                return <Grid key={'dato_empresa'+index} item xs={12} sm={6}><Fact title={item[0].replaceAll('_', ' ')} value={item[1]}/> </Grid>
                            })}
                        </Grid>
                        <Grid container style={{ marginTop: '2rem' }}>
                            <Grid item xs={12} sm={10}>
                                <Typography variant="h3" color="secondary" gutterBottom> Tipos de empresa </Typography>
                            </Grid>
                            {Object.entries(state.Empresa.Tipos).map((item, index) => {
                                return <Grid key={'tipo_empresa'+index} item xs={12}><Fact title='' value={item[1].nombre}/> </Grid>
                            })}
                        </Grid>
                        <Grid container style={{ marginTop: '2rem' }}>
                            <Grid item xs={12} sm={10}>
                                <Typography variant="h3" color="secondary" gutterBottom> Mercados objetivo </Typography>
                            </Grid>
                            {Object.entries(state.Empresa.Mercados_objetivos).map((item, index) => {
                                return <Grid key={'mercado_objetivo'+index} item xs={12}><Fact title='' value={item[1].nombre}/> </Grid>
                            })}
                        </Grid>
                        <Grid item xs={12} sm={10} style={{ marginTop: '2rem' }}>
                            <Grid container justify='center'>
                                <Button color='secondary' variant='contained' onClick={handleClickDownload}> <GetApp/> Descargar Logo </Button>
                            </Grid>
                        </Grid>
                        </>
                    )
                    :
                    <span> No existen datos de empresa asociados al usuario. </span>
                }
            </Grid>
            <Backdrop open={loading} style={{ zIndex: 999999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}

export default UserInfoView