import { toast } from "react-toastify";
import api from "../../../../../helpers/jwt/apiFetch";
import FormDataRecursive from "../../../../../utils/FormDataRecursive";

// TODO: QUEDA PENDIENTE MANEJAR EL EVENTO QUE DEBE MOSTRAR EL CAMPO DE TEXTO PARA INGRESAR UNA RESPUESTA ABIERTA SI ES QUE LA ALTERNATIVA QUE SELECCIONO PERMITE TEXTO
const callbackAlternative = (alt, setOtherResponse, methods) => {
  if(alt.permite_texto) {
    setOtherResponse( true );
    methods.setValue('otherResponse', {...methods.getValues('otherResponse'), alt});
  } else {
    setOtherResponse( false );
    methods.reset({...methods.getValues(), otherResponse: { value: '', alt: {} } });
  }
}

const answerQuestion = async (data, dispatch, question, postulationId) => {
  let userResponded = false;
  if(!question.es_obligatoria) {
    Object.entries(data).forEach(x => {
      if(x[0] == 'multipleResponse' && question.question_type_id == 1) {
        userResponded = x[1].some(y => y.checked);
      } else {
        if(x[1] && x[0] != 'multipleResponse') {
          userResponded = true;
        }
      }
    })
    if(!userResponded) {
      return;
    }
  }
  let formData = new FormDataRecursive();
  formData.appendRecursive({
    textResponse: data.textResponse,
    numberResponse: data.numberResponse,
    otherResponse: data.otherResponse,
    radioResponse: data.radioResponse != '' ? question.alternatives.filter(item => item.id == data.radioResponse) : '',
    // multipleResponse: (data.multipleResponse && (data.multipleResponse.length > 0)) ? data.multipleResponse.filter(item => Object.entries(item).length > 0 && item.checked).map(item => ({id: item.id, puntaje: item.puntaje})) : [],
    multipleResponse: (data.multipleResponse && (data.multipleResponse.length > 0)) ? data.multipleResponse.filter((value) => value !== undefined).filter(item => Object.entries(item).length > 0 && item.checked).map(item => ({id: item.id, puntaje: item.puntaje})) : [],
    linkResponse: data.linkResponse,
    files: data.files,
    questionTypeId: question.question_type_id,
    questionId: question.id,
    postulationId,
    answerId: question.answers.length > 0 ? question.answers[0].id : null
  });

  const response = await api('/answers', 'POST', formData);

  if(response.ok) {
    dispatch({ type: 'UPDATE_ANSWER', payload: response.data });
    return;
  }  
  toast('Ups, ocurrió al intentar guardar la respuesta', {
    position: toast.POSITION.TOP_RIGHT,
    type: 'error'
  });
}


export { callbackAlternative, answerQuestion }