import { toast } from "react-toastify";
import api from "../../../../helpers/jwt/apiFetch"

const getForm = async (id, dispatch) => {
    const response = await api(`/forms/${ id }`, 'GET');
    if(response.ok){
        dispatch({
            type: 'SET_FORM',
            payload: response.data
        });
        return;
    }
    toast('Ups, ocurrió al obtener el formulario.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { getForm }