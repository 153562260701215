import React from 'react';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { materialTableProps } from '../../../common/materialTable';
import { getAttachments, modifyAdmissibility, showPDF, update } from './PostulationList';
import { Assignment, AssignmentTurnedIn, CheckBox, CheckBoxOutlineBlank, Edit, FileCopy, Star, StarBorder } from '@material-ui/icons';

const State = ({ text, color }) => {
    return <Typography variant="caption" style={{color, fontWeight: 600}}> { text }</Typography>
}

const PostulationListView = ({ state, setState, setLoading }) => {
    const history = useHistory();

    const handleClick = async (postulationId) => {
        setLoading(true);
        await showPDF(postulationId);
        setLoading(false);
    }

    const handleClickAdmissibility = async (postulationId) => {
        setLoading(true);
        await modifyAdmissibility(postulationId, state, setState);
        setLoading(false);
    }

    const handleClickShowEvaluations = (postulationId) => {
        history.push(`/evaluaciones/listado/${ postulationId }`);
    }

    const handleClickEditEvaluations = (postulationId) => {
        history.push(`/evaluacion/rectificar/${ postulationId }`);
    }

    const handleClickDownloadImages = async (data) => {
        setLoading(true);
        await getAttachments(data);
        setLoading(false);
    }

    const handleClickWinner = async (rowData, opt) => {
        setLoading(true);
        await update({ data: { es_ganador_regional: opt },
            postulationId: rowData.id,
            state,
            setState
        });
        setLoading(false);
    }

    return (
        <MaterialTable
            {...materialTableProps}
            options={{
                filtering: true,
                pageSize: 10
            }}
            columns={[
                {
                    title: 'Código',
                    field: 'id',
                    filtering: false
                },
                {
                    title: 'Empresa',
                    filtering: false,
                    render: rowData => {
                        if(rowData.user.companies.length > 0) {
                            return <span>{ rowData.user.companies[0].nombre }</span>
                        }
                        return <span>{ rowData.user.guide?.nombre_empresa || '-' }</span>
                    }
                },
                {
                    title: 'Región',
                    field: '',
                    filtering: false,
                    render: rowData => {
                        if(rowData.user.companies.length > 0) {
                            return <span>{ rowData.user.companies[0].region.nombre }</span>
                        }
                        return <span>{ rowData.user.guide?.region?.nombre || '-' }</span>
                    }
                },
                {
                    title: 'Formulario',
                    field: 'form.nombre',
                    filtering: false,
                },
                {
                    title: 'Contacto',
                    field: 'user.correo_electronico',
                    filtering: false,
                    render: rowData => (
                        <div>
                            <p> { rowData.user.correo_electronico }</p>
                            <p> { rowData.user.celular }</p>
                        </div>
                    )
                },
                {
                    title: 'Estado',
                    field: 'state_id',
                    filtering: true,
                    lookup: { 1: 'Incompleto', 2: 'Ingresado', 3: 'En Evaluación', 4: 'Evaluado'},
                    render: (rowData) => {
                        if(rowData.state_id == 1) {
                          return <State text={ rowData.state.nombre } color='#EC7063'/>
                        }
                        if(rowData.state_id == 2) {
                          return <State text={ rowData.state.nombre } color='#5499C7'/>
                        }
                        if(rowData.state_id == 3) {
                          return <State text={ rowData.state.nombre } color='#58D68D'/>
                        } else { 
                          return <State text={ rowData.state.nombre } color='#F4D03F'/>
                        }
                      }
                },
                {
                    title: 'Acciones',
                    field: 'acciones',
                    filtering: false,
                    render: (rowData) => {
                        return  <> 
                        <Tooltip title="Ver respuestas">
                            <Button color="secondary" variant="outlined" onClick={() => handleClick(rowData.id)} style={{marginBottom: '0.5rem'}} fullWidth>
                                <Assignment />
                            </Button>
                        </Tooltip>
                        { [1,2].includes(rowData.state_id) && <Tooltip title="Modificar admisibilidad">
                            <Button variant="outlined" startIcon={ (rowData.es_admisible == null || rowData.es_admisible == 1) ? <CheckBox /> : <CheckBoxOutlineBlank />} onClick={() => handleClickAdmissibility(rowData.id)} style={{marginBottom: '0.5rem'}} fullWidth>
                                Admisible
                            </Button>
                        </Tooltip> }
                        <Tooltip title="Descargar archivos">
                        <Button color="secondary" variant="outlined" onClick={() => handleClickDownloadImages(rowData)} fullWidth>
                            <FileCopy />
                        </Button>
                        </Tooltip>
                        {
                            rowData.state_id == 3 && <>
                            <Tooltip title="Editar evaluaciones cuantitativas">
                            <Button color='inherit' variant="outlined" onClick={() => handleClickEditEvaluations(rowData.id)} style={{marginBottom: '0.5rem'}} fullWidth>
                                <Edit />
                            </Button>
                            </Tooltip>
                            <Tooltip title="Ver evaluaciones">
                            <Button color='primary' variant="outlined" onClick={() => handleClickShowEvaluations(rowData.id)} style={{marginBottom: '0.5rem'}} fullWidth>
                                <AssignmentTurnedIn />
                            </Button>
                            </Tooltip>
                        </>
                        }
                        {
                            rowData.state_id == 4 && (
                                rowData.es_ganador_regional ? 
                                <Tooltip title="Desmarcar como ganador regional">
                                <Button color='inherit' variant="outlined" fullWidth onClick={()=>handleClickWinner(rowData, 0)}>
                                    <Star style={{ color: '#bca53a' }}/>
                                </Button>
                                </Tooltip>
                                :
                                <Tooltip title="Marcar como ganador regional">
                                <Button color='inherit' variant="outlined" fullWidth onClick={()=>handleClickWinner(rowData, 1)}>
                                    <StarBorder style={{ color: '#bca53a' }}/>
                                </Button>
                                </Tooltip>
                            )                            
                            
                        }
                        </>
                    }
                },
            ]}
            data={state.postulations}
        />
    )
}

export default PostulationListView;