import { toast } from "react-toastify";
import api from "../../../../helpers/jwt/apiFetch";
import FormDataRecursive from "../../../../utils/FormDataRecursive";

const getCommunes = async (setCommunes) => {
  const response = await api(`/communes`, 'GET');
  // const response = await api(`/communes/${ regionId }`, 'GET');
  if(response.ok){
    setCommunes(response.data);
    return;
  }
  toast('Ups, ocurrió al obtener las comunas', {
    position: toast.POSITION.TOP_RIGHT,
    type: 'error'
  });
}

const getRegions = async (setRegions) => {
  const response = await api('/regions', 'GET');
  if(response.ok){
    setRegions(response.data);
    return;
  }
  toast('Ups, ocurrió al obtener las regiones', {
    position: toast.POSITION.TOP_RIGHT,
    type: 'error'
  });
}

const searchCompany = async ({ trigger, setValue, getValues }, setOpenModal) => {
    trigger('rut_2');
    const rut = getValues('rut_2').trim().split('-');
    if(rut[0].length <= 9 && rut.length == 2 && ['0','1','2','3','4','5','6','7','8','9','k','K'].includes(rut[1])){
      const response = await api(`/company/${rut[0]}`, 'GET');
      if(response.ok && response.data.length > 0){
        const data = response.data[0];
        setValue('rut', `${data.rut}-${data.digito_verificador}`);
        setValue('nombre', data.nombre || '');
        setValue('direccion_turistica', data.direccion_turistica || '');
        setValue('razon_social', data.razon_social || '');
        setValue('giro', data.giro || '');
        setValue('valle_vitivinicola', data.valle_vitivinicola || '');
        setValue('telefono', data.telefono || '');
        setValue('celular', data.celular || '');
        setValue('commune_id', data.commune_id || 0);
        setValue('region_id', data.region_id || 0);
        setValue('celular', data.celular || '');
        setValue('company_size_id', data.company_size_id || 0);
        setValue('target_market_id', data.target_market_id || 0);
        setValue('anio_inicio_actividades', data.anio_inicio_actividades || '');
        setValue('anio_inicio_actividades_enoturisticas', data.anio_inicio_actividades_enoturisticas || '');
        toast('Se ha encontrado información', {
          position: toast.POSITION.TOP_RIGHT,
          type: 'success'
        });
        setOpenModal(false);
        return;
      } 
      toast('No hemos encontrado registros asociados al rut de empresa ingresado', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'warning'
      });
    } else {
      return;
    }
}

const registerCompany = async (data, setIsSubmitting, history, user) => {
    setIsSubmitting(true);
    data.company_types_id = data.company_types_id.filter(item => Object.entries(item).length > 0 && item.checked).map(item => item.id);
    data.company_target_markets_id = data.company_target_markets_id.filter(item => Object.entries(item).length > 0 && item.checked).map(item => item.id);
    let formData = new FormDataRecursive();

    formData.appendRecursive({
      rut: parseInt(data.rut.trim().split('-')[0]),
      digito_verificador: data.rut.trim().split('-')[1],
      nombre: data.nombre.toLocaleUpperCase(),
      telefono: parseInt(data.telefono) || null,
      celular: parseInt(data.celular),
      razon_social: data.razon_social,
      giro: data.giro,
      valle_vitivinicola: data.valle_vitivinicola,
      region_id: parseInt(data.region_id),
      commune_id: parseInt(data.commune_id),
      company_size_id: parseInt(data.company_size_id),
      company_types_id: data.company_types_id,
      company_target_markets_id: data.company_target_markets_id,
      anio_inicio_actividades: parseInt(data.anio_inicio_actividades),
      anio_inicio_actividades_enoturisticas: parseInt(data.anio_inicio_actividades_enoturisticas),
      ruta_logo: data.ruta_logo,
      user_id: user.id
    });
    const response = await api('/company', 'POST', formData);

    if(response.ok){
      setIsSubmitting(false);
      localStorage.setItem('user', JSON.stringify(response.data));
      if(!response.data.es_validado){
        history.push('no_habilitado');
      }
      history.push('inicio');
    } else {
      toast('Ups, ocurrió un error al intentar registrarte', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
      });
    }
  }

export { getCommunes, getRegions, searchCompany, registerCompany}