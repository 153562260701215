const tamaños = {
  xl: {
    xs: 12
  },
  l: {
    xs: 12,
    sm: 10
  },
  m: {
    xs: 12,
    md:6
  },
  s: {
    xs: 6,
    md: 3
  }
}

export {
  tamaños
}