import React from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Button, Switch, Typography } from '@material-ui/core';
import { changeState, editForm } from './FormList';
import { materialTableProps } from '../../../common/materialTable';
import { Edit } from '@material-ui/icons';


const SwitchState = ({ rowData, state, setState, setLoading }) => {
    return <>
        <Switch value="" checked={rowData.es_activo == 0 ? false : true} onChange={e => changeState(rowData, e, state, setState, setLoading)} inputProps={{ 'aria-label': '' }} />
        {
            rowData.es_activo ?
            <Typography variant="caption"> Desactivar </Typography>
            :
            <Typography variant="caption"> Activar </Typography>
        }
    </>
}

const FormListView = ({ state, setState, setLoading }) => {
    const history = useHistory();

    return (
        <MaterialTable
            {...materialTableProps}
            options={{
                pageSize: 10,
                // filtering: true
            }}
            columns={[
                {
                    title: 'Nombre',
                    field: 'nombre',
                    // filtering: false,
                },
                {
                    title: 'Aplica a',
                    field: 'role.nombre',
                },
                {
                    title: 'Año',
                    field: 'anio',
                    // filtering: false,
                },
                {
                    title: 'Postulaciones máximas',
                    field: 'n_postulaciones_maximas',
                    // filtering: false,
                },
                {
                    title: 'Estado',
                    field: 'es_activo',
                    // filtering: true,
                    lookup: {true: 'Activo', false: 'Inactivo'},
                    render: rowData => {
                      if (rowData.is_editable || rowData.es_activo) return <SwitchState rowData={rowData} state={state} setState={setState} setLoading={setLoading} />;
                      return "-";
                    },
                },
                {
                    title: 'Acciones',
                    field: 'actions',
                    filtering: false,
                    render: (rowData) => {
                        return (
                            <Button variant="contained" color="primary" style={{color: "#FFF", margin: '0 5px'}} onClick={() => editForm(rowData, history)}>
                                <Edit />
                            </Button>
                        )
                    }
                },
            ]}
            data={state.data}
        />
    )
}

export default FormListView;
