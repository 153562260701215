import React, { useContext, useState, useEffect } from 'react';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { AddCircle, Cancel } from '@material-ui/icons';

import Text from '../../../../common/Text';
import CustomSelect from '../../../../common/Select';
import RadioAlternatives from '../../../../common/RadioGroup';
import { FormContext } from '../../../../../contexts/formContext';
import { createQuestion, editQuestion } from './Question';

const QuestionModalView = ({ setOpenDialog }) => {
    const formContext = useContext(FormContext);
    const { state } = formContext;
    const methods = useForm({
       defaultValues: {
            titulo: '',
            question_type_id: 0,
            es_obligatoria: '',
            es_admisible: '',
            permite_adjunto: '',
            texto_adjunto: '',
            alternatives: [],
            standard_id: 0
       }
    });
    const [question_types] = useState([
        {valor: 1, texto: 'Selección múltiple'},
        {valor: 2, texto: 'Selección única'},
        {valor: 3, texto: 'Texto'},
        {valor: 4, texto: 'Enlace'},
        {valor: 5, texto: 'Archivo'},
        {valor: 6, texto: 'Número'},
    ]);
    const [standards] = useState(state.form.standards.map((s, i) => ({valor: s.id, texto: `Criterio ${i+1}`})));
    const { handleSubmit, getValues, setValue, watch, control } = methods;
    const { fields: alternativeFields, append: alternativeAppend, remove: alternativeRemove } = useFieldArray({ 
        control, 
        name: "alternatives"
    });

    const onSubmit = async (data) => {
        if(Object.entries(state.question).length > 0) {
            await editQuestion(data, setOpenDialog, formContext);
        } else {
            await createQuestion(data, setOpenDialog, formContext);
        }
    }

    useEffect(() => {
        if(Object.entries(state.question).length > 0) {
            setValue('titulo', state.question.titulo);
            setValue('question_type_id', state.question.question_type_id);
            setValue('es_obligatoria', state.question.es_obligatoria == 1? '1' : '2');
            setValue('es_admisible', state.question.es_admisible == 1 ? '1' : '2');
            setValue('permite_adjunto', state.question.permite_adjunto == 1? '1' : '2');
            setValue('alternatives', state.question?.alternatives?.map(a => ({ 
                id: a.id,
                titulo: a.titulo,
                puntaje: a.puntaje,
            })));
            setValue('standard_id', state.question.standard_id || 0);
        }
    }, [])
    
    return (
        <FormProvider {...methods}>
        <form id='question-form' onSubmit={handleSubmit(onSubmit)} style={{margin: '1.5rem 0'}}> 
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Text nombre="titulo" etiqueta="Título" defaultValue={getValues('titulo')} />
                </Grid> 
                <Grid item xs={12} sm={4}>
                    <CustomSelect nombre="question_type_id" etiqueta="Tipo" valorPorDefecto={getValues('question_type_id')} opciones={question_types} required/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <RadioAlternatives nombre='es_obligatoria' etiqueta='¿Es obligatoria?'/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <RadioAlternatives nombre='es_admisible' etiqueta='¿Es admisible?'/>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                    <RadioAlternatives nombre='permite_adjunto' etiqueta='¿Permite archivo adjunto?'/>
                </Grid> */}
                {/* {
                    watch('permite_adjunto') == 1 && (
                        <Grid item xs={12}>
                            <Text nombre="texto_adjunto" etiqueta="Descripción de archivos adjuntos" defaultValue={getValues('texto_adjunto')} rules={{required: false}}/>
                        </Grid> 
                    )
                }  */}

                <Grid item xs={12}>
                    <CustomSelect nombre="standard_id" etiqueta="Criterios" opciones={standards}/>
                </Grid>
                {/* TODO: REVISAR CASO QUE PASA CUANDO: SE EDITA UNA PREGUNTA Y SE DEFINE UN TIPO DE PREGUNTA TEXTO, ESTO SE SIGUE MOSTRANDO DE IGUAL MANERA */}
                {([1,2].includes(watch('question_type_id')) || watch('alternatives').length > 0) && (
                    // {/* TODO: FALTA REALIZAR UNA VALIDACION QUE VERIFIQUE QUE SOLAMENTE UNA ALTERNATIVA PUEDA PERMITIR UNA RESPUESTA ABIERTA */}
                    <Grid item xs={12}> 
                        <Grid container justify='center' spacing={1}> 
                            {alternativeFields.map(( alternative, index ) => {
                                return <React.Fragment key={alternative.id}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1"> Alternativa { index + 1 } </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Text nombre={`alternatives.${index}.titulo`} etiqueta='Alternativa' defaultValue={alternative.titulo}/>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Text type="number" nombre={`alternatives.${index}.puntaje`} etiqueta='Ptj. base' defaultValue={alternative.puntaje}/>
                                    </Grid>
                                    {/* <Grid item xs={10} sm={3}>
                                        <RadioAlternatives nombre={`alternatives.${index}.permite_texto`} etiqueta='¿Permite texto?' valorPorDefecto={getValues(`alternatives.${index}.permite_texto`)}/>
                                    </Grid> */}
                                    <Grid item xs={2} sm={1}>
                                        <IconButton onClick={() => alternativeRemove(index)}>
                                            <Cancel fontSize='large' style={{color: '#ec6e7d'}}/>
                                        </IconButton>
                                    </Grid>
                                </React.Fragment>
                            })}
                        <Button variant='text' color='primary' startIcon={<AddCircle />} 
                            onClick={() => alternativeAppend({ titulo: '', puntaje: '' })}
                        >
                            Agregar alternativa
                        </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </form>
        </FormProvider>
    )
}

export default QuestionModalView