import { toast } from "react-toastify";
import api from "../../../../helpers/jwt/apiFetch";

const postulate = async (userId, formId, history) => {
    const response = await api(`/postulate/${ userId }/${ formId }`, 'GET');
    if(response.ok){
        if(response.data){
            history.push(`/postulacion/${ response.data.id }`);
        } else {
            toast(response.message, {
                position: toast.POSITION.TOP_RIGHT,
                type: 'warning'
            });
        }
    } else {
        toast('Ups, ocurrió un error al intentar comenzar la postulación.', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'error'
        });
    }
}

export { postulate }