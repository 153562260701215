import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Breadcrumbs, Grid, Link, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import Spinner from '../../../layout/Spinner';
import EvaluationListView from './EvaluationList/EvaluationListView';
import CustomDialog from '../../../common/CustomDialog';
import { handleClickBreadcumb } from '../../../../helpers/utils';
import { getEvaluations, updateEvaluation } from './EvaluationEdit';
import { getUsuario } from '../../../../helpers/jwt/JWTAuth';
import RadioAlternatives from '../../../common/RadioGroup';

const EvaluationEditView = () => {
    const { id } = useParams();
    const history = useHistory();
    const [state, setstate] = useState({})
    const [openDialog, setOpenDialog] = useState(false);
    const [evaluationState, setEvaluationState] = useState({});
    const [evaluationTypes] = useState([
        {id: 0, texto: 'Inexistente (0 puntos)'},
        {id: 1, texto: 'Deficiente (1 punto)'},
        {id: 2, texto: 'Regular (2 puntos)'},
        {id: 3, texto: 'Bueno (3 puntos)'},
        {id: 4, texto: 'Excelente (4 puntos)'},
    ]);
    const user = getUsuario();
    const methods = useForm({defaultValues: {puntaje: ''}});
    const onSubmit = async (data) => {
        await updateEvaluation(data, evaluationState, state, setstate);
        setOpenDialog(false);
        methods.reset();
    }

    useEffect(() => {
        getEvaluations(id, user.jury.id, setstate);
    }, []);

    if(Object.entries(state).length == 0){
        return <Spinner size={60}/>
    }   

    return <Grid container justify="center" alignItems="center">
        <Grid item xs={12} sm={10}>
            <Grid container justify='flex-end'>
                <Breadcrumbs aria-label="breadcrumb">
                <Link color='inherit' href="/inicio" onClick={(e) => handleClickBreadcumb(e, '/', history)}> Inicio </Link>
                <Typography color="textPrimary"> Evaluaciones </Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={10}>
            <Typography variant="h1" color="primary" gutterBottom>
                Evaluaciones postulación #{ id }
            </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Categoría</TableCell>
                        <TableCell>: {state.form.nombre}  </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Empresa</TableCell>
                        <TableCell>: {state.user.companies[0].nombre}  </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Encargado empresa</TableCell>
                        <TableCell>: {state.user.full_name}  </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Jurado evaluador</TableCell>
                        <TableCell>: {user.full_name}  </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Comentario evaluación</TableCell>
                        <TableCell>: {state.evaluations[0].comentario}  </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Grid>
        <Grid item xs={12} sm={10} style={{marginTop: '2rem'}}>
            <EvaluationListView data={state.answers} setOpenDialog={setOpenDialog} setEvaluationState={setEvaluationState}/>
        </Grid>
        <CustomDialog title='Editar evaluación' open={openDialog} setOpen={setOpenDialog} form='edit_evaluation'>
            <FormProvider {...methods}>
                <form id='edit_evaluation' onSubmit={methods.handleSubmit(onSubmit)}>
                <RadioAlternatives nombre='puntaje' etiqueta='Puntaje' alternatives={evaluationTypes} size='m'/>
                </form>
            </FormProvider>
        </CustomDialog>
    </Grid>
}

export default EvaluationEditView