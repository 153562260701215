import { toast } from "react-toastify";
import FormDataRecursive from "../../../../../utils/FormDataRecursive";
import api from "../../../../../helpers/jwt/apiFetch";

const finishEvaluation = async (data, postulationId, history) => {
    let formData = new FormDataRecursive();
    formData.appendRecursive({
        form_user_id: postulationId,
        commentary: data.commentary
    });
    const response = await api(`/finish_postulation?_methods=PUT`, 'POST', formData);
    if(response.ok){
        toast('Evaluación finalizada con éxito!', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        history.push('/');
        return;
    }
    toast('Ups, ocurrió un error al finalizar la evaluación.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { finishEvaluation }