import React, { createContext, useReducer } from 'react';

const FormContext = createContext();

const formReducer = (state, action) => {
    // destructuring action ...
    const { type, payload = {} } = action;

    switch (type) {
        case "SET_FORM":
            console.log({...state, form: payload})
            return({...state, form: payload});

        case "ADD_QUESTION":
            let questions = [...state.form.questions, payload];
            console.log(questions)
            return {
                ...state,
                form: { ...state.form, questions }
            };
        
        case "ADD_STANDARD":
            let standards = [...state.form.standards, payload];
            return {
                ...state,
                form: { ...state.form, standards }
            };

        case "DELETE_QUESTION":
            let order = 0;
            let questionsFiltered = state.form.questions.filter((q) => {
                if(payload.id == q.id){
                    order = q.orden;
                    return false;
                }
                return true;
            });
            console.log(questionsFiltered)
            
            questionsFiltered = questionsFiltered.map(q => {
                if(q.orden > order) return {...q, orden: q.orden - 1};
                return q;
            });

            return { ...state, form: { ...state.form, questions: questionsFiltered } };
        
        case "DELETE_STANDARD":
            let orderStandard = 0;
            let standardsFiltered = state.form.standards.filter((s) => {
                if(payload.id == s.id){
                    orderStandard = s.orden;
                    return false;
                }
                return true;
            });
            console.log(standardsFiltered)
            
            standardsFiltered = standardsFiltered.map(s => {
                if(s.orden > orderStandard) return {...s, orden: s.orden - 1};
                return s;
            });

            return { ...state, form: { ...state.form, standards: standardsFiltered } };

        case "SET_ACTUAL_QUESTION":
            return({...state, question: payload});
        
        case "SET_ACTUAL_STANDARD":
            return({...state, standard: payload});
        
        case "UPDATE_QUESTION":
            const questionsUpdated = state.form.questions.map(q => {
                if(q.id == payload.id) return payload;

                return q;
            });
            
            return({...state, form: { ...state.form, questions: questionsUpdated }})
        case "UPDATE_STANDARD":
            const standardsUpdated = state.form.standards.map(s => {
                if(s.id == payload.id) return payload;

                return s;
            });
            
            return({...state, form: { ...state.form, standards: standardsUpdated }})
         
        case "GET_QUESTIONS_FROM_FORM":
            return({
                ...state,
                ambits: payload,
            });

        case "SET_AMBITS_FORM":
            return({
                ...state,
                ambits: [
                    ...state.ambits,
                    payload.data
                ]
            });

        case "DELETE_AMBIT":
            let ambitDeletedIndex = 0;
            let formWithAmbitDeleted = state.ambits.filter(( ambit, index ) => {
                if(ambit.id != payload.data.id) {
                    return true;
                } 
                ambitDeletedIndex = index;
                return false;
            })
            .map(( ambit, index ) => {
                if(index >= ambitDeletedIndex){
                    return {...ambit, orden: ambit.orden - 1};
                } 
                return ambit;
            });
            
            return ({ ...state, ambits: formWithAmbitDeleted });
        default:
            return state;
    }

}

const FormProvider = ({ children }) => {
    const initialState = { 
        question: {},
        standard: {},
        form: {},
    }

    const [state, dispatch] = useReducer(formReducer, initialState); 

    return (
        <FormContext.Provider value={{
            state,
            dispatch,
        }}>
            { children }
        </FormContext.Provider>
    )
}

export {FormProvider, FormContext};