import React, { useEffect, useState } from 'react';
import { Typography, Grid, Button, CircularProgress, Tooltip, Backdrop } from '@material-ui/core';
import { RemoveCircle, CheckCircle, DoneAll, Help, EmailSharp, GetApp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import CardState from '../../layout/CardState';
import { tamaños } from '../../../helpers/sizes';
import Counter from '../../common/Counter';
import { generateExcelEvaluations, getPostulations, notifyCompanies } from './HomeAdmin';
import PostulationListView from './PostulationList/PostulationListView';

const useStyles = makeStyles({
    containerCounterActiveForms: {
        alignItems: 'center',
        backgroundColor: '#00CCCC',
        borderRadius: 15,
        display: 'flex',
        height: 30,
        marginLeft: 24,
        justifyContent: 'center',
        width: 40,
    },
    counterActiveForms: {
        color: '#FFF',
        fontSize: 24,
        fontWeight: 600
    },
    dataTable: {
        boxShadow: '0px 3px 26px #00000017',
        marginTop: 24,
    },
    item: {
        margin: '1rem 0'
    },
});

// const filters_opts = [
//     {valor: 1, texto: "Empresa"},
//     {valor: 2, texto: "Formulario"},
//     {valor: 3, texto: "Año"},
// ];

const icons = [<RemoveCircle style={{color: 'white'}}/>, <CheckCircle style={{color: 'white'}}/>, <Help style={{color: 'white'}}/>, <DoneAll style={{color: 'white'}}/>];

const HomeAdminView = () => {
    const classes = useStyles();
    const [state, setState] = useState({
        actives: 0, 
        postulations: [],
        indicators: []
    });
    const [loading, setLoading] = useState(false);

    // const [isSubmitting, setIsSubmitting] = useState(false);

    // const methods = useForm({ defaultValues: { search_value: '', filter: 0 } });
    // const { handleSubmit, watch } = methods;
    // const onSubmit = async (data, e) => {
    //     setIsSubmitting(true);
    //     const endpoint = `/postulations/all?filter=${ data.filter }&search_value=${ data.search_value }`;
    //     await fetchData({endpoint, method: 'GET'}).then(response=>{
    //         if(response.ok){
    //             const { data } = response;
    //             setState({counterActiveForms: data.postulations.length, formsData: [...data.postulations]});
    //             setInfo([...data.states_info]);
    //         }
    //     });
    //     setIsSubmitting(false);
    // }

    const handleClickNotify = async () => {
        setLoading(true);
        await notifyCompanies();
        setLoading(false);
    }

    const handleClickGenerateExcelEvaluations = async () => {
        setLoading(true);
        await generateExcelEvaluations();
        setLoading(false);
    }

    useEffect(() => {
        getPostulations(setState);
    }, []);

    return (
        <Grid container justify="center">
            <Grid item {...tamaños.l}>
                <Typography variant="h1" color="primary">
                    {`Premios Enoturismo ${ new Date().getFullYear() }`}
                </Typography>
            </Grid>
            <Grid item {...tamaños.l} className={classes.item}>
                <Grid container spacing={3}>
                    {state.indicators.map((item, index) => (
                        <Grid item {...{...tamaños.m, sm: 6, md: 3}} key={index}>
                            <CardState 
                                total={item.total}
                                title={item.state}
                                background='linear-gradient(90deg, rgba(144,191,40,1) 0%, rgba(174,204,51,1) 100%)'
                                icon={icons[index]}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={8} className={classes.item}>
                <Counter title="Postulaciones en curso:" counter={ state.actives }/>
            </Grid>
            <Grid item xs={12} sm={2} className={classes.item}>
                <Tooltip title="Notificar a usuarios que no han completado su postulación">
                    <Button color='secondary' variant='contained' onClick={handleClickNotify} fullWidth> <EmailSharp /> Notificar postulantes </Button>
                </Tooltip>
            </Grid>
            <Grid item xs={12} sm={10}>
                <Grid container spacing={1}>
                    {/* <Grid item>
                        <Button color='primary' variant='contained' disabled> <GetApp /> Excel postulaciones </Button>
                    </Grid> */}
                    <Grid item>
                        <Button color='secondary' variant='contained' onClick={handleClickGenerateExcelEvaluations}> <GetApp /> Excel evaluaciones </Button>
                    </Grid>
                </Grid>
            </Grid>
            {/* TODO: HABILITAR */}
            {/* <Grid item {...tamaños.l}>
                <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container alignItems="baseline" spacing={1}>
                        <Grid item xs={12} sm={3}>
                            <CustomSelect nombre="filter" etiqueta="Filtrar por" opciones={filters_opts} required/>
                        </Grid>
                        {[1, 2].includes(watch("filter")) && (
                            <Grid item xs={12} sm={6}>
                                <Text nombre="search_value" etiqueta="Busqueda"/>
                            </Grid>
                        )}
                        {watch("filter") == 3 && (
                            <Grid item xs={12} sm={6}>
                                <Text type="number" nombre="search_value" etiqueta="Año"
                                    rules={{
                                        required: 'El campo Año es requerido', 
                                        min: {value: 2021, message: 'No existen registros anteriores al año 2021'},
                                        max: {value: year, message: `No existen registros posteriores al año ${ year }`}
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                            <Button type="submit" variant="contained" color="primary" fullWidth disabled={watch("filter") == 0 || isSubmitting}>
                                { isSubmitting ? <CircularProgress size={24}/> : ( <><FilterList /> <span> Filtrar </span></>)} 
                            </Button>
                        </FormControl>
                        </Grid>
                    </Grid>
                </form>
                </FormProvider>
            </Grid> */}
            <Grid item {...tamaños.l} className={classes.dataTable}>
                <PostulationListView setLoading={setLoading} state={state} setState={setState} />
            </Grid>
            <Backdrop open={loading} style={{ zIndex: 999999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}

export default HomeAdminView;
