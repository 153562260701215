import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Typography, Button } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// import { createFormUserRelation, getToken } from '../../../helpers/requests';

import axios from 'axios';
import { materialTableProps } from '../../../common/materialTable';
import { postulate } from './FormList';
import { getUsuario } from '../../../../helpers/jwt/JWTAuth';

const FormListView = ({ data }) => {
  const history = useHistory();
  const [state, setState] = useState(0)
  const user = getUsuario();
  

  const handleClick = async (formId) => {
    setState(formId);
    await postulate(user.id, formId,  history);
    setState(0);
  }

  return (
    <MaterialTable
      {...materialTableProps}
      options={{
        filtering: false,
      }}
      columns={[
        {
          title: 'Nombre',
          field: 'nombre',
        },
        {
          title: 'Año',
          field: 'anio',
        },
        {
          title: 'Postulaciones disponibles',
          field: 'postulaciones_disponibles',
          render: (rowData) => ( <span> { rowData.n_postulaciones_maximas - rowData.cantidad_postulaciones } </span> )
        },
        {
          title: 'Acciones',
          field: 'acciones',
          filtering: false,
          render: rowData => {
            if((rowData.n_postulaciones_maximas - rowData.cantidad_postulaciones) > 0) {
              return <Button variant="outlined" color="primary" onClick={()=>handleClick(rowData.id)} disabled={ state == rowData.id } > Postular </Button>
            }
            return <span> Postulación no disponible </span>
          }
        },
      ]}
      data={data}
    />
  )
}

export default FormListView;
    