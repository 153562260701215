import apiFetch  from './apiFetch'

const login = async (data, history) => {
    const LOGIN_ENDPOINT = `${process.env.REACT_APP_API_URL}/auth/login`;
    try {
        const response = await fetch(LOGIN_ENDPOINT, {method: 'POST', body: JSON.stringify(data), headers:{'Content-Type': 'application/json'}});
        let datos_respuesta = await response.json();
        if (response.ok){
            if(datos_respuesta.access_token && datos_respuesta.expires_in){
                let jwt = datos_respuesta.access_token;
                let expire_in = datos_respuesta.expires_in;
                let datos = JSON.stringify(datos_respuesta.datos)
                localStorage.setItem("user", datos);
                localStorage.setItem("estado", datos_respuesta.estado);
                localStorage.setItem("access_token", jwt);
                localStorage.setItem("expire_in", expire_in);
                window.location.href = '/';
            }
        }
        if(datos_respuesta.error){
            return datos_respuesta.error;
        }
    } catch(e){
        console.log('error :', e);
        return "Error al iniciar";
    }
}

const session = () =>{
    const jwt = localStorage.getItem('access_token');
    const user = localStorage.getItem('user');
    if(jwt && user){
        return true;
    }
    return false;
}

const perfil = () => {
    let usuario = localStorage.getItem('user');
    usuario = JSON.parse(usuario)
    if(usuario){
        const perfil = usuario.roles[0]; // TODO: obtiene primer rol !MODIFICAR ESTO!
        return perfil || false;
    }
    return false;
}

//Pertece al grupo de utem
const isAdmin = () => {
    let getPerfil = perfil();
    return (getPerfil === 1 || getPerfil === 4 || getPerfil === 6 || getPerfil === 9)
}

const id = () => {
    let usuario = localStorage.getItem('user');
    usuario = JSON.parse(usuario)
    if(usuario && usuario.id){
        return usuario.id
    }
    return null
}

const getUsuario = () =>{
    let usuario = localStorage.getItem('user');
    usuario = JSON.parse(usuario)
    return usuario;
}

const datos = async () => {
    // fetchData();
    let data = await apiFetch('/auth/me', 'post')
    let datos = JSON.stringify(data.datos)
    // console.log('se ejecuto datos')
    localStorage.setItem("user", datos);
    localStorage.setItem("estado", data.estado);
}

const getUsuarioActualizado = async () => {
    let data = await apiFetch('/auth/me', 'post')
    let datos = JSON.stringify(data.datos)
    // console.log('se ejecuto datos')
    localStorage.setItem("user", datos);
    localStorage.setItem("estado", data.estado);
    return data.datos;
}

const logout = async (e) => {
    e && e.preventDefault();
    await apiFetch('/auth/logout', 'post');
    localStorage.removeItem("access_token");
    localStorage.removeItem("expire_in");
    localStorage.removeItem("user");
    localStorage.removeItem("estado");
    window.location.href = '/';
}

const reLoggin = async () => {
    if(localStorage.getItem("access_token")){
        try {
            const datos_respuesta = await apiFetch('/auth/refresh', 'post');
            if(datos_respuesta.access_token && datos_respuesta.expires_in){
                let jwt = datos_respuesta.access_token;
                let expire_in = datos_respuesta.expires_in;
                localStorage.setItem("access_token", jwt);
                localStorage.setItem("expire_in", expire_in);
                await datos();
            }
            if(datos_respuesta.error){
                return datos_respuesta.error;
            }
        } catch(e){
        console.log('error :', e);
        }
    }

}

export { login, session, logout, perfil, datos, reLoggin, id, getUsuario, isAdmin, getUsuarioActualizado }
