import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Backdrop, Breadcrumbs, CircularProgress, Grid, Link, Typography } from '@material-ui/core';

import QuestionView from './QuestionView';
import Spinner from '../../../layout/Spinner';
import FinishView from './Finish/FinishView';
import { handleClickBreadcumb } from '../../../../helpers/utils';
import { getPostulationByStandard } from './Postulation';
import { EvaluationContext } from '../../../../contexts/evaluationContext';

const PostulationView = () => {
    const { id } = useParams();
    const history = useHistory();
    const { state, dispatch } = useContext(EvaluationContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPostulationByStandard(id, dispatch);
    }, [ ])

    if(state.questions.length == 0){
        return <Spinner size={60}/>
    }

    return (
        <Grid container justify="center">
            <Grid item xs={12} sm={10}>
                <Grid container justify='flex-end'>
                    <Breadcrumbs aria-label="breadcrumb">
                    <Link color='inherit' href="/inicio" onClick={(e) => handleClickBreadcumb(e, '/', history)}> Inicio </Link>
                    <Typography color="textPrimary"> Postulación </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={10}>
                <Typography variant="h1" color="primary" gutterBottom>
                    Postulación { state.form.name }
                </Typography>
                <Typography variant="h2" color="secondary" gutterBottom>
                    EMPRESA { state.company.name } 
                </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
                <QuestionView />
                {state.isComplete && (state.questions.length == state.question.orden) && <FinishView setLoading={setLoading} postulationId={id}/>}
            </Grid>
            <Backdrop open={loading} style={{ zIndex: 999999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}

export default PostulationView