import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { materialTableProps } from '../../../common/materialTable';
import { getJuries } from './JuriesList';
import { generateExcelEvaluations } from '../Regions/RegionList';

const JuriesListView = ({ setLoading, form }) => {
  const [juries, setJuries] = useState([]);
  useEffect(() => {
    getJuries(setJuries);
  }, [ ])

  const handleClick = async (rowData) => {
    const filename = `EVALUACIONES_${rowData.entidad.split(' ').join('_')}_${rowData.cargo.split(' ').join('_')}`;
    await generateExcelEvaluations(`evaluations_by_jury?jury_id=${ rowData.id }&form_id=${ form }`, filename.toLocaleUpperCase(), setLoading);
  }
  return (
    <MaterialTable
      {...materialTableProps}
      options={{
        filtering: false,
        tableLayout: 'fixed',
        pageSize: 20
      }}
      columns={[
        {
          title: 'Jurado',
          field: 'user.full_name',      
        },
        {
          title: 'Entidad',
          field: 'entidad',      
        },
        {
          title: 'Cargo',
          field: 'cargo',      
        },
        {
          title: 'Región',
          field: 'region.nombre',
          render: rowData => (rowData.region && rowData.region.nombre) ?? 'Nacional' 
        },
        {
          title: 'Acciones',
          field: 'actions',
          render: rowData => <Tooltip title='Descargar reporte'>
            <Button color='secondary' variant='outlined' onClick={() => handleClick(rowData)}> <GetApp/> </Button>
          </Tooltip>
        },
      ]}
      data={juries}
    />
  )
}

export default JuriesListView