import { Link, Typography } from '@material-ui/core';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import ButtonLoad from '../../common/ButtonLoad';
import Text from '../../common/Text';
import Login from '../Login/LoginView';
import { resetPassword } from './ResetPassword';

const ResetPasswordView = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const methods = useForm();
    const history = useHistory();
    const { token } = useParams();
    const { handleSubmit, watch } = methods;
  
    const onSubmit = async (data) => {
      await resetPassword(data, setIsSubmitting, history, token);
    }

    return <Login>
        <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
            <Text type="email" nombre="correo_electronico" etiqueta="Correo"/>
            <Text type="password" nombre="password" etiqueta="Contraseña"
                rules={{
                    required: 'El campo Contraseña es requerido',
                    pattern: { message: "La contraseña debe tener mínimo 8 caracteres (al menos 1 mayúscula, 1 minúscula y 1 número).", value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/ },
                    validate: (value) => value === watch('password_confirmation') || 'Ambas contraseñas deben coincidir' 
                }}
            />
            <Text type="password" nombre="password_confirmation" etiqueta="Confirme contraseña"
                rules={{
                    required: 'El campo Contraseña es requerido',
                    validate: (value) => value === watch('password') || 'Ambas contraseñas deben coincidir' 
                }}
            />
            <ButtonLoad isSubmitting={isSubmitting} texto='Cambiar'/>
            <Typography variant="subtitle1" align='center'>
                <Link to="/login" component={RouterLink}>Ir al login</Link>
            </Typography>
        </form>
        </FormProvider>
    </Login>
}

export default ResetPasswordView