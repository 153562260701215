import React from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Typography, Button, Tooltip } from '@material-ui/core';
import { materialTableProps } from '../../../common/materialTable';
import { showPDF } from '../../HomeAdmin/PostulationList/PostulationList';
import { Assignment, AssignmentTurnedIn } from '@material-ui/icons';

const State = ({ text, color }) => {
  return <Typography variant="caption" style={{color, fontWeight: 600}}> { text }</Typography>
}

const PostulationListView = ({ data, setLoading }) => {
const history = useHistory();

  const handleClick = async (postulationId) => {
    setLoading(true);
    await showPDF(postulationId);
    setLoading(false);
  }

  return (
    <MaterialTable
      {...materialTableProps}
      options={{
        filtering: false,
      }}
      columns={[
        {
          title: 'Código',
          field: 'id',
          width: '5%',
        },
        {
          title: 'Nombre formulario',
          field: 'nombre',
          width: '60%',
          render: (rowData) => <span> { rowData.form.nombre + ' - ' + rowData.form.anio } </span>
        },
        {
          title: 'Estado',
          field: 'estado',
          width: '15%',
          render: (rowData) => {
            if(rowData.state_id == 1) {
              return <State text={ rowData.state.nombre } color='#EC7063'/>
            }
            if(rowData.state_id == 2) {
              return <State text={ rowData.state.nombre } color='#5499C7'/>
            }
            if(rowData.state_id == 3) {
              return <State text={ rowData.state.nombre } color='#58D68D'/>
            } else { 
              return <State text={ rowData.state.nombre } color='#F4D03F'/>
            }
          }
        },
        {
          title: 'Acciones',
          field: 'acciones',
          filtering: false,
          width: '20%',
          render: rowData => {
            if(rowData.state_id == 1){
              return (
              <Tooltip title="Completar postulación">
                <Button variant="outlined" color="primary" onClick={() => history.push(`/postulacion/${ rowData.id }`)}> 
                  <AssignmentTurnedIn/>
                </Button>
              </Tooltip>
              )
            } 
            return (
              <Tooltip title="Ver respuestas">
                <Button color="secondary" variant="outlined" onClick={() => handleClick(rowData.id)}>
                  <Assignment/>
                </Button>
              </Tooltip>
            )
          }
        },
      ]}
      data={data}
    />
  )
}

export default PostulationListView;
