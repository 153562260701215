import { toast } from "react-toastify";
import api from "../../../helpers/jwt/apiFetch";

const getEvaluations = async (postulationId, setState) => {
    const response = await api(`/evaluations/${ postulationId }`, 'GET');
    if(response.ok){
        setState(response.data);
        return;
    }
    toast('Ups, ocurrió al obtener las evaluaciones', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { getEvaluations }