import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getUsuario } from '../../helpers/jwt/JWTAuth';

const useStyles = makeStyles({
    containerInfo:{
        boxShadow: '0px 3px 26px #00000017',
        background: '#bece7e',
        borderRadius: 28,
        minHeight: '200px',
        margin: '16px 0',
        padding: 16,
    },
    image: {
        height: '80%',
        width: '80%'
    },
});

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const GreetingCard = ({ mesagge }) => {
    const classes = useStyles();
    const usuario = getUsuario();
    const texto = mesagge ? mesagge : 'Plataforma de postulación a Premios Enoturismo.'
    return (
        <Grid item xs={12} className={classes.containerInfo} >
            <Grid container alignItems="center">
                <Grid item xs={8}>
                    <Grid container direction="column" justify="flex-start">
                        <Grid item>
                            <Typography variant="h2" color="secondary" style={{fontWeight: 700}}>
                                Bienvenid@ {`${capitalizeFirstLetter(usuario.nombres)} ${capitalizeFirstLetter(usuario.apellido_p)}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" color="textSecondary" style={{padding: '16px 24px'}}>
                                {texto}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <img src={`${process.env.REACT_APP_URL}/assets/images/logo-bienvenida.png`} alt="Logo bienvenida" className={classes.image} />
                </Grid>
            </Grid>
        </Grid>        
    )
}

export default GreetingCard;
