import React, { useEffect, useState } from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import EvaluationsListView from './EvaluationsListView';
import { getData, updateScore } from './EvaluationsEdit';
import Spinner from '../../../layout/Spinner';
import CustomDialog from '../../../common/CustomDialog';
import { FormProvider, useForm } from 'react-hook-form';
import Text from '../../../common/Text';

const EvaluationsEditView = () => {
    const {form_user_id} = useParams();
    const [data, setData] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [answerSelected, setAnswerSelected] = useState({});
    const methods = useForm({
        defaultValues:{
            score: ''
        }
    });

    const onSubmit = async ({ score }) => {
        await updateScore(answerSelected, parseInt(score), data, setData);
        setOpenDialog(false);
        methods.reset();
    }

    useEffect(() => {
        getData(form_user_id, setData);
    }, []);

    if(Object.entries(data).length == 0){
        return <Spinner size={60}/>
    }

    return (
        <Grid container justify="center" alignItems="center">
            <Grid item xs={12} sm={10}>
                <Typography variant="h1" color="primary" gutterBottom>
                    Postulación #{form_user_id}
                </Typography>
            </Grid>

            <Grid item xs={12} sm={8}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Categoría</TableCell>
                        <TableCell>: {data.postulation_info.form.nombre} </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Empresa</TableCell>
                        <TableCell>: {data.postulation_info.user.company.nombre} </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Encargado Empresa</TableCell>
                        <TableCell>: {data.postulation_info.user.full_name} </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            </Grid>

            <Grid item xs={12} sm={10}>
                <EvaluationsListView data={data.answers} setOpenDialog={setOpenDialog} setAnswerSelected={setAnswerSelected}/>
            </Grid>

            <CustomDialog title='Editar evaluación' open={openDialog} setOpen={setOpenDialog} form='edit_evaluation'>
            <FormProvider {...methods}>
                <form id='edit_evaluation' onSubmit={methods.handleSubmit(onSubmit)}>
                    <Text nombre='score' etiqueta='Puntaje' type='number'/>
                </form>
            </FormProvider>
            </CustomDialog>
        </Grid>
    )
}

export default EvaluationsEditView