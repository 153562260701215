import { toast } from "react-toastify";
import FormDataRecursive from "../../../../utils/FormDataRecursive";
import api from "../../../../helpers/jwt/apiFetch";

const evaluate = async (postulationId, history) => {
    let formData = new FormDataRecursive();
    formData.appendRecursive({
        form_user_id: postulationId,
    });
    const response = await api('/evaluate_postulation', 'POST', formData);

    if(response.ok){
        history.push(`/evaluacion/postulacion/${ postulationId }`);
        return;
    }

    toast('Ups, ocurrió al intentar evaluar la postulación.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { evaluate } 