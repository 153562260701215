import { toast } from "react-toastify";
import FormDataRecursive from "../../../../utils/FormDataRecursive";
import api from "../../../../helpers/jwt/apiFetch";

const createJury = async (data, setLoading, reset) => {
    setLoading(true);
    let formData = new FormDataRecursive();

    formData.appendRecursive({
        nombres: data.nombres,
        apellido_p: data.apellido_p,
        apellido_m: data.apellido_m,
        // rut: parseInt(data.rut.trim().split('-')[0]),
        // digito_verificador: data.rut.trim().split('-')[1],
        correo_electronico: data.correo_electronico,
        celular: data.celular ? parseInt(data.celular) : '',
        gender_id: parseInt(data.gender_id),
        region: parseInt(data.region_id),
    });

    const response = await api('/juries', 'POST', formData);

    setLoading(false);

    if(response.ok) {
        reset({
            nombres: '',
            apellido_p: '',
            apellido_m: '',
            // rut: '',
            correo_electronico: '',
            celular: '',
            gender_id: '0',
            region_id: '0',
        });
        toast('Jurado creado exitosamente.', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });    
        return;
    }
    
    if(response.error){
        toast(response.error, {
            position: toast.POSITION.TOP_RIGHT,
            type: 'error'
        });
        return;
    }

    Object.values(response.errors).forEach(error => {
        toast(error[0], {
          position: toast.POSITION.TOP_RIGHT,
          type: 'error'
        });
    });
}

export { createJury }