import { Backdrop, Breadcrumbs, Button, CircularProgress, Grid, Link, Tooltip, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import Counter from "../../common/Counter";
import { useState } from "react";
import { useEffect } from "react";
import { getUsers, notifyUsers } from "./Users";
import UserListView from "./UserList/UserListView";
import { useHistory, useLocation } from "react-router-dom";
import { EmailSharp } from "@material-ui/icons";

const useStyles = makeStyles({
  dataTable: {
    marginTop: "2rem",
    boxShadow: "0px 3px 26px #00000017",
  },
});

const UsersView = () => {
  const history = useHistory();
  const [state, setState] = useState({ actives: 0, data: [] });
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const handleClickBreadcumb = (event) => {
    event.preventDefault();
    history.push("/");
  };

  const handleClickNotify = async () => {
    setLoading(true);
    await notifyUsers();
    setLoading(false);
  };

  useEffect(() => {
    getUsers(setState);
  }, []);

  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={10}>
        <Grid container justify="flex-end">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/inicio" onClick={handleClickBreadcumb}>
              Inicio
            </Link>
            <Typography color="textPrimary"> Mantenedor de usuarios </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Typography variant="h1" color="primary" gutterBottom>
          Mantenedor de Usuarios
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Counter title="Usuarios activos" counter={state.actives} />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Tooltip title="Notificar a usuarios que no han completado su registro">
          <Button color="secondary" variant="contained" onClick={handleClickNotify} fullWidth>
            <EmailSharp /> Notificar usuarios
          </Button>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={10} className={classes.dataTable}>
        <UserListView state={state} setState={setState} setLoading={setLoading} />
      </Grid>
      <Backdrop open={loading} style={{ zIndex: 999999, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default UsersView;
