import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Poppins",
      "sans-serif"
    ].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    h1: { 
      fontSize: 36,
      fontWeight: 700,
      letterSpacing: 0.75, 
    }, 
    h2: { 
      fontSize: 30,
      fontWeight: 600,
      letterSpacing: 0.6, 
    },
    h3: { 
      fontSize: 24,
      fontWeight: 500,
      letterSpacing: 0.5,
    },
    h4: { 
      fontSize: 20,
      fontWeight: 600,
      letterSpacing: 0.3,
    }
  },
  palette: {
    primary:{
      main: '#aecc33',
      dark: '#90bf28'
    },
    secondary:{
      main: '#d66316',
      dark: '#b95513'
    },
    info: {
      main: '#576271'
    },
  },
  shape: {
    // borderRadius: 17
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: "#C5C6C6"
      },
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: "#c76a20"
        }
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.4,
        backgroundColor: "#C5C6C6",
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.4,
          backgroundColor: "#c76a20"
        }
      }
    },
    MuiButton: {
      contained: {
        color: "#FFFFFF",
        textTransform: "capitalize",
      },
      containedPrimary: {
        color: "#FFFFFF",
        textTransform: "capitalize",
      },
      outlined: {
        // color: '#FFFFFF',
        textTransform: "capitalize"
      },
    },
    MuiCheckbox: {
      indeterminate: {
        color: '#e3e3e3'
      }
    }
  }
});

export default theme;
