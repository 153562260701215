import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Chip, Switch, Tooltip, Typography } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import MaterialTable from "material-table";

import { materialTableProps } from "../../../common/materialTable";
import { changeState } from "./UserList";

const SwitchState = ({ rowData, state, setState, setLoading }) => {
  return (
    <>
      <Switch value="" checked={rowData.es_habilitado == 0 ? false : true} onChange={(e) => changeState(rowData, e, state, setState, setLoading)} inputProps={{ "aria-label": "" }} />
      {rowData.es_habilitado == 0 ? <Typography variant="caption"> No habilitado </Typography> : <Typography variant="caption"> Habilitado </Typography>}
    </>
  );
};

const UserListView = ({ state, setState, setLoading }) => {
  const history = useHistory();

  const showUser = (user) => {
    history.push(`/usuarios/ver/${user.id}`);
  };

  return (
    <MaterialTable
      {...materialTableProps}
      options={{
        pageSize: 10,
        filtering: true
      }}
      columns={[
        {
          title: "Nombre",
          field: "full_name",
        },
        {
          title: "Correo",
          field: "correo_electronico",
        },
        {
          title: 'Tipo usuario',
          field: 'roles[0].id',
          filtering: true,
          lookup: { 2: 'Encargado viña', 4: 'Guía'},
          render: (rowData) => {
            if(rowData.roles[0].id == 2) {
              return <span> Encargado viña </span>
            }
            return <span> Guía </span>
          }
        },
        {
          title: "Cambiar Estado",
          field: "es_habilitado",
          filtering: false,
          render: (rowData) => <SwitchState rowData={rowData} state={state} setState={setState} setLoading={setLoading} />,
        },
        {
          title: "Acciones",
          field: "verEditar",
          filtering: false,
          render: (rowData) => (
            <Tooltip title="Ver usuario">
              <Button variant="contained" color="primary" onClick={() => showUser(rowData)}>
                <Visibility />
              </Button>
            </Tooltip>
          ),
        },
      ]}
      data={state.data}
    />
  );
};

export default UserListView;
