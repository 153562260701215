import { Backdrop, Breadcrumbs, Button, CircularProgress, Grid, Link, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Text from '../../../common/Text';
import { validateCellPhone } from '../../../../helpers/validations';
import { handleClickBreadcumb } from '../../../../helpers/utils';
import { PersonAdd } from '@material-ui/icons';
import CustomSelect from '../../../common/Select';
import { createJury } from './NewJuries';
import { useEffect } from 'react';
import { getRegions } from '../../Register/Company/RegisterCompany';



const NewJuriesView = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [regions, setRegions] = useState([]);
    const [genders] = useState([
        {valor: 1, texto: "Masculino"},
        {valor: 2, texto: "Femenino"},
        {valor: 3, texto: "Prefiero no decirlo"},
    ]);
    const methods = useForm();
    const { handleSubmit, reset } = methods;

    const onSubmit = async (data) => {
       await createJury(data, setLoading, reset);
    }

    useEffect(() => {
        getRegions(setRegions)
    }, [])

  return (
    <Grid container justify="center">
        <Grid item xs={12} sm={10}>
            <Grid container justify='flex-end'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color='inherit' href="/inicio" onClick={(e) => handleClickBreadcumb(e, '/', history)}> Inicio </Link>
                    <Link color='inherit' href="/jurados/listado" onClick={(e) => handleClickBreadcumb(e, '/jurados/listado', history)}> Listado de jurados </Link>
                    <Typography color="textPrimary"> Crear jurado </Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={10} style={{marginBottom: '1rem'}}> 
            <Typography variant="h1" align="left" color="primary">
                Nuevo jurado
            </Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
            <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justify='center' spacing={1}>
                    <Grid item xs={12} sm={7}>
                        <Text nombre="nombres" etiqueta="Nombres"/>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Text nombre="apellido_p" etiqueta="Apellido paterno"/>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Text nombre="apellido_m" etiqueta="Apellido materno"/>
                    </Grid>
                    {/* <Grid item xs={12} sm={7}>
                        <Text nombre='rut' etiqueta='Rut' placeholder='12345678-9' rules={{
                            required: 'El campo Rut es requerido',
                            validate: validateRut
                        }}/>
                    </Grid> */}
                    <Grid item xs={12} sm={7}>
                        <Text nombre='correo_electronico' etiqueta='Correo' type='email' placeholder='jurado@gmail.com'/>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Text nombre='celular' etiqueta='Celular' type='number' placeholder='9 XXXX XXXX'
                            rules={{
                                required: false,
                                validate: (v) => validateCellPhone(v, false)
                            }}
                        />
                    </Grid>
                    <Grid item xs={10} sm={7}>
                        <CustomSelect nombre="gender_id" etiqueta="Género" opciones={genders} required/>
                    </Grid>
                    <Grid item xs={10} sm={7}>
                        <CustomSelect nombre='region_id' etiqueta='Región' opciones={regions} required/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button type="submit" color="secondary" variant="contained" startIcon={<PersonAdd />} disabled={loading} fullWidth>
                            Crear jurado
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
        </Grid>
        <Backdrop open={loading} style={{ zIndex: 999999, color: '#fff' }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </Grid>
  )
}

export default NewJuriesView