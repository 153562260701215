import {logout} from './JWTAuth';

async function api(url, method = "GET", body = null, isJson=false) {
    const baseUrl = process.env.REACT_APP_API_URL;
    
    let jwt = localStorage.getItem('access_token');

    let headers = {
        Authorization: 'Bearer ' + jwt,
        Accept: 'application/json',
    };

    if(isJson) { 
        headers = {...headers, 'Content-type': 'application/json'} 
    }

    let response = await fetch(baseUrl + url, {headers, method, body});
    
    switch (response.status) {
        case 200:
        case 201:
            return await response.json();
        case 401:
            return await logout();
        case 403:
            window.location.href = '/';
            break;
        case 404:
            return await response.json();
        default:
            return await response.json();
    }
}
export default api;