import React from 'react';
import { Route, Redirect } from "react-router-dom";

import { session } from '../../helpers/jwt/JWTAuth';

const PublicRoute = ({ component: Component, ...rest }) => {
    const getSession = session();
    
    if (getSession) {
        return <Redirect to={{ pathname: '/' }} />
    }

    return (
        <Route {...rest} render={props => (
            <div id="content" className='content ml-0'>
                <Component {...props} />
            </div>
        )} />
    );
};

export default PublicRoute;