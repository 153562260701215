import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

// Components
import { Menu, MenuItem, Grid, AppBar, Drawer, IconButton, CssBaseline, Hidden, Toolbar, Typography, Avatar, List, ListItem, ListItemIcon, Collapse } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// Icons
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { getUsuario } from "../../helpers/jwt/JWTAuth";
import { BarChart } from "@material-ui/icons";

const drawerWidth = "20%";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  avatar: {
    background: "linear-gradient(90deg, rgba(144,191,40,1) 0%, rgba(174,204,51,1) 100%)",
    color: "#FFF",
    heigth: 50,
    width: 50,
  },
  appBar: {
    backgroundColor: "#FAFAFA",
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100%",
    marginTop: theme.spacing(3),
    maxWidth: "80%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  containerItemsInfo: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("md")]: {
      width: "0%",
      flexShrink: 0,
    },
  },
  drawerPaper: {
    background: "linear-gradient(90deg, rgba(144,191,40,1) 0%, rgba(174,204,51,1) 100%)",
    border: "none",
    width: drawerWidth,
    [theme.breakpoints.down("md")]: {
      width: "30%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
  iconLink: {
    color: "#FFF",
    fontSize: 31,
    marginRight: theme.spacing(3),
  },
  logo: {
    width: "250px",
    height: "150px",
    marginBottom: theme.spacing(3),
  },
  links: {
    alignItems: "center",
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: 24,
    fontWeight: 700,
    display: "flex",
    opacity: 0.65,
    textDecoration: "none",
    [theme.breakpoints.down("lg")]: {
      fontSize: 20,
      fontWeight: 600,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  list: {
    width: "100%",
    maxWidth: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
  nestedIconLink: {
    color: "#FFF",
    fontSize: 12,
    marginRight: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  subLinks: {
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight: 500,
    opacity: 0.65,
    textDecoration: "none",
  },
  userName: {
    height: "100%",
    textAlign: "right",
  },
}));

const logout = async (e) => {
  e && e.preventDefault();
  localStorage.removeItem("access_token");
  localStorage.removeItem("user");
  window.location.href = "/login";
};

function ResponsiveDrawer({ component, ...rest }) {
  const user = getUsuario();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const window = rest.window;
  const classes = useStyles();
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDropdown = () => {
    setOpen(!open);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  const userOptions = (
    <>
      <List className={classes.list}>
        <ListItem>
          <ListItemIcon>
            <HomeOutlinedIcon fontSize="large" className={classes.iconLink} />
          </ListItemIcon>
          <NavLink exact to="/inicio" activeStyle={{ opacity: 1 }} className={classes.links}>
            Inicio
          </NavLink>
        </ListItem>
        {/* <ListItem disabled={true}>
                <NavLink exact to="#" activeStyle={{opacity: 1}} className={classes.links}>
                    <SearchIcon fontSize="large" className={classes.iconLink} />
                    Resultados
                </NavLink>
            </ListItem> */}
      </List>
    </>
  );
  const adminOptions = (
    <>
      <List className={classes.list}>
        <ListItem>
          <Grid container justify="space-between" alignItems="center">
            <NavLink exact to="/inicio" activeStyle={{ opacity: 1 }} className={classes.links}>
              <HomeOutlinedIcon fontSize="large" className={classes.iconLink} />
              Inicio
            </NavLink>
          </Grid>
        </ListItem>
        <ListItem onClick={handleDropdown}>
          <Grid container alignItems="center">
            <SettingsOutlinedIcon className={classes.iconLink} style={{ opacity: 0.6 }} />
            <span className={classes.links}> Mantenedores </span>
            {open ? <ExpandLess style={{ color: "#FFF", cursor: "pointer" }} /> : <ExpandMore style={{ color: "#FFF", cursor: "pointer" }} />}
          </Grid>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem className={classes.nested}>
              <NavLink exact to="/formularios/listado" activeStyle={{ opacity: 1 }} className={classes.subLinks}>
                <FiberManualRecordIcon className={classes.nestedIconLink} />
                Formularios
              </NavLink>
            </ListItem>
            <ListItem className={classes.nested}>
              <NavLink exact to="/jurados/listado" activeStyle={{ opacity: 1 }} className={classes.subLinks}>
                <FiberManualRecordIcon className={classes.nestedIconLink} />
                Jurados
              </NavLink>
            </ListItem>
            <ListItem className={classes.nested}>
              <NavLink exact to="/usuarios/listado" activeStyle={{ opacity: 1 }} className={classes.subLinks}>
                <FiberManualRecordIcon className={classes.nestedIconLink} />
                Usuarios
              </NavLink>
            </ListItem>
          </List>
        </Collapse>
        <ListItem>
          <Grid container justify="space-between" alignItems="center">
            <NavLink exact to="/reportes" activeStyle={{ opacity: 1 }} className={classes.links}>
              <BarChart fontSize="large" className={classes.iconLink} />
              Reportes
            </NavLink>
          </Grid>
        </ListItem>
      </List>
    </>
  );
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar>
          <Grid container direction="row" justify="flex-end" alignItems="baseline" className={classes.containerItemsInfo}>
            <Grid item xs={1} sm={3} md={3} lg={1}>
              <IconButton aria-label="open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={11} sm={6} md={4} lg={4} className={classes.info}>
              <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item xs={7} sm={7} md={7} lg={7} className={classes.userName}>
                  <Typography variant="caption" align="right" style={{ color: "#2E4765" }}>
                    {user.correo_electronico}
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Avatar className={classes.avatar}>{`${user.nombres.slice(0, 1).toUpperCase()}${user.apellido_p.slice(0, 1).toUpperCase()}`}</Avatar>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <MoreVertIcon style={{ fontSize: 30, color: "#2E4765" }} />
                  </IconButton>
                  <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="sidebar">
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <div className={classes.toolbar} />
            <img src="/assets/images/logo-enoturismo-2024.png" alt="Premios enoturismo" className={classes.logo} />
            {rest.isAdmin && adminOptions}
            {(rest.isNormal || rest.isJury) && user.es_habilitado == 1 && userOptions}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <div className={classes.toolbar} />
            <Grid container direction="column" alignItems="center" className={classes.containerInfo}>
              <img src="/assets/images/logo-enoturismo-2024.png" alt="Premios enoturismo" className={classes.logo} />
              {rest.isAdmin && adminOptions}
              {(rest.isNormal || rest.isJury) && user.es_habilitado == 1 && userOptions}
            </Grid>
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {component}
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
