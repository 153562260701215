import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { makeStyles, Typography, Link, Grid, Hidden, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import "../../../index.css";
import ButtonLoad from "../../common/ButtonLoad";
import Text from "../../common/Text";
import { login } from "./Login";

const useStyles = makeStyles({
  error: {
    marginTop: "1rem",
  },
  root: {
    height: "100vh",
  },
  img: {
    height: "100vh",
    width: "100%",
    position: "sticky",
    top: 0,
  },
});

const FormLogin = () => {
  const classes = useStyles();
  const history = useHistory();
  const [errors, setErrors] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const methods = useForm();
  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    await login(data, history, setErrors, setIsSubmitting);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text type="email" nombre="correo_electronico" etiqueta="Correo" rules={{ required: "Correo requerido" }} />
          <Text type="password" nombre="password" etiqueta="Contraseña" rules={{ required: "Contraseña requerida" }} />
          <ButtonLoad isSubmitting={isSubmitting} texto="iniciar sesión" />
        </form>
      </FormProvider>
      {errors && (
        <Alert severity="error" className={classes.error}>
          {Object.keys(errors).map((keyName, i) => (
            <div key={i}>
              <span className="input-label"> {errors[keyName]} </span>
            </div>
          ))}
        </Alert>
      )}
      <Grid item>
        <Typography variant="subtitle1" align="center">
          <Link to="/solicitar_cambio_contraseña" component={RouterLink}>
            ¿Olvidaste tu contraseña?
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Link to="registro?usuario=viña" component={RouterLink}>
          <Button variant="contained" color="secondary" style={{ borderRadius: '12px' }} fullWidth> Crear cuenta como viña </Button>
        </Link>
      </Grid>
      <Grid item>
        <Link to="registro?usuario=guia" component={RouterLink}>
          <Button variant="contained" style={{ backgroundColor: '#5C5177', borderRadius: '12px' }} fullWidth> Crear cuenta como guía </Button>
        </Link>
      </Grid>
    </>
  );
};

const Login = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      {/* Imagen login */}
      <Hidden smDown>
        <Grid item sm={6} style={{ background: "linear-gradient(90deg, rgba(164,186,73,1) 0%, rgba(120,128,41,1) 100%)" }}>
          {/* <img src={`${process.env.REACT_APP_URL}/assets/images/fondo.jpg`} alt="Bienvenida" className={classes.img}/>  */}
        </Grid>
      </Hidden>
      <Grid item sm={12} md={6}>
        <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
          <Grid item xs={10} sm={6}>
            <Grid container justify="center">
              <Grid item xs={7} sm={7} md={8} lg={6}>
                <img src={`${process.env.REACT_APP_URL}/assets/images/logo-enoturismo-color-2024.png`} alt="Logo enoturismo" loading="lazy" style={{ height: "100%", width: "100%" }} />
              </Grid>
            </Grid>
            <br />
            <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
              {children ? children : <FormLogin />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
