import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, FormControl, Grid, InputLabel, Select, Typography } from '@material-ui/core';
import RegionsListView from './Regions/RegionsListView';
import { getActiveForms } from './Reports';
import JuriesListView from './Juries/JuriesListView';

const ReportsView = () => {
    const [option, setOption] = useState(0);
    const [option2, setOption2] = useState(0);
    const [loading, setLoading] = useState(false);
    const [activeForms, setActiveForms] = useState([]);
    
    useEffect(() => {
        getActiveForms(setActiveForms);
    }, [ ]);

    const handleChange = (e, opt) => {
        if(opt == 1){
            setOption(e.target.value);
            return;
        }
        setOption2(e.target.value);
    }

    return (
        <Grid container justify="center" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={10}>
                <Typography variant="h1" color="primary" gutterBottom>
                    Reportes de evaluación
                </Typography>
            </Grid>
            <Grid item xs={12} sm={5} style={{marginBottom: '2rem'}}>
            <FormControl fullWidth>
                <InputLabel htmlFor="opt-form">Formulario</InputLabel>
                <Select
                    native
                    value={option}
                    onChange={(e) => handleChange(e, 1)}
                    inputProps={{
                        name: 'opt-form',
                        id: 'opt-form',
                    }}
                >
                <option value="0" disabled>Seleccione una opción</option>
                {
                    activeForms.map(i => <option key={i.value} value={i.value}>{i.text}</option>)
                }
                </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={5} style={{marginBottom: '2rem'}}>
            <FormControl fullWidth>
                <InputLabel htmlFor="opt-report">Tipo de reporte</InputLabel>
                <Select
                    native
                    value={option2}
                    onChange={(e) => handleChange(e, 2)}
                    inputProps={{
                        name: 'opt-report',
                        id: 'opt-report',
                    }}
                    disabled={option==0}
                >
                <option value="0" disabled>Seleccione una opción</option>
                <option value={1}>Por jurado</option>
                <option value={2}>Por región</option>
                <option value={3}>Nacional</option>
                </Select>
            </FormControl>
            </Grid>

            { option2 == 1 && <Grid item xs={12} sm={10}>
                <JuriesListView setLoading={setLoading} form={option}/>
            </Grid>  
            }
            { option2 == 2 && <Grid item xs={12} sm={10}>
                <RegionsListView setLoading={setLoading} form={option}/>
            </Grid> 
            }
            { option2 == 3 && <Grid item xs={12} sm={10}>
                <RegionsListView setLoading={setLoading} form={option} isNational={true}/>
            </Grid> 
            }
            <Backdrop open={loading} style={{ zIndex: 999999, color: '#fff' }}>
            <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>            
    )
}

export default ReportsView