import { toast } from "react-toastify";
import api from "../../../helpers/jwt/apiFetch";

const getPostulations = async (setState) => {
    const response = await api(`/jury/postulations`, 'GET');
    if(response.ok){
        setState({
            postulations: response.data,
        });
        return;
    }
    toast('Ups, ocurrió al obtener el listado de postulaciones.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { getPostulations }