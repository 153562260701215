import { toast } from "react-toastify"
import api from "../../../../helpers/jwt/apiFetch"

const getEvaluations = async (postulationId, juryId, setState) => {
    const response = await api(`/jury/evaluations?jury_id=${juryId}&form_user_id=${postulationId}`);

    if(response.ok){
        setState(response.data);
        return;
    }
    console.log(response.error)
    toast('Ups, ocurrió al obtener las evaluaciones.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const updateEvaluation = async (data, evaluation, state, setState) => {
    const response = await api(`/evaluation_answer/update?answer_jury_id=${evaluation.id}&evaluation=${ data.puntaje }`, 'PUT');
    if(response.ok){
        console.log(response)
        setState({...state, answers: state.answers.map(a => a.id == response.data.answer_id ? { ...a, evaluations: [response.data] } : a)})
        toast('Puntaje modificado.', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        return;
    }
    toast('Ups, ocurrió al modificar la evaluación.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { getEvaluations, updateEvaluation }