import React from 'react'
import {
  Button,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/app-theme';

const useStyle = makeStyles({
  wrapper: {
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  submitButton: {
    backgroundColor: 'linear-gradient(90deg, rgba(144,191,40,1) 0%, rgba(174,204,51,1) 100%)',
    borderRadius: '17px',
    boxShadow: '0px 3px 6px #00000029',
    color: 'white',
    height: 60,
    opacity: 1
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textButton: {
    color: '#FFFFFF',
    letterSpacing: '0.2rem',
    font: 'normal normal bold 20px/30px Poppins',
  },
});
const ButtonLoad = ({texto, isSubmitting, icono, icono2}) => {
  const classes = useStyle();
  return (
    <div className={classes.wrapper}>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        className={classes.submitButton}
        disabled={isSubmitting}
        startIcon={icono}
        endIcon={icono2}
      >
        <span className={classes.textButton}>{texto}</span>
      </Button>
      {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  )
}

export default ButtonLoad
