import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Backdrop, Breadcrumbs, Button, CircularProgress, Grid, Link, Typography } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { makeStyles } from '@material-ui/styles';

import { tamaños } from '../../../helpers/sizes';
import { getForms } from './Forms';
import theme from '../../../theme/app-theme';

// components
import Counter from '../../common/Counter';
import CustomDialog from '../../common/CustomDialog';
import FormListView from './FormList/FormListView';
import NewFormModalView from './NewForm/NewFormModalView';

const useStyles = makeStyles( {
    item: {
        padding: '16px 0',
    },
    dataTable: {
        boxShadow: '0px 3px 26px #00000017',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

const FormsView = () => {
    const classes = useStyles();
    const history = useHistory();
    const [state, setState] = useState({ actives: 0, data: [] });
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleClickButton = () => {
        history.push(`/formularios/crear`);
    }

    const handleClickBreadcumb = (event) => {
        event.preventDefault();
        history.push('/');
    }

    useEffect(()=>{
        getForms(setState);
    }, [ ]);

    return (
        <Grid container justify="center">
            <Grid item {...tamaños.l}>
                <Grid container justify='flex-end'>
                    <Breadcrumbs aria-label="breadcrumb">
                    <Link color='inherit' href="/inicio" onClick={handleClickBreadcumb}> Inicio </Link>
                    <Typography color="textPrimary"> Mantenedor de formularios </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item {...tamaños.l}> 
                <Grid container justify="space-between">
                    <Typography variant="h1" color="primary">
                        Mantenedor de Formularios
                    </Typography>  
                    <Button color="primary"variant="contained" startIcon={<AddCircleOutlineOutlinedIcon/>} onClick={() => setOpenModal(true)}>
                        Crear formulario
                    </Button> 
                </Grid>
            </Grid>
            <Grid item {...tamaños.l} className={ classes.item }>
                <Counter title="Formularios activos" counter={state.actives}/>
            </Grid>
            <Grid item {...tamaños.l} className={ classes.dataTable }>
                <FormListView state={state} setState={setState} setLoading={setLoading} />
            </Grid>
            <CustomDialog title='Nuevo formulario' open={openModal} setOpen={setOpenModal} form='new-form'>
                <NewFormModalView />
            </CustomDialog>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}

export default FormsView;