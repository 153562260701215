import MaterialTable from 'material-table';
import React from 'react'
import { materialTableProps } from '../../../common/materialTable';
import { Button, Tooltip, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { evaluate } from './PostulationList';
import { showPDF } from '../../HomeAdmin/PostulationList/PostulationList';
import { Assignment, AssignmentTurnedIn, Edit, GetApp } from '@material-ui/icons';
import { generateExcelEvaluations } from '../../Evaluations/EvaluationList/Evaluation';
import { getUsuario } from '../../../../helpers/jwt/JWTAuth';

const useStyles = makeStyles({
  btn: {
    marginTop: '0.5rem',
  }
});

const EvaluationsButtons = ({ rowdata, setLoading}) => {
  const history = useHistory();
  const classes = useStyles();
  const { evaluations } = rowdata;

  const handleClickEvaluate = async (postulationId) => {
    await evaluate(postulationId, history);
  }

  const handleClickContinueEvaluation = (postulationId) => {
    history.push(`/evaluacion/postulacion/${ postulationId }`);
  }

  const handleClickShowPdf = async (postulationId) => {
    setLoading(true);
    await showPDF(postulationId);
    setLoading(false);
  }

  const handleClickGenerateExcelEvaluations = async (evaluation) => {
    const user = getUsuario();
    setLoading(true);
    await generateExcelEvaluations(user.jury.id, evaluation.id);
    setLoading(false);
  }

  const handleClickEditEvaluations = (postulationId) => {
    history.push(`/evaluacion/editar/${ postulationId }`);
  }

  if(evaluations.length > 0) {
    return <>
      { 
        evaluations[0].state_id == 5 ? 
        <Tooltip title='Evaluar postulación'>
          <Button className={classes.btn} color='primary' variant='outlined' onClick={() => handleClickContinueEvaluation(rowdata.id)} fullWidth>
            <AssignmentTurnedIn />
          </Button>
        </Tooltip>
        :
        <>
        <Tooltip title='Descargar evaluaciones'>
          <Button className={classes.btn} color='default' variant='outlined' onClick={() => handleClickGenerateExcelEvaluations(rowdata)} fullWidth>
            <GetApp />
          </Button>
        </Tooltip>
        <Tooltip title='Editar evaluaciones'>
          <Button className={classes.btn} color='default' variant='outlined' onClick={() => handleClickEditEvaluations(rowdata.id)} fullWidth>
            <Edit />
          </Button>
        </Tooltip>
        </>
      }
      <Tooltip title="Ver respuestas">
        <Button className={classes.btn} color='secondary' variant='outlined' onClick={() => handleClickShowPdf(rowdata.id)} fullWidth>
          <Assignment />
        </Button>
      </Tooltip>
    </>
  } 

  return <>
    <Tooltip title='Evaluar postulación'>
      <Button className={classes.btn} color='primary' variant='outlined' onClick={() => handleClickEvaluate(rowdata.id)} fullWidth>
        <AssignmentTurnedIn />
      </Button>
    </Tooltip>
    <Tooltip title="Ver respuestas">
      <Button className={classes.btn} color='secondary' variant='outlined' onClick={() => handleClickShowPdf(rowdata.id)} fullWidth>
        <Assignment />
      </Button>
    </Tooltip>
  </>
}

export const PostulationListView = ({ data = [], setLoading }) => {
  return (
    <MaterialTable
      {...materialTableProps}
      options={{
        filtering: false,
      }}
      columns={[
        {
          title: 'Código',
          field: 'id',
        },
        {
          title: 'Empresa',
          field: 'user.companies[0].nombre',
        },
        {
          title: 'Postulación',
          field: 'form.nombre',
        },
        {
          title: 'Estado',
          // field: 'form.nombre',
          render: ({ evaluations }) => { 
            if(evaluations.length > 0){
              return evaluations[0].state_id == 5 ? <p>En evaluación</p> : <p>Evaluada</p>
            } else {
              return <p> Sin evaluar </p>
            }
          }
        },
        {
          title: 'Acciones',
          field: 'acciones',
          filtering: false,
          render: (rowData) => <EvaluationsButtons rowdata={rowData} setLoading={setLoading}/>
        },
      ]}
      data={data}
    />
  )
}
