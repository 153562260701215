import MaterialTable from 'material-table';
import React from 'react';
import { materialTableProps } from '../../../../common/materialTable';
import { Edit } from '@material-ui/icons';
import { Button, Tooltip } from '@material-ui/core';

const EvaluationListView = ({ data, setOpenDialog, setEvaluationState }) => {

    const handleClickEdit = (evaluation) => {
        setOpenDialog(true);
        setEvaluationState(evaluation);
    }

    return (
        <MaterialTable
            {...materialTableProps}
            options={{
                pageSize: 20,
            }}
            columns={[
            {
                title: 'Criterio',
                field: 'question.standard.titulo', 
                cellStyle: { width: "70%" },
            },
            {
                title: 'Puntaje',
                field: 'evaluations[0].puntaje',
                cellStyle: { width: "15%" },
                render: (rowData) => <> {rowData.evaluations[0].puntaje} Pts. </>
            },
            {
                title: 'Acciones',
                field: 'verEditar',
                filtering : false,
                cellStyle: { width: "15%" },
                render: (rowData) => 
                    <Tooltip title="Editar puntaje">
                        <Button variant="outlined" color="secondary" onClick={() => handleClickEdit(rowData.evaluations[0])}><Edit /></Button>
                    </Tooltip> 
            }
            ]}
            data={data}
        />
    )
}

export default EvaluationListView