import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Collapse } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const BigAlert = ({ children, severity='warning', variant='standard' }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={() => {setOpen(false);}}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
          }
          severity={severity}
          variant={variant}
          >
          { children }
        </Alert>
      </Collapse>
    </div>
  );
}

export default BigAlert;
