import React from 'react';
import { Grid, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel, FormHelperText } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

const RadioAlternatives = ({ nombre, etiqueta, alternatives, valorPorDefecto="", size="s", callback, disabled=false, rules }) => {
    const { control } = useFormContext();

    if(size == "s"){
        size = { xs: 12, sm: 4 }
    } else if (size == "m") {
        size = { xs: 12, sm: 6 }
    } else {
        size = { xs: 12 }
    }

    if(!alternatives){
        alternatives = [
            { id: 1, texto: 'Si' },
            { id: 2, texto: 'No' },
        ];
    }
    
    return (
        <Controller
            name={nombre}
            defaultValue={valorPorDefecto}
            control={control}
            rules={rules ? { ...rules } : { required: 'Debe seleccionar una alternativa' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl error={!!error} component="fieldset" fullWidth>
                    <FormLabel id={`radio-${nombre}`}> { etiqueta } </FormLabel>
                    <RadioGroup id={`radio-${nombre}`} value={value} onChange={onChange}>
                        {/* value define el radio seleccionado */}
                        <Grid container>
                            {alternatives.map(( alternative, index ) => (
                                <Grid item {...size} key={`radioalternative${index}`}>
                                    <FormControlLabel 
                                        value={`${alternative.id}`} 
                                        control={<Radio color="primary" onClick={callback && (() => callback(alternative))} disabled={disabled}/>} 
                                        label={alternative.texto}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </RadioGroup>
                    <FormHelperText>{ error && error.message }</FormHelperText>
                </ FormControl>
            )}
        />
    )
}

export default RadioAlternatives;
