import FormDataRecursive from "../../../utils/FormDataRecursive";

const login = async (data, history, setErrors, setIsSubmitting) => {
  setIsSubmitting(true);
  let formData = new FormDataRecursive();
  formData.appendRecursive(data);

  let response = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
    method: 'POST',
    body: formData,
  });

  setIsSubmitting(false);

  response = await response.json();

  if(response.ok) {
    console.log(response)
    localStorage.setItem('user', JSON.stringify(response.data));
    localStorage.setItem('access_token', response.data.token);
    history.push('/inicio');
  } else {
    setErrors(response.errors);
  }
}

export {
  login
}