import { toast } from "react-toastify";
import api from "../../../../helpers/jwt/apiFetch";
import FormDataRecursive from "../../../../utils/FormDataRecursive";

const registerGuide = async (data, setIsSubmitting, history, user) => {
  setIsSubmitting(true);
  let formData = new FormDataRecursive();
  formData.appendRecursive({
    nombre_empresa: data.nombre_empresa,
    direccion_empresa: data.direccion_empresa,
    ruta_imagen: data.ruta_imagen,
    user_id: user.id,
    region_id: data.region_id,
    commune_id: data.commune_id,
  });
  const response = await api("/guides", "POST", formData);
  setIsSubmitting(false);
  if (response.ok) {
    localStorage.setItem("user", JSON.stringify(response.data));
    if (!response.data.es_validado) {
      history.push("no-habilitado");
      return;
    }
    history.push("inicio");
  } else {
    toast("Ups, ocurrió un error al intentar registrarte", {
      position: toast.POSITION.TOP_RIGHT,
      type: "error",
    });
  }
};

export { registerGuide };
