import { Link, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import ButtonLoad from '../../common/ButtonLoad';
import Text from '../../common/Text';
import Login from '../Login/LoginView';
import { requestResetPassword } from './RequestResetPassword';

const RequestResetPasswordView = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const methods = useForm();
    const { handleSubmit } = methods;
  
    const onSubmit = async (data) => {
      await requestResetPassword(data, setIsSubmitting);
    }

    return <Login>
        <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
            <Text type="email" nombre="correo_electronico" etiqueta="Correo"/>
            <ButtonLoad isSubmitting={isSubmitting} texto='Solicitar cambio'/>
            <Typography variant="subtitle1" align='center'>
                <Link to="/login" component={RouterLink}>Ya tengo una cuenta</Link>
            </Typography>
        </form>
        </FormProvider>
    </Login>
}

export default RequestResetPasswordView