import React, { useContext } from 'react';
import { Button, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';
import { FormContext } from '../../../../../contexts/formContext';
import { deleteQuestion } from './Question';


const useStyles = makeStyles({
    container: {
        boxShadow: '0px 5px 25px #00000017',
        borderRadius: 25,
        padding: '2rem',
        margin: '1rem',
    },
});

const QuestionView = ({ question, setOpenDialog }) => {
    const classes = useStyles();
    const {state, dispatch} = useContext(FormContext);

    const handleEdit = (question) => {
        dispatch({type: 'SET_ACTUAL_QUESTION', payload: question});
        setOpenDialog(true);
    }

    // TODO: AGREGAR MODAL DE CONFIRMACION
    const handleDelete = async (question) => {
        await deleteQuestion(question, dispatch);
    }

    return (
      <Grid item xs={12} sm={10}>
        <Grid container spacing={1} className={ classes.container }>
          <Grid item xs={12} sm={10}>
            <Typography>
              { question.orden }. { question.titulo }
            </Typography>
          </Grid>
          {state.form.is_editable && 
          <Grid item xs={12} sm={2}>
            <Grid container justify='flex-end' spacing={1}>
              <Button variant='outlined' color='primary' style={{margin: '0.2rem'}} onClick={() => handleEdit(question) }>
                <Edit fontSize='small' style={{cursor: 'pointer'}}/>
              </Button>                            
              <Button variant='outlined' color='secondary' style={{margin: '0.2rem'}} onClick={() => handleDelete(question) }>
                <Close fontSize='small' style={{cursor: 'pointer'}}/>
              </Button>     
            </Grid>
          </Grid>
          }
        </Grid>
      </Grid>
    )
}

export default QuestionView