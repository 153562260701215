import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';

import Checkboxes from '../../../../common/MultipleCheckbox';
import FileInput from '../../../../common/FileInput';
import RadioAlternatives from '../../../../common/RadioGroup';
import Text from '../../../../common/Text';
import { validateTextResponse } from '../../../../../helpers/validations';
import { PostulationContext } from '../../../../../contexts/postulationContext';
import { answerQuestion } from './Question';

const useStyles = makeStyles({
  root: {
    borderRadius: 28,
    boxShadow: '0px 3px 6px #00000029',
    minHeight: '40vh',
    marginBottom: '2rem'
  },
  header: {
    background: 'linear-gradient(90deg, rgba(144,191,40,1) 0%, rgba(174,204,51,1) 100%)',
    borderTopLeftRadius: 28,
    borderTopRightRadius: 28,
    minHeight: '5vh',
    padding: '0.5rem 1rem',
  },
  body: {
    margin: '2rem 0',
    padding: '0 2rem',
    minHeight: '20vh'  
  },
  chip: {
    color: 'white',
    backgroundColor: '#D66316',
    border: '1px solid #D66316'
  },
});

const CustomChip = (({ text, variant = "outlined", size = "small" }) => {
  const classes = useStyles();
  return (
    <Chip label={ text } variant={ variant } size={ size } className={ classes.chip }/>
  );
});

const QuestionView = () => {
  const classes = useStyles();
  const { state: { data, questionSelected }, dispatch } = useContext(PostulationContext);
  const [alternatives, setAlternatives] = useState([]);
  const [fileTypes] = useState([
    {tipo: 'txt', mime: 'text/plain'},
    {tipo: 'pdf', mime: 'application/pdf'}, 
    {tipo: 'png', mime: 'image/png'},
    {tipo: 'jpg, jpeg', mime: 'image/jpeg'},
    {tipo: 'rar', mime: 'application/x-rar-compressed'},
    {tipo: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
    {tipo: 'docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'},
    {tipo: 'pptx', mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'},
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const [defaultFiles, setDefaultFiles] = useState([]);
  // Se inicializa form
  const methods = useForm({
    defaultValues: {
      multipleResponse: questionSelected.question_type_id == 1 ? questionSelected.alternatives.map(q => ({ ...q, checked: false })) : [],
      radioResponse: '',
      textResponse: '',
      numberResponse: '',
      otherResponse: '',
      linkResponse: '',
      files: '',  
      filesAttached: ''
    }
  });

  const goToBack = () => {
    dispatch({ type: 'UPDATE_QUESTION_SELECTED', payload: { orden: questionSelected.orden - 1 } });
  }

  const onSubmit = async (values) => {
    setIsLoading(true);
    await answerQuestion(values, dispatch, questionSelected, data.id);
    setIsLoading(false);
  }

  useEffect(() => {
    let isMounted = true;
    if(isMounted){
      setAlternatives(questionSelected.alternatives.map( alt => ({...alt, texto: alt.titulo}) ));
      if(questionSelected.answers.length > 0) {
        const { answers, alternatives, question_type_id } = questionSelected;
        if(answers[0].attachments?.length > 0) {
          setDefaultFiles(answers[0]?.attachments?.map(a => ({name: a.documento})));
        }
         
        methods.reset({
          multipleResponse: alternatives.map( alt => {
            if(answers[0]?.alternatives?.map(a => a.id).includes(alt.id)){ 
              return { ...alt, checked: true };
            } 
            return { ...alt, checked: false };
          }),
          textResponse: question_type_id == 3 ? questionSelected.answers[0].respuesta : '',
          radioResponse: question_type_id == 2 ? (answers[0].alternatives[0].id).toString() : '',
          numberResponse: question_type_id == 6 ? questionSelected.answers[0].respuesta : '',
          // otherResponse: '',
          linkResponse: question_type_id == 4 ? questionSelected.answers[0].respuesta || '' : '',
          files: '', 
          filesAttached: '',
        });
        return;
      }
      methods.reset({
        multipleResponse : questionSelected.alternatives.map(q => ({ ...q, checked: false })),
        textResponse: '',
        numberResponse: '',
        radioResponse: '',
        otherResponse: '',
        linkResponse: '',
        files: '',
        filesAttached: '',
      });
    }

    return () => { isMounted = false };
  }, [ questionSelected ]);
  
  return (
    <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Grid container justify="flex-start" className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          {/* INDICADOR OBLIGATORIEDAD */}
          <Grid container justify='flex-end'> { questionSelected.es_obligatoria == 1 && <CustomChip text='Obligatoria' /> } </Grid>
          {/* TITULO PREGUNTA */}
          <Grid container alignItems="center">
            <Typography variant='subtitle1' style={{ color: "#FFFFFF"}}> { questionSelected.titulo } </Typography>
          </Grid>
        </Grid>

        <Grid container direction='column' justify='space-between'>
          <Grid container className={ classes.body }>
            {/* PREGUNTA TIPO SELECCION MULTIPLE */}
            { questionSelected.question_type_id == 1 && <Checkboxes nombre='multipleResponse' alternatives={alternatives} required={questionSelected.es_obligatoria}/> }
            
            {/* PREGUNTA TIPO SELECCION UNICA */}
            { questionSelected.question_type_id == 2 && 
              <RadioAlternatives nombre='radioResponse' etiqueta='' size='l' alternatives={alternatives} 
                rules={{
                  required: questionSelected.es_obligatoria && 'Debe seleccionar una alternativa'
                }}
              /> 
            }

            {/* PREGUNTA TIPO TEXTO */}
            { questionSelected.question_type_id == 3 && (
              <Text nombre='textResponse' etiqueta='' type='text' placeholder='Ingrese respuesta' multiline={true}
                rules={{
                  required: questionSelected.es_obligatoria && 'Respuesta requerida',
                  validate:  validateTextResponse
                }}
              /> 
            )}

            {/* PREGUNTA TIPO ENLACE */}
            { questionSelected.question_type_id == 4 && (
              <Text nombre='linkResponse' etiqueta='' type='text' placeholder='Ingrese enlace'
                rules={{
                  required: questionSelected.es_obligatoria && 'Enlace requerido',
                  // pattern:{ value: /^(ftp|http|https):\/\/[^ "]+$/,message: 'Enlace inválido, favor anteponer http:// o https://' }
                }}
              /> 
            )}

            {/* PREGUNTA TIPO ARCHIVO */}
            { questionSelected.question_type_id == 5 && <FileInput nombre='files' fileTypesAllowed={fileTypes} defaultFiles={defaultFiles} cantidadNecesariaArchivos={questionSelected.cantidad_archivos} required={questionSelected.es_obligatoria} multiple/> }

            {/* PREGUNTA TIPO NUMERO */}
            { questionSelected.question_type_id == 6 && (
              <Text nombre='numberResponse' etiqueta='' type='number' placeholder='Ingrese número' 
                rules={{
                  required: questionSelected.es_obligatoria && 'Debe ingresar un número'
                }}
              /> 
            )}

            {/* SI PREGUNTA PERMITE ADJUNTAR ARCHIVO */}
            {
              questionSelected.permite_adjunto == 1 && (
              <>
                <Typography> { questionSelected.texto_adjunto || 'Adjunte archivo:' } </Typography>
                <FileInput nombre='filesAttached' fileTypesAllowed={fileTypes} multiple/>
              </>
            )}
          </Grid>

          {/* BOTONES */}
          <Grid container justify='space-between' spacing={1}>
            <Grid item xs={12} sm={3}>
              { questionSelected.orden > 1  && <Button variant='contained' color='secondary' startIcon={<NavigateBefore/>}  onClick={ goToBack } style={{borderBottomLeftRadius: 28}} fullWidth> Anterior </Button> }
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button type='submit' color='primary' variant='contained' fullWidth disabled={isLoading}>
                {
                  isLoading
                  ? 'Guardando...'
                  : 'Guardar respuesta'
                }
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              { data.form.questions.length > questionSelected.orden && <Button type="submit" variant='contained' color='secondary' endIcon={<NavigateNext/>} style={{borderBottomRightRadius: 28}} disabled={isLoading} fullWidth> Siguiente </Button> }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
    </FormProvider>
  )
}

export default QuestionView