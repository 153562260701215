import { AppBar, Button, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons';
import React, { useContext, useEffect} from 'react'
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {FormContext} from '../../../../contexts/formContext';
import CustomDialog from '../../../common/CustomDialog';
import Spinner from '../../../layout/Spinner';
import { getForm } from './EditForm';
import QuestionModalView from './Question/QuestionModalView';
import QuestionView from './Question/QuestionView';
import EmptyView from './Empty/EmptyView';
import StandardModalView from './Standard/StandardModalView';
import StandardView from './Standard/StandardView';

const useStyles = makeStyles({
    state: { 
        alignItems: 'center',
        backgroundColor: 'green',
        borderRadius: '0.5rem',
        color: '#FFFFFF',
        display: 'flex',
        fontWeight: 600,
        height: '2rem',
        justifyContent: 'center',
        padding: '0.5rem 1rem',
    },
});


const TabPanel= (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && ( 
            <Grid container justify='center'>
                {children}
            </Grid>
            )
        }
      </div>
    );
}

const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const EditFormView = () => {
    const { state, dispatch } = useContext(FormContext);
    const { id } = useParams();
    const { form } = state;
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openStandardDialog, setOpenStandardDialog] = useState(false);
    const [openEditStandardDialog, setOpenEditStandardDialog] = useState(false);

    const [value, setValue] = useState(0);

    useEffect(() => {
      getForm(id, dispatch);
    }, [ ])

    if(Object.entries(form).length === 0){
        return <Grid container justify='center'> 
            <Spinner size={60} />
        </Grid>
    }

    const handleAdd = () => {
        dispatch({type: 'SET_ACTUAL_QUESTION', payload: {}});
        setOpenDialog(true);
    }

    const handleAddStandard = () => {
        dispatch({type: 'SET_ACTUAL_STANDARD', payload: {}});
        setOpenStandardDialog(true);
    }
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <Grid container justify='center'>
        <Grid item xs={12} sm={10} style={{marginBottom: '2rem'}}>
            <Grid container justify='space-between'>
                <Grid item>
                    <Typography variant='h1' color='primary'> { form.nombre } { form.anio } </Typography>  
                </Grid>
                <Grid item xs={12} sm={2}>
                    { form.es_activo ? <div className={classes.state} style={{backgroundColor: '#64b684'}}> ACTIVO </div> : <div className={classes.state} style={{backgroundColor: '#ec6e7d'}}> INACTIVO </div> }
                </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12} sm={10}>
            <AppBar position="static" color='default'>
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Preguntas" {...a11yProps(0)} />
                    <Tab label="Criterios" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
        </Grid>

        <TabPanel value={value} index={0} style={{ width: '100%'}}>
          <Grid item xs={12} sm={10}>
            {
            form.questions.length == 0 ? 
              <EmptyView message='Formulario vacío, aún no has agregado preguntas' setOpenDialog={setOpenDialog}/>
            :
            <>
              <Grid container justify='center'>
                  { form.questions.map((q) => <QuestionView key={q.id} question={q} setOpenDialog={setOpenEditDialog}/> )}
              </Grid>
              { form.is_editable && 
              <Grid container justify='center'>
                <Button variant='contained' color='primary' onClick={handleAdd}> 
                  <AddCircle/> Agregar
                </Button>
              </Grid>
              }
            </>
            }
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1} style={{ width: '100%'}}>
          <Grid item xs={12} sm={10}>
            {
            form.standards.length == 0 ?
              <EmptyView message='Aún no has agregado criterios.' setOpenDialog={setOpenStandardDialog}/>
            :
            <>
              <Grid container justify='center'>
                { form.standards.map((s) => <StandardView key={s.id} standard={s} setOpenDialog={setOpenEditStandardDialog}/> )}
              </Grid>
              { form.is_editable && 
              <Grid container justify='center'>
                <Button variant='contained' color='primary' onClick={handleAddStandard}> 
                  <AddCircle/> Agregar
                </Button>
              </Grid>
              }
            </>
            }
          </Grid>
        </TabPanel>
        
        <CustomDialog title='Editar pregunta' open={openEditDialog} setOpen={setOpenEditDialog} form='question-form'>
            <QuestionModalView setOpenDialog={setOpenEditDialog}/> 
        </CustomDialog>

        <CustomDialog title='Nueva pregunta' open={openDialog} setOpen={setOpenDialog} form='question-form'>
            <QuestionModalView setOpenDialog={setOpenDialog}/> 
        </CustomDialog>

        <CustomDialog title='Nuevo criterio' open={openStandardDialog} setOpen={setOpenStandardDialog} form='standard-form'>
            <StandardModalView setOpenDialog={setOpenStandardDialog}/>
        </CustomDialog>

        <CustomDialog title='Editar criterio' open={openEditStandardDialog} setOpen={setOpenEditStandardDialog} form='standard-form'>
            <StandardModalView setOpenDialog={setOpenEditStandardDialog}/>
        </CustomDialog>
    </Grid>
}

export default EditFormView