import { toast } from "react-toastify";
import api from "../../../../helpers/jwt/apiFetch";

const changeState = async (rowData, event, state, setState, setLoading) => {
    setLoading(true); // backdrop true
    const data = state.data.map(user => {
        if (user.id === rowData.id) {
            return { ...user, es_habilitado: event.target.checked };
        } 
        return user;
    });

    let response = await api(`/users/${rowData.id}/update_state?_methods=PUT`, 'POST', {});

    setLoading(false); // backdrop false
    if(response.ok){
        toast('Estado de usuario actualizado exitosamente!', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        setState({
            actives: state.actives + (!event.target.checked ? 1 : -1),
            data
        });
        return;
    }
    
    toast(response.error, {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { changeState };