import React from 'react';
import { PostulationProvider } from '../../../../contexts/postulationContext';
import PostulationView from './PostulationView';

const Index = () => {
  return <PostulationProvider>
    <PostulationView />
  </PostulationProvider>
}

export default Index