import React from 'react';
import { Button, Switch, Tooltip, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';

import { materialTableProps } from '../../../common/materialTable';
import { changeState } from '../../Users/UserList/UserList';
import { Person, PostAdd } from '@material-ui/icons';

const SwitchState = ({ rowData, state, setState, setLoading }) => {
    return <>
        <Switch value="" checked={rowData.es_habilitado == 0 ? false : true} onChange={e => changeState(rowData, e, state, setState, setLoading)} inputProps={{ 'aria-label': '' }} />
        {
            rowData.es_habilitado == 0 ? <Typography variant="caption"> No habilitado </Typography> : <Typography variant="caption"> Habilitado </Typography>
        }
    </>
}

const JuriesListView = ({ state, setState, setLoading }) => {

    return (
        <MaterialTable
            {...materialTableProps}
            options={{
            pageSize: 10
            }}
            columns={[
            {
                title: 'Nombre',
                field: 'full_name',
            },
            {
                title: 'Región',
                field: 'jury.region.nombre',
            },
            {
                title: 'Fecha creación',
                field: 'created_at',
                render: rowData => rowData.created_at.split('T')[0]
            },
            {
                title: 'Estado',
                field: 'es_habilitado',
                filtering : false,
                render: rowData => <SwitchState rowData={rowData} state={state} setState={setState} setLoading={setLoading} />
            },
            {
                title: 'Acciones',
                field: 'verEditar',
                filtering : false,
                render: (rowData) => 
                <>
                    <Tooltip title="Ver información de jurado">
                        <Button variant="contained" color="secondary" style={{margin: '2px'}} disabled><Person /></Button>
                    </Tooltip>
                </>
            }
            ]}
            data={state.data}
        />
    )
}

export default JuriesListView;
