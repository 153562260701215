import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import React from 'react'
import { download } from './Postulation';
import BigAlert from '../../../layout/BigAlert';

const useStyles = makeStyles({
    body: {
    //   margin: '2rem 0',
    //   padding: '0 2rem',
    //   minHeight: '5vh'  
    },
});

const AnswerView = ({ question }) => {
    const classes = useStyles();
    const handleClickDownload = async (attachmentId) => {
        await download(attachmentId);
    }

    if(question.answers.length == 0){
        return (<Typography> No existe respuesta. </Typography>);
    }

    if([1, 2].includes(question.question_type_id)){
        return (
        <Grid container className={ classes.body }>
            Respuesta: 
            <ul>
                { question.answers[0].alternatives.map(a => <li key={a.id}> { a.titulo } </li>) }
            </ul>  
        </Grid>
        )
    }

    if(question.question_type_id == 4){
        return (
        <Grid container className={ classes.body }>
            <Typography gutterBottom paragraph> Respuesta: </Typography> 
            <Typography align='justify' style={{textIndent: '3rem'}} gutterBottom paragraph>
                {
                ['https://', 'http://'].some(i => question.answers[0].respuesta != null && question.answers[0].respuesta.includes(i)) ?
                <a target='_blank' href={question.answers[0].respuesta}> {question.answers[0].respuesta} </a>
                :
                (question.answers[0].respuesta != null ? question.answers[0].respuesta : 'No existe respuesta.')
                }
            </Typography>
        </Grid>
        )
    }

    if([3, 6].includes(question.question_type_id)){
        return (
        <Grid container className={ classes.body }>
            <Typography gutterBottom paragraph> Respuesta: </Typography> 
            <Typography align='justify' style={{textIndent: '3rem'}} gutterBottom paragraph> { question.answers[0].respuesta || 'No existe respuesta.' } </Typography>
        </Grid>
        )
    }

    return (
        <>
        <BigAlert variant='standard'>
            <Typography> Imágenes de uso exclusivo Premios Enoturismo Chile. Prohibido su uso y reproducción. </Typography>
        </BigAlert>
        <TableContainer>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Archivo</TableCell>
                        <TableCell>Descargar</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                { 
                    question.answers[0].attachments.map((a, idx) => <TableRow key={'attach'+a.id}>
                        <TableCell> {`ARCHIVO ${ idx+1 }`} </TableCell>
                        <TableCell> <Button color='secondary' variant='outlined' onClick={() => handleClickDownload(a.id)}> <GetApp/> </Button> </TableCell>
                        </TableRow>
                    )
                }
                </TableBody>
            </Table>
        </TableContainer>
        </>
    );
}

export default AnswerView