import { toast } from "react-toastify";
import FormDataRecursive from "../../../../../utils/FormDataRecursive";
import api from "../../../../../helpers/jwt/apiFetch";

const createStandard = async (data, setOpenDialog, formContext) => {
    const { dispatch, state: { form } } = formContext;
    data.orden = form.standards.length + 1;
    data.form_id = form.id;

    let formData = new FormDataRecursive();
    formData.appendRecursive(data);
    const response = await api('/standards', 'POST', formData);
    setOpenDialog(false);
    if(response.ok){
        toast('Criterio agregada exitosamente!', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        dispatch({type: 'ADD_STANDARD', payload: response.data});
        return;
    }
    toast('Ups, ocurrió un error al intentar agregar el criterio', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const editStandard = async (data, setOpenDialog, formContext) => {
    const { dispatch, state: { form, standard } } = formContext;
    data.form_id = form.id;
    let formData = new FormDataRecursive();
    formData.appendRecursive(data);
    const response = await api(`/standards/${ standard.id }`, 'POST', formData);
    setOpenDialog(false);
    if(response.ok){
        toast('Criterio editado exitosamente!', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        dispatch({type: 'UPDATE_STANDARD', payload: response.data});
        return;
    }
    toast('Ups, ocurrió un error al intentar agregar el criterio', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const deleteStandard = async (data, state, dispatch) => {
    const is_used = state.form.questions.some(q => q.standard_id == data.id);

    if(is_used){
        toast('No se puede eliminar un criterio que se encuentra asociado a una pregunta.', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'warning'
        });
        return;
    }
    
    const confirm = window.confirm('¿Está seguro de eliminar el criterio?');
    if(!confirm) return; 

    const response = await api(`/standards/${ data.id }`, 'DELETE');
    if(response.ok){
        toast('Criterio eliminado exitosamente!', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        dispatch({type: 'DELETE_STANDARD', payload: response.data});
        return;
    }
    toast('Ups, ocurrió un error al intentar eliminar el criterio', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { createStandard, deleteStandard, editStandard }