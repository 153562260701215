import React from 'react';
import { Grid } from '@material-ui/core';
import GreetingCard from '../../common/GreetingCard';

const BlockedView = () => {
  const mesagge = 'Le enviaremos un correo de confirmación en cuanto su cuenta se encuentre disponible. Una vez recibido el correo, por favor cierre y vuelva a iniciar su sesión.';
  return (
    <Grid container justify="center"> 
      <Grid item xs={10}>
        <GreetingCard mesagge={mesagge} />
      </Grid>
    </Grid>
  )
}

export default BlockedView
