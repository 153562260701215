const validateRut = (rut) => {
    let formatedRut = rut.trim().split('-');
    return (formatedRut.length === 2 
        && formatedRut[0].length <= 9 
        && formatedRut[0].length >= 7 
        && ['0','1','2','3','4','5','6','7','8','9','k','K'].includes(formatedRut[1]))
        || 'Formato de rut inválido';
}
const validateCellPhone = (cellPhone, required=true) => {
    return (cellPhone.length === 9 || !required) || 'Formato de celular inválido';
} 

// telefono es opcional
const validatePhone = (phone) => {
    return (phone.length === 9 || !phone) || 'Formato de telefono inválido';
}

const validateTextResponse = (response) => {
    return (response.trim().length <= 2500) || 'La respuesta excede el máximo de carácteres permitidos'
}

const validateSizeFile = (v, cantidadNecesariaArchivos, requerido=true) => {
    if(!requerido){
      return true;
    }
    if(v.length != cantidadNecesariaArchivos) {
      return false;
    }
    if(v.length == 0) {
      return true;
    }
    let totalSize = 0;
    let isValidated = false;
    for (let i = 0; i < v.length; i++) {
      if(parseInt(v[i]?.size) < 10485760){
        totalSize += parseInt(v[i]?.size);
        isValidated = true;
      } else {
        return false;
      }
    }
    
    if( totalSize >= 10485760) {
      return false;
    }
    return isValidated;
}
  
const validateTypeFile = (v, fileTypes) => {
    let permitido = false;
    if (v.length == 0) {
        return true;
    }

    for (let i = 0; i < v.length; i++) {
        if(fileTypes.map(item => item.mime).includes(v[i]?.type)){
        permitido = true;
        } else {
        return false;
        }
    }
    return permitido;
}

export {
    validateRut,
    validateCellPhone,
    validatePhone,
    validateTextResponse,
    validateSizeFile,
    validateTypeFile
}