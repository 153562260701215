import React, { createContext, useReducer } from 'react';

const PostulationContext = createContext();

const formReducer = (state, action) => {
    const { type, payload = {} } = action;

    switch (type) {
        case 'UPDATE_POSTULATION': 
            return payload;
        case 'UPDATE_QUESTION_SELECTED':
            let questionSelected = {};
            if(Object.keys(payload).includes('id')){
                // Se busca pregunta por id
                questionSelected = state.data.form.questions.filter(q => q.id == payload.id)[0];
            } else {
                // Se busca pregunta por orden
                questionSelected = state.data.form.questions.filter(q => q.orden == payload.orden)[0];
            }
            return {...state, questionSelected};

        case 'UPDATE_ANSWER':
            const nQuestions = state.data.form.questions.length;
            let nextQuestion = {};

            let questions = state.data.form.questions.map((q, index) => {
                if(q.id == payload.question_id){
                    if((index+1) < nQuestions){
                        nextQuestion = state.data.form.questions[index + 1];
                    } else {
                        const firstNotAnswered = state.data.form.questions.filter(q => q.es_obligatoria == 1 && q.answers.length == 0);
                        if(firstNotAnswered.length > 0){
                            nextQuestion = firstNotAnswered[0];
                        } else {
                            nextQuestion = q;
                        }
                    }
                    return { ...q, answers: [payload] };
                }
                return q;
            });

            const n_questions_answered = questions.filter(q => q.es_obligatoria == 1 && q.answers.length > 0).length;
            const isComplete = state.mandatoryQuestions - n_questions_answered == 0 ? true : false;
            return { 
                ...state,
                questionSelected: nextQuestion, 
                isComplete,
                isCompleteAux: isComplete,
                data: { 
                    ...state.data,
                    form: { ...state.data.form, questions }
                }
            }
        case 'UPDATE_IS_COMPLETE':
            return { ...state, isComplete: payload }
        default:
            return state;
    }
}

const PostulationProvider = ({ children }) => {
    const initialState = { 
        data: {},
        questionSelected: {},
        isComplete: false,
        isCompleteAux: false,
        mandatoryQuestions: 0,
    };

    const [state, dispatch] = useReducer(formReducer, initialState); 

    return (
        <PostulationContext.Provider value={{
            state,
            dispatch,
        }}>
            { children }
        </PostulationContext.Provider>
    )
}

export {PostulationProvider, PostulationContext};