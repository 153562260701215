import { toast } from "react-toastify";
import api from "../../../../helpers/jwt/apiFetch";
import FormDataRecursive from "../../../../utils/FormDataRecursive";

const getPostulationByStandard = async (postulationId, dispatch) => {
    const response = await api(`/evaluation/postulations/${ postulationId }`, 'GET');
    if(response.ok){
        const { questions } = response.data;
        dispatch({type: 'SET_POSTULATION', payload: {
            ...response.data,
            question: questions[0]
        }})
        return;
    }
    toast('Ups, ocurrió al obtener la postulacion.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const download = async (attachmentId, setLoading) => {
    // setLoading(true);
    let response = await fetch(`${process.env.REACT_APP_API_URL}/download_attachment/${ attachmentId }`,{
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        }
    });
    // setLoading(false);
    if(response.status == 200){
        const blob = await response.blob();
        const extension = blob.type.split('/')[1];
        const urlBlob = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = urlBlob;
        a.download = `Archivo_${attachmentId}.${extension}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(urlBlob);
        return;
    }

    toast('No se encontró el archivo.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const toPrevQuestion = (order, dispatch) => {
    dispatch({ type: 'UPDATE_QUESTION', payload: order - 1 });
}

const toNextQuestion = (order, dispatch,e) => {
    e.preventDefault();
    dispatch({ type: 'UPDATE_QUESTION', payload: order + 1 });
}

const evaluate = async ({question, data, dispatch, setLoading}) => {
    let formData = new FormDataRecursive();
    formData.appendRecursive({
        answer_id: question.answers[0].id,
        evaluation: parseInt(data.evaluation),
    });
    setLoading(true);
    const response = await api('/evaluate_answer', 'POST', formData);
    setLoading(false);
    if(response.ok){
        const questionEvaluated = { ...question, 
            answers: [ { ...question.answers[0], evaluations: [response.data]} ]
        };
        dispatch({ type: 'UPDATE_EVALUATION', payload: questionEvaluated });
        return;
    }

    toast('Ups, ocurrió un error al evaluar la respuesta.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });

    return;
}

const updateEvaluation = async ({question, data, dispatch, setLoading}) => {
    const { evaluations } = question.answers[0];

    setLoading(true);
    const response = await api(`/evaluation_answer/update?answer_jury_id=${evaluations[0].id}&evaluation=${ data.evaluation }`, 'PUT');
    setLoading(false);
    if(response.ok){
        const questionEvaluated = { ...question, 
            answers: [ { ...question.answers[0], evaluations: [response.data]} ]
        };
        dispatch({ type: 'UPDATE_EVALUATION', payload: questionEvaluated });
        return;
    }
    toast('Ups, ocurrió un error al modificar la evaluación de la respuesta.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
    return;
}

export { getPostulationByStandard, download, toPrevQuestion, toNextQuestion, evaluate, updateEvaluation }