import { toast } from "react-toastify";
import api from "../../../../helpers/jwt/apiFetch";
import FormDataRecursive from "../../../../utils/FormDataRecursive";

const changeState = async (rowData, event, state, setState, setLoading) => {
    setLoading(true); // backdrop true
    const data = state.data.map(form => {
        if (form.id === rowData.id) {
            return { ...form, es_activo: event.target.checked };
        } 
        return form;
    });

    let formData = new FormDataRecursive();

    formData.appendRecursive({
        es_activo: rowData.es_activo ? 0 : 1
    });

    let response = await api(`/forms/update/${rowData.id}?_methods=PUT`, 'POST', formData);
    setLoading(false); // backdrop false
    if(response.ok){
        toast('Estado de formulario actualizado exitosamente!', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        setState({
            actives: state.actives + (!event.target.checked ? 1 : -1),
            data
        });
        return;
    }
    
    toast('Ups, ocurrió un error al intentar cambiar el estado del formulario', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const editForm = (row, history) => {
    history.push(`/formularios/editar/${row.id}`);
}

export { changeState, editForm }