import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Backdrop, Breadcrumbs, CircularProgress, Grid, Link, Typography } from '@material-ui/core';
import { tamaños } from '../../../helpers/sizes';
import { getEvaluations } from './Evaluations';
import Spinner from '../../layout/Spinner';
import EvaluationListView from './EvaluationList/EvaluationListView';
import { handleClickBreadcumb } from '../../../helpers/utils';

const EvaluationsView = () => {
    const { form_user_id } = useParams();
    const history = useHistory();
    const [state, setState] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getEvaluations(form_user_id, setState)
    }, [])

    if(Object.entries(state).length == 0){
        return <Grid container justify='center'> 
            <Spinner size={60} />
        </Grid>
    }

    const { user } = state.form_user ;

    return (
        <Grid container justify="center">
            <Grid item {...tamaños.l}>
                <Grid container justify='flex-end'>
                    <Breadcrumbs aria-label="breadcrumb">
                    <Link color='inherit' href="/inicio" onClick={(e) => handleClickBreadcumb(e, '/', history)}> Inicio </Link>
                    <Typography color="textPrimary"> Evaluaciones empresa </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item {...tamaños.l} style={{ marginBottom: '2rem' }}> 
                <Grid container direction='column'>
                    <Typography variant="h1" color="primary">
                        Evaluaciones postulación #{ form_user_id } 
                    </Typography> 
                    <Typography variant="h2" color="secondary">
                        Empresa { user.company.nombre }
                    </Typography>  
                </Grid>
            </Grid>
            <Grid item {...tamaños.l}>
                <EvaluationListView data={state.evaluations} setLoading={setLoading}/>
            </Grid>
            <Backdrop open={loading} style={{ zIndex: 999999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}

export default EvaluationsView