import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        height: '60vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

const Spinner = ({ size }) => {
    const classes = useStyles();
    return <div className={classes.root}>
        <CircularProgress size={size}/>
    </div>
}

export default Spinner;