import { toast } from "react-toastify";
import api from "../../../helpers/jwt/apiFetch";

const getPostulations = async (userId, setState) => {
    const response = await api(`/postulations/${ userId }`, 'GET');
    if(response.ok) {
        setState((state) => ({...state, postulations: response.data}));
        return;
    }
    toast('Ups, ocurrió al obtener el listado de postulaciones', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const getForms = async (userId, setState) => {
    const response = await api(`/forms/user/${ userId }`, 'GET');
    if(response.ok) {
        setState((state) => ({...state, forms: response.data}));
        return;
    }
    toast('Ups, ocurrió al obtener el listado de formularios', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { getPostulations, getForms }