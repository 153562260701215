import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    container: {
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: 26,
        minHeight: 150,
        padding: 16
    },
    totalContainer: { borderBottom: '5px solid #FFF' }
});

const CardState = ({ background, icon, title, total }) => {
    const classes = useStyles();
    return (
        <Grid container spacing={1} className={classes.container} style={{background: `${background}`}}>
            <Grid container alignItems="center">
                { icon }
                <Typography variant="h4" style={{color: '#FFF'}}> 
                    { title }
                </Typography>
            </Grid>
            <Grid item className={classes.totalContainer}>
                <Typography variant="h1" style={{fontSize: 60, color: '#FFF'}}> 
                    { total }
                </Typography>
            </Grid>
        </Grid>
    )
}

export default CardState;

