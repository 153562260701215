import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Grid, Link } from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form';
import ButtonLoad from '../../../common/ButtonLoad';
import CustomSelect from '../../../common/Select';
import Text from '../../../common/Text';
import FileInput from '../../../common/FileInput';
import { getCommunes, getRegions } from '../Company/RegisterCompany';
import { registerGuide } from './RegisterGuide';
import { getUsuario } from '../../../../helpers/jwt/JWTAuth';

const useStyle = makeStyles({
    input: {
      padding: '0 3rem 0 3rem'
    },
  });

const RegisterGuideView = () => {
    const user = getUsuario();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [communes, setCommunes] = useState([]);
    const [regions, setRegions] = useState([]);
    const classes = useStyle();
    const history = useHistory();
    const methods = useForm({
        defaultValues: {
          nombre_empresa: '',
          direccion_empresa: '',
          commune_id: '',
          region_id: '',
          ruta_imagen: '',
        }
    });
    const { handleSubmit } = methods;
    const onSubmit = async (data) => {
        await registerGuide(data, setIsSubmitting, history, user);
    }

    useEffect(() => {
        getRegions(setRegions);
        getCommunes(setCommunes);
    }, []); 

    return <Grid container direction='column' alignItems='center' justify='center' spacing={3} style={{width: '100%',height: '100%'}}>
    <Grid item style={{ margin: '5rem 0 2rem 0'}}>
      <Typography variant='h1' color='primary' align='center'> Formulario de registro </Typography>
    </Grid>
    <FormProvider {...methods}>
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
      <Grid container justify='center' alignItems='center' spacing={1}>
        <Grid item xs={10} sm={7} className={ classes.input }> 
          <Text nombre='nombre_empresa' etiqueta='Nombre de empresa en la que trabaja' />
        </Grid>
        <Grid item xs={10} sm={7} className={ classes.input }> 
          <Text nombre='direccion_empresa' etiqueta='Dirección de empresa' />
    </Grid>
        <Grid item xs={12} sm={7} className={ classes.input }>
          <CustomSelect nombre="commune_id" etiqueta="Comuna" opciones={communes} required/>
        </Grid>
        <Grid item xs={12} sm={7} className={ classes.input }>
          <CustomSelect nombre="region_id" etiqueta="Región" opciones={regions} required/>
        </Grid>
        <Grid item xs={12} sm={7}>
            <Typography variant='subtitle1'> Adjunte imagen de usted (En formato horizontal, JPG y alta resolución): </Typography>
            <FileInput nombre='ruta_imagen'/>
        </Grid> 
        <Grid item xs={10} sm={7} style={{padding: '0 3rem 0 3rem',marginBottom: '2rem'}}> 
          <ButtonLoad texto='Registrarme' isSubmitting={isSubmitting} />
        </Grid>
      </Grid>
    </form> 
    </FormProvider>
  </Grid>
}

export default RegisterGuideView;