import { toast } from "react-toastify";

const generateExcelEvaluations = async(juryId, formUserId) => {
    let response = await fetch(`${process.env.REACT_APP_API_URL}/juries/evaluations?jury_id=${ juryId }&form_user_id=${ formUserId }`,{
        method: 'GET',
        headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        }
    });
    if(response.status == 200){
        const blob = await response.blob();
        const urlBlob = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = urlBlob;
        a.download = `postulacion_${formUserId}_jurado_${juryId}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(urlBlob);
        return;
    }

    toast('Ocurrió un problema al intentar generar el excel.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { generateExcelEvaluations }