import React from 'react'
import { useFormContext } from "react-hook-form";
import { Controller } from "react-hook-form";
import { Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

const CustomSelect = ({ nombre, etiqueta, errorTexto, opciones=[], valorPorDefecto, multiple=false, required=false }) => {
  const { control } = useFormContext();
  valorPorDefecto = valorPorDefecto == undefined ? 0 : valorPorDefecto; 
  if(etiqueta === undefined){
    const partes = nombre.split('_')
    for (let i=0; i<partes.length; i++) {
      partes[i] = partes[i].charAt(0).toUpperCase() + partes[i].slice(1);
    }
    etiqueta = partes.join(' ');
  }
  if(errorTexto === undefined){
    errorTexto = `El ${etiqueta} es requerido`
  }
  return (
    <Controller
      name={nombre}
      control={control}
      rules={{ 
        validate: value => {
          if(required) return value != 0 || "Seleccione una opción válida"
          return true;
        }
      }}
      defaultValue={valorPorDefecto}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="question-type-select"> { etiqueta } </InputLabel>
          <Select
            id="question-type-select"
            autoWidth={true}
            label={etiqueta}
            value={value}
            onChange={onChange}
            error={!!error}
            style={{ marginBottom: error ? 0 : 22 }}
            multiple={multiple}
            renderValue={(selected) => {
              if(typeof selected != 'object') {
                if(selected == 0) return <em> Seleccione una opción </em>;
                return opciones.filter(opt => opt.valor == selected)[0]?.texto;
              }
              if(selected.length == 1 && selected[0] == 0){
                return <em> Seleccione una opción </em>;
              } 
              return selected.length >= 1 && (selected.map((value) => {
                const opc = opciones.find(opc => opc.valor == value);
                return opc && <Chip key={value} label={opc?.texto}/>
              }))
            }}
          >
            <MenuItem value={0} disabled>
              <em> Seleccione una opción </em>
            </MenuItem>
            {
              opciones.map( ({valor, texto}) => <MenuItem value={valor} key={valor}>{texto}</MenuItem>)
            }
          </Select>
          { error && <FormHelperText error> { error.message } </FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export default CustomSelect;
