import React, { useEffect, useState } from 'react';
import { Typography, Grid, CircularProgress, Backdrop} from '@material-ui/core';
import Counter from '../../common/Counter';
import { tamaños } from '../../../helpers/sizes';
import GreetingCard from '../../common/GreetingCard';
import { getUsuario } from '../../../helpers/jwt/JWTAuth';
import { getForms, getPostulations } from './HomeUser';
import PostulationListView from './PostulationList/PostulationListView';
import FormListView from './FormList/FormListView';
import Spinner from '../../layout/Spinner';

const HomeUserView = () => {
    const user = getUsuario();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        forms: [],
        postulations: [],
        loading: false
    });

    useEffect(()=>{
        setState({...state, loading: true});
        getForms(user.id, setState);
        getPostulations(user.id, setState);
        setState({...state, loading: false});
    }, []);

    if(state.loading){
        return <Grid container justify='center'> 
            <Spinner size={60} />
        </Grid>
    }

    return (
        <Grid container justify="center" alignItems="center">
            <Grid item {...tamaños.l}>
                <Typography variant="h1" color="primary" gutterBottom>
                    {`Premios Enoturismo ${new Date().getFullYear()}`}
                </Typography>
            </Grid>
            <Grid item {...tamaños.l}>
                <GreetingCard />
            </Grid>
            <Grid item {...tamaños.l}>
                <Typography variant="h2" color="secondary" gutterBottom>
                    Formularios
                </Typography>
            </Grid>
            <Grid item {...tamaños.l}>
                <Counter title="Disponibles" counter={state.forms.length}/>
            </Grid>
            <Grid item {...tamaños.l}>
                <FormListView data={state.forms}/>
            </Grid>
            <Grid item {...tamaños.l} style={{ marginTop: '2rem' }}>
                <Typography variant="h2" color="secondary" gutterBottom>
                    Postulaciones
                </Typography>
            </Grid>
            <Grid item {...tamaños.l}>
                <PostulationListView data={state.postulations} setLoading={setLoading}/>
            </Grid>
            <Backdrop open={loading} style={{ zIndex: 999999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}

export default HomeUserView;
