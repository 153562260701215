import { toast } from "react-toastify";
import FormDataRecursive from "../../../../utils/FormDataRecursive";

const registerUser = async (data, setIsSubmitting, history, dispatch, userType) => {
    setIsSubmitting(true);
    let formData = new FormDataRecursive();
    formData.appendRecursive({
      nombres: data.nombres,
      apellido_p: data.apellido_p,
      apellido_m: data.apellido_m,
      rut: parseInt(data.rut_encargado.split('-')[0]),
      digito_verificador: data.rut_encargado.split('-')[1],
      gender_id: parseInt(data.gender_id),
      telefono: data.telefono_encargado || null,
      celular: data.celular_encargado,
      cargo: data.cargo_encargado || null,
      correo_electronico: data.correo_1,
      correo_electronico_2: data.correo_2,
      password: data.password_1.replaceAll(' ', ''),
      password_confirmation: data.password_2.replaceAll(' ', ''),
      user_type: userType
    });

    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/register`, {
        method: 'POST',
        headers: {
          ContentType: 'application/json',
        },
        body: formData
      }
    );

    response = await response.json();
    
    setIsSubmitting(false);

    if(response.ok) {
      let jwt = response.data.token;
      let datos = JSON.stringify(response.data.user)
      localStorage.setItem("user", datos);
      localStorage.setItem("access_token", jwt);
      dispatch({ type: 'set', user: response.user, token: jwt })
      history.push("/");
    } else {
      Object.values(response.errors).forEach(error => {
        toast(error[0], {
          position: toast.POSITION.TOP_RIGHT,
          type: 'error'
        });
      });
    }
}


export { registerUser }