import React from 'react';
import MaterialTable from 'material-table';
import { materialTableProps } from '../../../common/materialTable';
import { Button, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

const EvaluationsListView = ({ data, setOpenDialog, setAnswerSelected }) => {
    const handleClickEdit = (rowData) => {
        setAnswerSelected(rowData);
        setOpenDialog(true);
    }
    return (
        <MaterialTable
        {...materialTableProps}
        options={{
        filtering: false,
        }}
        columns={[
        {
            title: 'Pregunta',
            field: 'question.titulo',
        },
        {
            title: 'Respuesta',
            field: 'response',
            render: rowData => <ul>
                {rowData.alternatives.map(response => 
                    <li key={response.id}> { response.titulo } </li>
                )}
            </ul>
        },
        {
            title: 'Puntaje',
            field: 'puntaje',
        },
        {
            title: 'Acciones',
            field: 'actions',
            render: rowData => 
                <Tooltip title='Editar ptje.'>
                    <Button color='secondary' variant='outlined' onClick={() => handleClickEdit(rowData)}> <Edit/> </Button>
                </Tooltip>
        }
        ]}
        data={data}
    />
    )
}

export default EvaluationsListView