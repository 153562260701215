import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Typography, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Clear } from '@material-ui/icons';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    divider: {
        margin: '0rem 0.5rem'
    }
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h3" color='secondary'>{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Clear />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

const CustomDialog = withStyles(styles)(({ title, children, open, setOpen, callback, maxWidth = "md", fullWidth = true, form='' , ...rest}) => {
    const handleClose = () => {
        setOpen(false);
    }

    return (
      <Dialog open={open} maxWidth={maxWidth} fullWidth={fullWidth}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}> { title } </DialogTitle>
        <Divider className={rest.classes.divider}/>
        <DialogContent> { children } </DialogContent>
        <Divider className={rest.classes.divider}/>
        <DialogActions >
          <Button variant='outlined' color='default' onClick={handleClose}>
            Cancelar
          </Button>
          {/* TODO: BOTON SE TIENE QUE DESHABILITAR CUANDO SE PRESIONE */}
          <Button type='submit' form={form} variant='outlined' color='primary'>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    )
})  

export default CustomDialog;