import axios from "axios";
import { toast } from "react-toastify";
import api from "../../../../helpers/jwt/apiFetch";
import FormDataRecursive from "../../../../utils/FormDataRecursive";

const showPDF = async (postulationId) => {
    await axios(`${process.env.REACT_APP_API_URL}/pdf`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            'Accept': 'application/json',
        },
        data: {
            postulationId
        },
        responseType: 'blob' //Force to receive data in a Blob Format
    })
    .then(response => {
    //Create a Blob from the PDF Stream
        const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
    //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
        window.open(fileURL);
    })
    .catch(error => {
        console.log(error);
        toast('Ups, ocurrió al obtener las respuestas de la postulación', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'error'
        });
    });
}

const modifyAdmissibility = async (postulationId, state, setState) => {
    const response = await api(`/postulations/update_admissibility?form_user_id=${ postulationId }`, 'PUT');
    if(response.ok){
        const postulations = state.postulations.map(p => {
            if(response.data.form_user_id == p.id){
                return { ...p, es_admisible: response.data.admissibility };
            }
            return p;
        });

        setState({...state, postulations});

        toast('Admisibilidad modificada exitosamente.', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        return;
    }
    toast('Ups, ocurrió al modificar la admisibilidad de la postulación.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const update = async ({data, postulationId, state, setState}) => {
    let formData = new FormDataRecursive();
    formData.appendRecursive(data);
    const response = await api(`/postulations/update/${ postulationId }?_methods=PUT`, 'POST', formData);
    if(response.ok){
        const postulations = state.postulations.map(p => {
            if(postulationId == p.id){
                return { ...p, ...data };
            }
            return p;
        });

        setState({...state, postulations});

        toast('Postulación modificada exitosamente.', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        return;
    }
    toast('Ups, ocurrió al modificar la postulación.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const getAttachments = async (data) => {
    const response = await api(`/attachments/${ data.id }`, 'GET');
    if(response.ok) {
        for (let i = 0; i < response.data.length; i++) {
            let rp = await fetch(`${process.env.REACT_APP_API_URL}/download_attachment/${ response.data[i].id }`,{
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                }
            });
            
            if(rp.status != 200) {
                toast('Ocurrió un error al intentar descargar los archivos de postulación.', {
                    position: toast.POSITION.TOP_RIGHT,
                    type: 'error'
                });
                return;
            }

            const blob = await rp.blob();
            const urlBlob = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = urlBlob;
            a.download = `postulacion_${data.id}_archivo_${i+1}.${ response.data[i].extension }`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(urlBlob);
        }
    }

}

const downloadImages = async (data) => {
    let response = await fetch(`${process.env.REACT_APP_API_URL}/download_files_postulation?form_user_id=${ data.id }`,{
        method: 'GET',
        headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        }
    });
    if(response.status == 200){
        const blob = await response.blob();
        const urlBlob = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = urlBlob;
        a.download = `IMAGENES_POSTULACION_${data.id}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(urlBlob);
        return;
    }

    toast('No se logró descargar las imágenes de esta postulación.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { showPDF, modifyAdmissibility, downloadImages, update, getAttachments }