import React from 'react';
import MaterialTable from 'material-table';
import { materialTableProps } from '../../../common/materialTable';
import { Button, Tooltip } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { generateExcelEvaluations } from './Evaluation';

const EvaluationListView = ({ data, setLoading }) => {

  const handleClickGenerateExcelEvaluations = async (evaluation) => {
    setLoading(true);
    await generateExcelEvaluations(evaluation.jury_id, evaluation.form_user_id);
    setLoading(false);
  }

  return (
    <MaterialTable
      {...materialTableProps}
      options={{
        filtering: false,
      }}
      columns={[
        {
          title: '# Evaluación',
          field: 'id',
        },
        {
          title: 'Jurado',
          field: 'jury.user.full_name',
        },
        {
          title: 'Contacto',
          field: 'jury.user.correo_electronico',
        },
        {
          title: 'Estado',
          field: 'state.nombre',
        },
        {
          title: 'Acciones',
          field: 'acciones',
          filtering: false,
          render: (rowData) =>
          <Tooltip title='Descargar evaluaciones'>
            <Button color='secondary' variant='outlined' onClick={() => handleClickGenerateExcelEvaluations(rowData)}> <GetApp /> </Button>
          </Tooltip>
        },
      ]}
      data={data}
    />
  )
}

export default EvaluationListView