import { toast } from "react-toastify";
import api from "../../../helpers/jwt/apiFetch";

const getUsers = async (setState, ) => {
  const response = await api("/users", "GET");
  if (response.ok) {
    setState({
      data: response.data,
      actives: response.data.filter((user) => user.es_habilitado == 1).length,
    });
    return;
  }
  toast("Ups, ocurrió al obtener el listado de usuarios", {
    position: toast.POSITION.TOP_RIGHT,
    type: "error",
  });
};

const notifyUsers = async () => {
  const response = await api("/send_alert_email", "GET");
  if (response.ok) {
    toast(response.mensaje, {
      position: toast.POSITION.TOP_RIGHT,
      type: "success",
    });
    return;
  }
  toast("Ups, ocurrió al notificar a los usuarios.", {
    position: toast.POSITION.TOP_RIGHT,
    type: "error",
  });
};

export { getUsers, notifyUsers };
