import { toast } from "react-toastify";
import api from "../../../../helpers/jwt/apiFetch";

const generateExcelEvaluations = async(path, filename = 'evaluaciones', setLoading) => {
    setLoading(true);
    let response = await fetch(`${process.env.REACT_APP_API_URL}/${ path }`,{
        method: 'GET',
        headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        }
    });
    setLoading(false);
    if(response.status == 200){
        const blob = await response.blob();
        const urlBlob = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = urlBlob;
        a.download = `${filename}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(urlBlob);
        return;
    }

    toast('Ocurrió un problema al intentar generar el excel.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const getAllRegions = async (setRegions) => {
    const response = await api('/get_regions', 'GET');
      if(response.ok){
        setRegions(response.data);
        return;
      }
      toast('Ups, ocurrió al obtener las regiones', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
      });
}

export { generateExcelEvaluations, getAllRegions }