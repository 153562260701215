import React, { useState } from 'react';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { finishPostulation } from '../Postulation';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    borderRadius: 28,
    boxShadow: '0px 3px 6px #00000029',
    minHeight: '40vh',
    padding: '0.5rem 1rem',

  },
});

const FinishView = ({ postulationId }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);


  const handleClickFinish = async () => {
    await finishPostulation(postulationId, setIsLoading, history);
  }

  return (
    <Grid container justify="center" alignItems='center' className={classes.root}>
      <Grid item xs={12} sm={10}>
        <Typography variant='h6' color='textSecondary' align='center' gutterBottom>
          Has respondido todas las preguntas obligatorias, por lo cual ya puedes finalizar la postulación. Recuerda que una vez finalizada la postulación, no puedes volver a modificar tus respuestas. 
        </Typography>
        <Button color='secondary' variant='contained' onClick={handleClickFinish} disabled={isLoading} fullWidth> 
          { isLoading ? 'FINALIZANDO POSTULACIÓN ...' : 'FINALIZAR POSTULACIÓN'}
      </Button>
      </Grid>
    </Grid>
  )
}

export default FinishView
