import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';

const EmptyView = ({ message='', setOpenDialog }) => {
  return (
    <Grid container alignItems='center' style={{ height: '50vh' }}>
      <Grid item xs={12}>
        <Typography align='center'> { message } </Typography>
        <center>
          <Button variant='contained' color='primary' onClick={()=> setOpenDialog(true)}> 
            <AddCircle style={{ color: '#FFF', marginRight: '0.5rem' }}/> Agregar
          </Button>
        </center>
      </Grid>
    </Grid>
  )
}

export default EmptyView
