import React from 'react'
import { useFormContext } from "react-hook-form";
import { Controller } from "react-hook-form";
import { TextField } from '@material-ui/core';

const Text = ({nombre, etiqueta, errorTexto, type="text", separacion=22, defaultValue="", rules, placeholder="", disabled=false, multiline=false, ...rest}) => {
  const { control } = useFormContext();
  let inputProps = {};
  if(etiqueta === undefined){
    const partes = nombre.split('_')
    for (let i=0; i<partes.length; i++) {
      partes[i] = partes[i].charAt(0).toUpperCase() + partes[i].slice(1);
    }
    etiqueta = partes.join(' ');
  }
  if(errorTexto === undefined){
    errorTexto = `El campo ${etiqueta} es requerido`
  }
  if(type == 'number'){
    inputProps = {
      min: 0
    }
  }
  return (
    <Controller
      name={nombre}
      control={control}
      rules={rules ? { ...rules } : { required: errorTexto }}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <TextField
          disabled={disabled}
          error={!!error}
          fullWidth
          helperText={error ? error.message : null}
          InputProps={{inputProps}}
          label={etiqueta}
          onChange={onChange}
          placeholder={placeholder}
          style={{ marginBottom: error ? 0 : separacion }}
          type={type}
          value={value}
          variant="outlined"
          multiline={multiline}
          rows={10}
          {...rest}
        />
      )}
    />
  )
}

export default Text
