import React from "react";
import { Route, Redirect } from "react-router-dom";

import ResponsiveDrawer from "../layout/ResponsiveDrawer";
import { session, getUsuario } from "../../helpers/jwt/JWTAuth";

const PrivateRoute = ({ component: Component, role, ...rest }) => {
  const isLogged = session();

  // Si no esta logeado, redirige al login
  if (!isLogged) {
    return <Redirect to={{ pathname: "/login" }} />;
  }

  if(window.location.pathname == "/logout") {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    return <Redirect to={{ pathname: "/login" }} />
  }

  const user = getUsuario();
  const role_id = user.roles[0].id;
  const isAdmin = user && user.roles.some((rol) => rol.id === 1);
  const isNormal = user && user.roles.some((rol) => [2, 4].includes(rol));
  const isJury = user && user.roles.some((rol) => rol.id === 3);

   // si usuario tiene rol empresa y no esta habilitado
   if (isLogged && role_id == 2 && !user.es_habilitado) {
    if(user.should_update && window.location.pathname !== "/registro-empresa") {
      return <Route {...rest} render={(props) => <Redirect to={{ pathname: "/registro-empresa", state: { from: props.location } }} />} />;
    } else {
      if (user.company == null && window.location.pathname !== "/registro-empresa") {
        return <Route {...rest} render={(props) => <Redirect to={{ pathname: "/registro-empresa", state: { from: props.location } }} />} />;
      } else {
        if (window.location.pathname !== "/registro-empresa" && window.location.pathname !== "/no_habilitado") {
          return <Route {...rest} render={(props) => <Redirect to={{ pathname: "/no_habilitado", state: { from: props.location } }} />} />;
        }
      }
    }
  }

  // si usuario tiene rol jurado y no esta habilitado
  if (isLogged && role_id == 3 && !user.es_habilitado) {
    if (window.location.pathname !== "/jurado_no_habilitado") {
      return <Route {...rest} render={(props) => <Redirect to={{ pathname: "/jurado_no_habilitado", state: { from: props.location } }} />} />;
    }
  }
  // si usuario tiene rol guia y no esta habilitado
  if (isLogged && role_id == 4 && !user.es_habilitado) {
    if (user.guide == null && window.location.pathname !== "/guia/registro") {
      return <Route {...rest} render={(props) => <Redirect to={{ pathname: "/guia/registro", state: { from: props.location } }} />} />;
    } else {
      if (window.location.pathname !== "/guia/registro" && window.location.pathname !== "/guia/no-habilitado") {
        return <Route {...rest} render={(props) => <Redirect to={{ pathname: "/guia/no-habilitado", state: { from: props.location } }} />} />;
      }
    }
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? (
          <React.Fragment>
            <ResponsiveDrawer role_id={role_id} isAdmin={isAdmin} isNormal={isNormal} isJury={isJury} component={<Component role_id={role_id} {...props} />} />
          </React.Fragment>
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
