import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from '../../../../../contexts/formContext';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import Text from '../../../../common/Text';
import CustomSelect from '../../../../common/Select';
import { createStandard, editStandard } from './Standard';

const StandardModalView = ({ setOpenDialog }) => {
    const [standard_types] = useState([
        {valor: 1, texto: 'Cualitativo'},
        {valor: 2, texto: 'Cuantitativo/Interno'},
    ]);
    const formContext = useContext(FormContext);
    const { state } = formContext;
    const methods = useForm({
       defaultValues: {
            titulo: '',
            descripcion: '',
            standard_type_id: 0,
       }
    });

    const { handleSubmit, setValue } = methods;

    const onSubmit = async (data) => {
       if(Object.entries(state.standard).length > 0) {
            await editStandard(data, setOpenDialog, formContext);
        } else {
            await createStandard(data, setOpenDialog, formContext);
        }
    }
    
    useEffect(() => {
        if(Object.entries(state.standard).length > 0) {
            setValue('titulo', state.standard.titulo);
            setValue('descripcion', state.standard.descripcion);
            setValue('standard_type_id', state.standard.standard_type_id);
        }
    }, [])
    return (
        <FormProvider {...methods}>
        <form id='standard-form' onSubmit={handleSubmit(onSubmit)} style={{margin: '1.5rem 0'}}> 
            <Grid item xs={12}>
                <Text nombre="titulo" etiqueta="Título" />
            </Grid>
            <Grid item xs={12}>
                <Text nombre="descripcion" etiqueta="Descripción" />
            </Grid> 
            <Grid item xs={12}>
                <CustomSelect nombre="standard_type_id" etiqueta="Tipo" opciones={standard_types} required/>
            </Grid>
        </form>
        </FormProvider>
    )
}

export default StandardModalView