import React, { useState, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Grid, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Info, NavigateBefore, NavigateNext, Warning } from '@material-ui/icons';
import { EvaluationContext } from '../../../../contexts/evaluationContext';
import { evaluate, toNextQuestion, toPrevQuestion, updateEvaluation } from './Postulation';
import RadioAlternatives from '../../../common/RadioGroup';
import AnswerView from './AnswerView';

const useStyles = makeStyles({
  root: {
    borderRadius: 28,
    boxShadow: '0px 3px 6px #00000029',
    minHeight: '15vh',
    marginBottom: '2rem'
  },
  header: {
    background: 'linear-gradient(90deg, rgba(144,191,40,1) 0%, rgba(174,204,51,1) 100%)',
    borderTopLeftRadius: 28,
    borderTopRightRadius: 28,
    minHeight: '5vh',
    padding: '0.5rem 1rem',
  },
  item: {
    margin: '1rem 0',
    padding: '0 3rem',
    // minHeight: '20vh'  
  },
});

const QuestionView = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { state: { question, questions }, dispatch } = useContext(EvaluationContext);

  const [evaluationTypes] = useState([
  	{id: 0, texto: 'Inexistente (0 puntos)'},
  	{id: 1, texto: 'Deficiente (1 punto)'},
  	{id: 2, texto: 'Regular (2 puntos)'},
  	{id: 3, texto: 'Bueno (3 puntos)'},
    {id: 4, texto: 'Excelente (4 puntos)'},
  ])
  	
  const methods = useForm({
    defaultValues: {
      evaluation: ''
    }
  });

  const onSubmit = async (data) => {
    if(question.answers[0].evaluations.length > 0){
      await updateEvaluation({question, data, dispatch, setLoading});
    } else {
      await evaluate({question, data, dispatch, setLoading});
    }
  }

  useEffect(() => {
    let isMounted = true;
    if(isMounted){
      if(question.answers.length > 0 && question.answers[0].evaluations.length > 0) {
        const { evaluations } = question.answers[0];
        methods.reset({evaluation: evaluations[0].puntaje.toString()});
        return;
      }
      methods.reset({evaluation: ''});
    }
    return () => { isMounted = false };
  }, [ question ]);
  
  return (
    <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Grid container justify="flex-start" className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          {/* TITULO PREGUNTA */}
          <Grid container alignItems="center">
          <Typography variant='subtitle1' style={{ color: "#FFFFFF"}}> { question.orden}) { question.titulo } </Typography>
          </Grid>
        </Grid>
        <Grid container direction='column' justify='space-between' alignItems='center' className={classes.body}>
          <Grid container className={classes.item}>
            <AnswerView question={question}/>
          </Grid>

          <Grid container className={classes.item}>
            {
              question.standard && question.standard.standard_type_id == 1 ?
              <>
              <Grid item xs={12}>
                <Typography> Evalúe criterio: </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography> { question.standard.titulo } </Typography>
              </Grid>
              <Grid item xs={2}>
                <Tooltip title={ question.standard.descripcion } arrow>
                  <IconButton>
                    <Info color='secondary'/>
                  </IconButton>
                </Tooltip>
              </Grid>
                <RadioAlternatives nombre='evaluation' etiqueta='' size='l' alternatives={evaluationTypes} 
                  rules={{
                    required: 'Debe especificar una evaluación.'
                  }}
                /> 
              </>
              :
              <Grid item xs={12}>
                <Grid container justify='center'>
                <Warning color='error'/> 
                <Typography> Pregunta no evaluable. </Typography>
                </Grid>
              </Grid>
            }
          </Grid>

          {/* BOTONES */}
          <Grid container justify='space-between' spacing={1}>
            <Grid item xs={12} sm={3}>
              { question.orden > 1 && <Button 
                variant='contained' 
                color='secondary' 
                startIcon={<NavigateBefore/>}  
                style={{borderBottomLeftRadius: 28}} 
                onClick={() => toPrevQuestion(question.orden, dispatch)}
                fullWidth> Anterior </Button> 
              } 
            </Grid>
            <Grid item xs={12} sm={5}>
              {
                (question.standard && question.standard.standard_type_id == 1) && (
                  <Button type='submit' color='primary' variant='contained' fullWidth disabled={loading}>
                  {
                    loading
                    ? 'Guardando evaluación...'
                    : 'Guardar evaluación'
                  }
                  </Button>
                )
              }
            </Grid>

            <Grid item xs={12} sm={3}>
            { questions.length > question.orden && (
              question.standard && question.standard.standard_type_id == 1 ? 
              (
              <Button type='submit' variant='contained' color='secondary' endIcon={<NavigateNext/>} style={{borderBottomRightRadius: 28}} disabled={loading} fullWidth> 
                Siguiente 
              </Button> 
              )
              :
              (
              <Button variant='contained' color='secondary' endIcon={<NavigateNext/>} style={{borderBottomRightRadius: 28}} 
              disabled={loading} 
              onClick={(e) => toNextQuestion(question.orden, dispatch,e)}
              fullWidth> 
                Siguiente 
              </Button> 
              )
            )
            }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
    </FormProvider>
  )
}

export default QuestionView;