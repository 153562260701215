import React, { useState } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
import { makeStyles, Typography, Grid, Link } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';

import ButtonLoad from '../../../common/ButtonLoad';
import CustomSelect from '../../../common/Select';
import Text from '../../../common/Text';
import { useUser } from '../../../../contexts/userContext';
import { validateRut, validateCellPhone, validatePhone } from '../../../../helpers/validations';
import { registerUser } from './RegisterUser';

const useStyle = makeStyles({
  input: {
    padding: '0 3rem 0 3rem'
  },
});

const RegisterUserView = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userType = queryParams.get('usuario');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [genders] = useState([
    {valor: 1, texto: "Masculino"},
    {valor: 2, texto: "Femenino"},
    {valor: 3, texto: "Prefiero no decirlo"},
  ]);
  const { dispatch } = useUser();
  const classes = useStyle();
  const history = useHistory();
  const methods = useForm();
  const { handleSubmit, watch } = methods;

  const onSubmit = async (data) => {
    await registerUser(data, setIsSubmitting, history, dispatch, userType);
  }

  if(!userType || !['guia', 'viña'].includes(userType)) {
    history.push('/login');
  } 

  return <Grid container direction='column' alignItems='center' justify='center'spacing={3} style={{width: '100%',height: '100%'}}>
    <Grid item style={{ margin: '5rem 0 2rem 0'}}>
      <Typography variant='h1' color='primary' align='center'> Registro de usuario </Typography>
    </Grid>
    <FormProvider {...methods}>
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
      <Grid container justify='center' alignItems='center' spacing={1}>
        <Grid item xs={10} sm={7} className={ classes.input }> 
          <Text nombre='nombres' etiqueta='Nombres' />
        </Grid>
        <Grid item xs={10} sm={7} className={ classes.input }> 
          <Text nombre='apellido_p' etiqueta='Apellido Paterno' />
        </Grid>
        <Grid item xs={10} sm={7} className={ classes.input }> 
          <Text nombre='apellido_m' etiqueta='Apellido Materno' />
        </Grid>
        <Grid item xs={10} sm={7} className={ classes.input }>
          <Text nombre='rut_encargado' etiqueta='Rut' placeholder='12345678-9'
            rules={{
              required: 'El campo Rut encargado es requerido',
              validate: validateRut
            }}
          />
        </Grid>
        <Grid item xs={10} sm={7} className={ classes.input }>
          <CustomSelect nombre="gender_id" etiqueta="Género" opciones={genders} required/>
        </Grid>
        <Grid item xs={10} sm={7} className={ classes.input }>
          <Text nombre='telefono_encargado' etiqueta='Telefono' type='number' placeholder='2 XXXX XXXX' rules={{
              required: false,
              validate: validatePhone
            }}/>
        </Grid>
        <Grid item xs={10} sm={7} className={ classes.input }>
          <Text nombre='celular_encargado' etiqueta='Celular' type='number' placeholder='9 XXXX XXXX'  rules={{
              required: 'El campo Celular encargado es requerido',
              validate: validateCellPhone
            }}/>
        </Grid>
        { 
          userType == 'viña' && 
          <Grid item xs={10} sm={7} className={ classes.input }>
            <Text nombre='cargo_encargado' etiqueta='Cargo en empresa' placeholder='Jefe'/>
          </Grid>
        }
        <Grid item xs={10} sm={7} className={ classes.input }> 
          <Text nombre='correo_1' etiqueta='Correo' type='email'/>
        </Grid>
        <Grid item xs={10} sm={7} className={ classes.input }> 
          <Text nombre='correo_2' etiqueta='Confirme correo' type='email' 
            rules={{
              required: 'El campo Confirme correo es requerido',
              validate: (value) => value === watch('correo_1') || 'Ambos correos deben coincidir' 
            }}
          />
        </Grid>
        <Grid item xs={10} sm={7} className={ classes.input }> 
          <Text nombre='password_1' etiqueta='Contraseña' type='password' 
            rules={{
              required: 'El campo Contraseña es requerido',
              pattern: { message: "La contraseña debe tener mínimo 8 caracteres (al menos 1 mayúscula, 1 minúscula y 1 número).", value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/ },
              validate: (value) => value === watch('password_2') || 'Ambas contraseñas deben coincidir' 
            }}
          />
        </Grid>
        <Grid item xs={10} sm={7} className={ classes.input }> 
          <Text nombre='password_2' etiqueta='Confirme contraseña' type='password'  
            rules={{
              required: 'El campo Confirme contraseña es requerido',
              validate: (value) => value === watch('password_1') || 'Ambas contraseñas deben coincidir' 
            }}
          />
        </Grid>
        <Grid item xs={10} sm={7} style={{padding: '0 3rem 0 3rem',marginBottom: '2rem'}}> 
          <ButtonLoad texto='Registrarme' isSubmitting={isSubmitting} />
          <Typography variant="subtitle1" align='center' >
            <Link to="login" component={RouterLink}>Ya tengo una cuenta</Link>
          </Typography>
        </Grid>
      </Grid>
    </form> 
    </FormProvider>
  </Grid>
}

export default RegisterUserView;