import { toast } from "react-toastify";
import api from "../../../../helpers/jwt/apiFetch";

const getUserInfo = async (userId, setState) => {
    let response = await api(`/users/${userId}`, 'GET');

    if(response.ok){
        setState(response.data);
        return;
    }
    
    toast('Ups, ocurrió un error al intentar obtener información del usuario', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const download = async (companyId, setLoading) => {
    setLoading(true);
    let response = await fetch(`${process.env.REACT_APP_API_URL}/logos/companies/${ companyId }`,{
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        }
    });
    setLoading(false);
    if(response.status == 200){
        const blob = await response.blob();
        const extension = blob.type.split('/')[1];
        const urlBlob = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = urlBlob;
        a.download = `Logo_empresa_${companyId}.${extension}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(urlBlob);
        return;
    }

    toast('No se encontró el archivo.', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { getUserInfo, download };