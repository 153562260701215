import { Backdrop, Breadcrumbs, Button, CircularProgress, Grid, Link, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import Counter from '../../common/Counter';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import JurorsListView from './JuriesList/JuriesListView';
import { getJuries } from './Juries';
import { PersonAdd } from '@material-ui/icons';
import { handleClickBreadcumb } from '../../../helpers/utils';

const useStyles = makeStyles({
    dataTable: {
        marginTop: '2rem',
        boxShadow: '0px 3px 26px #00000017',
    },
});

const JuriesView = () => {
    const history = useHistory();
    const [state, setState] = useState({ actives: 0, data: [] });
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const handleClickButton = (event) => {
        event.preventDefault();
        history.push('/jurados/crear');
    }

    useEffect(() => {
        getJuries(setState);
    }, [ ]);
    
    return (
        <Grid container justify="center">
            <Grid item xs={12} sm={10}>
                <Grid container justify='flex-end'>
                    <Breadcrumbs aria-label="breadcrumb">
                    <Link color='inherit' href="/inicio" onClick={(e) => handleClickBreadcumb(e, '/', history)}> Inicio </Link>
                    <Typography color="textPrimary"> Mantenedor de jurados </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={10}>
                <Grid container justify="space-between">
                    <Typography variant="h1" color="primary" gutterBottom>
                        Mantenedor de Jurados
                    </Typography> 
                    <Button color="primary"variant="contained" startIcon={<PersonAdd/>} onClick={handleClickButton}>
                        Crear jurados
                    </Button> 
                </Grid>
            </Grid>
            <Grid item xs={12} sm={10}>
                <Counter title="Jurados activos" counter={state.actives}/>
            </Grid>
            <Grid item xs={12} sm={10} className={ classes.dataTable }>
                <JurorsListView state={ state } setState={ setState } setLoading={setLoading}/>
            </Grid>
            <Backdrop open={loading} style={{ zIndex: 999999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid> 
    )
}

export default JuriesView