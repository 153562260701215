import React, { useEffect, useState } from 'react'
import GreetingCard from '../../common/GreetingCard';
import { Backdrop, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import Counter from '../../common/Counter';
import Spinner from '../../layout/Spinner';
import { PostulationListView } from './PostulationList/PostulationListView';
import { getPostulations } from './HomeJury';
import { getUsuario } from '../../../helpers/jwt/JWTAuth';
import { GetApp } from '@material-ui/icons';

const HomeJuryView = () => {
    const user = getUsuario();
    const [state, setState] = useState({
        postulations: [], 
        loading: false
    });
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        setState({...state, loading: true});
        getPostulations(setState);
        setState({...state, loading: false});
    }, []);

    if(state.loading){
        return <Grid container justify='center'> 
            <Spinner size={60} />
        </Grid>
    }

    return (
        <Grid container justify="center" alignItems="center">
            <Grid item xs={12} sm={10}>
                <Typography variant="h1" color="primary" gutterBottom>
                    {`Premios Enoturismo ${new Date().getFullYear()}`}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
                <GreetingCard mesagge={`Plataforma de evaluación Premios Enoturismo Chile ${ new Date().getFullYear() }`}/>
            </Grid>
            <Grid item xs={12} sm={7}>
                <Typography variant="h2" color="secondary" gutterBottom>
                    { user.jury.es_nacional ? 'Jurado nacional' : user.jury.region.nombre }
                </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <a href="/assets/docs/instructivo-jurado.pdf" download="Instructivo.pdf" target="_blank" rel="noreferrer">
                    <Button color='secondary' variant='contained' startIcon={<GetApp/>} fullWidth> Descargar instructivo </Button>
                </a>
            </Grid>
            <Grid item xs={12} sm={10}>
                <Counter title='Postulaciones a evaluar' counter={state.postulations.length}/>
            </Grid>
            <Grid item xs={12} sm={10}>
                <PostulationListView data={state.postulations} setLoading={setLoading}/>
            </Grid>
            <Backdrop open={loading} style={{ zIndex: 999999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}

export default HomeJuryView