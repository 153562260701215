import React from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import Text from '../../../../common/Text';
import { Button, Grid, Typography } from '@material-ui/core';
import BigAlert from '../../../../layout/BigAlert';
import { finishEvaluation } from './Finish';

const FinishView = ({ setLoading, postulationId }) => {
    const history = useHistory();
    const methods = useForm({
        defaultValues: { commentary: '' }
    });

    const onSubmit = async (data) => {
        setLoading(true);
        await finishEvaluation(data, postulationId, history);
        setLoading(true);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <BigAlert severity='success' variant='standard'>
                    <Typography> ¡Ya has completado tu evaluación! Escribe en el siguiente recuadro si posees algun comentario u observación respecto a esta postulación. Para finalizar y continuar evaluando las siguientes postulaciones de tu región da clic en <b>Finalizar evaluación</b>.</Typography>
                </BigAlert>
            </Grid>
            <Grid item xs={12}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Grid container justify='flex-end'>
                            <Text type='text' nombre='commentary' etiqueta='Comentario (Máx. 500 carácteres)' multiline placeholder='Ingrese un comentario sobre la postulación aquí.' 
                                rules={{
                                    required: 'Se requiere un comentario.',
                                    validate: value => (value.trim().length >= 5 && 500 >= value.trim().length) || 'Debe ingresar un comentario que tenga más de 5 carácteres y menos de 500.'
                                }}
                            />
                            <Button type='submit' variant='contained' color='secondary'> Finalizar evaluación </Button>
                        </Grid>
                    </form>
                </FormProvider>
            </Grid>
        </Grid>
    )
}

export default FinishView