import React from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";

// Toast notifications
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Theme config
import theme from "./theme/app-theme";

// Context
import { FormProvider } from "./contexts/formContext";
import { UserProvider } from "./contexts/userContext";

// Route types
import PrivateRoutes from "./components/router/Private";
import PublicRoutes from "./components/router/Public";

// Components
import BlockedView from "./components/views/Blocked/BlockedView";
import EditFormView from "./components/views/Forms/EditForm/EditFormView";
import FormsView from "./components/views/Forms/FormsView";
import Home from "./components/pages/login/Home";
import Index from "./components/views/HomeUser/Postulation/Index";
import Login from "./components/views/Login/LoginView";
import RegisterCompanyView from "./components/views/Register/Company/RegisterCompanyView";
import RegisterUserView from "./components/views/Register/User/RegisterUserView";
import RegisterGuideView from "./components/views/Register/Guide/RegisterGuideView";
import RequestResetPasswordView from "./components/views/RequestResetPassword/RequestResetPasswordView";
import ResetPasswordView from "./components/views/ResetPassword/ResetPasswordView";
import UserInfoView from "./components/views/Users/UserInfo/UserInfoView";
import UsersView from "./components/views/Users/UsersView";
import JuriesView from "./components/views/Juries/JuriesView";
import NewJuriesView from "./components/views/Juries/NewJuries/NewJuriesView";
import EvaluationWrapper from "./components/views/HomeJury/Postulation/Index";
import EvaluationsView from "./components/views/Evaluations/EvaluationsView";
import EvaluationEditView from "./components/views/HomeJury/EvaluationEdit/EvaluationEditView";
import ReportsView from "./components/views/Reports/ReportsView";
import EvaluationsEditView from "./components/views/HomeAdmin/EvaluationsEdit/EvaluationsEditView";

export default function App() {
  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <Router>
          <Switch>
            <Redirect exact from="/" to="inicio" />
            {/* Public routes */}
            <PublicRoutes path="/login" component={Login} />
            <PublicRoutes path="/solicitar_cambio_contraseña" component={RequestResetPasswordView} />
            <PublicRoutes path="/cambio_contraseña/:token" component={ResetPasswordView} />
            <PublicRoutes path="/registro" component={RegisterUserView} />

            <PrivateRoutes path="/inicio" component={Home} />

            {/* Admin routes */}
            <PrivateRoutes path="/jurados/listado" component={JuriesView} role={1} />
            <PrivateRoutes path="/jurados/crear" component={NewJuriesView} role={1} />
            <PrivateRoutes path="/usuarios/listado" component={UsersView} role={1} />
            <PrivateRoutes path="/usuarios/ver/:id" component={UserInfoView} role={1} />
            <PrivateRoutes path="/guias/listado" component={UsersView} role={1} />
            <PrivateRoutes path="/formularios/listado" component={FormsView} role={1} />
            <PrivateRoutes path="/evaluaciones/listado/:form_user_id" component={EvaluationsView} role={1} />
            <PrivateRoutes path="/reportes" component={ReportsView} role={1} />
            <PrivateRoutes path="/evaluacion/rectificar/:form_user_id" component={EvaluationsEditView} role={1} />

            {/* Company routes */}
            <PrivateRoutes path="/registro-empresa" component={RegisterCompanyView} role={2} />
            <PrivateRoutes path="/no_habilitado" component={BlockedView} role={2} />
            <PrivateRoutes path="/postulacion/:id" component={Index} role={2} />

            {/* Jury routes */}
            <PrivateRoutes path="/evaluacion/postulacion/:id" component={EvaluationWrapper} role={3} />
            <PrivateRoutes path="/evaluacion/editar/:id" component={EvaluationEditView} role={3} />
            <PrivateRoutes path="/jurado_no_habilitado" component={BlockedView} role={3} />

            {/* Guide routes */}
            <PrivateRoutes path="/guia/registro" component={RegisterGuideView} role={4} />
            <PrivateRoutes path="/guia/no-habilitado" component={BlockedView} role={4} />

            {/* si no se pone al ultimo, no renderiza ningun componente*/}
            <FormProvider>
              <PrivateRoutes path="/formularios/editar/:id" component={EditFormView} role={1} />
            </FormProvider>
            <Route component={NoMatch} />
          </Switch>
          <Link to="#" className="btn btn-icon btn-circle btn-success btn-scroll-to-top fade" data-click="scroll-top">
            <i className="fa fa-angle-up"></i>
          </Link>
        </Router>
      </ThemeProvider>
    </UserProvider>
  );
}

function NoMatch({ location }) {
  return (
    <div>
      <h3>
        La url <code>{location.pathname}</code> no se encuentra disponible. <Link to="/">Volver al inicio</Link>
      </h3>
    </div>
  );
}
