import { Breadcrumbs, Button, Grid, Link, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { PostulationContext } from '../../../../contexts/postulationContext';
import { tamaños } from '../../../../helpers/sizes';
import BigAlert from '../../../layout/BigAlert';
import Spinner from '../../../layout/Spinner';
import { finishPostulation, getPostulation } from './Postulation';
import QuestionView from './Question/QuestionView';
import QuestionSelectView from './QuestionSelect/QuestionSelectView';
import FinishView from './Finish/FinishView';

const PostulationView = () => {
    const { id } = useParams();
    const history = useHistory();
    const { state: { data, isComplete, isCompleteAux }, dispatch } = useContext(PostulationContext);
    const [showFinish, setShowFinish] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      getPostulation(id, dispatch, history, setShowFinish);
    }, [])
    
    if(Object.entries(data).length == 0){
        return <Grid container justify='center'> 
            <Spinner size={60} />
        </Grid>
    }

    const handleClick = (event) => {
        event.preventDefault();
        history.push(event.target.getAttribute('href'))
    }

    const handleClickFinish = async () => {
        await finishPostulation(id, setIsLoading, history);
    }

    return (    
        <Grid container justify="center" alignItems="center">
            <Grid item {...tamaños.l}>
                <Grid container justify='flex-end'>
                    <Breadcrumbs aria-label="breadcrumb">
                    <Link color='inherit' href="/inicio" onClick={handleClick}> Inicio </Link>
                    <Typography color="textPrimary"> Postulación </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item {...tamaños.l} style={{marginBottom: '1rem'}}>
                <BigAlert>
                    <p> Antes de comenzar a responder las preguntas, debes tener en consideración lo siguiente:</p>
                    <ol>
                        <li>Para almacenar cada respuesta, debes hacer click en el botón <b>Guardar respuesta</b>.</li>
                        <li>Podrás navegar entre preguntas ya sea haciendo click en los botones <b>{'< Anterior'}</b> o <b>{'Siguiente >'}</b>, y también desde el menú desplegable <b>Preguntas</b>. </li>
                        <li>Si te equivocas en alguna respuesta, puedes volver a esta y modificarla sin problemas.</li>
                        <li>Una vez respondas todas las preguntas que son de carácter obligatorio, se desplegará un botón que te permitirá finalizar tu postulación.</li>
                    </ol>
                </BigAlert>
            </Grid>
            <Grid item {...tamaños.l}>
                <Typography variant="h1" color="primary" gutterBottom>
                    { data.form.nombre } - { data.form.anio } 
                </Typography>
            </Grid>

            <Grid item {...tamaños.l} style={{ marginTop: '3rem' }}>
                <Grid container justify='center'>
                    {
                        isComplete
                        ? <FinishView postulationId={id}/>
                        :  <>
                            { (showFinish || isCompleteAux) && 
                            <Grid item xs={12} sm={6}>
                                <Button color='secondary' variant='contained' onClick={handleClickFinish} disabled={isLoading} fullWidth> 
                                    { isLoading ? 'FINALIZANDO POSTULACIÓN ...' : 'FINALIZAR POSTULACIÓN'}
                                </Button>
                            </Grid>
                            }
                            <Grid item xs={12} sm={9} style={{marginTop: '2rem'}}> <QuestionView /> </Grid> 
                        </>
                    }
                </Grid>
                
                <Grid container justify='center' style={{ marginTop: '1rem' }}>
                    <Grid item xs={8}>
                    <QuestionSelectView />
                    </Grid>
                </Grid> 
            </Grid>
        </Grid>
    )
}

export default PostulationView