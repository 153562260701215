import React, { createContext, useReducer } from 'react';

const EvaluationContext = createContext();

const evaluationReducer = (state, action) => {
    const { type, payload = {} } = action;

    switch (type) {
        case 'SET_POSTULATION':
            return { 
                ...state, 
                ...payload,
            };
        case 'UPDATE_QUESTION':
            return { ...state, question: state.questions[payload - 1] };
        case 'UPDATE_EVALUATION':
            let question = {};
            let isComplete = false;
            const questionsUpdated = state.questions.map(q => {
                if(q.id == payload.id){ 
                    const questionsNotEvaluated = state.questions.filter(q => (q.id != payload.id) && (q.standard !== null) && (q.standard.standard_type_id == 1) && q.answers[0].evaluations.length == 0)
                    if(questionsNotEvaluated == 0){
                        isComplete = true;
                    }

                    if(payload.orden == state.questions.length){
                        question = payload;
                    } else {
                        question = state.questions[payload.orden];
                    }
                    
                    return payload; 
                }
                return q;
            });
            return { ...state, questions: questionsUpdated, question, isComplete };
        default:    
            return state;
    }
}

const EvaluationProvider = ({ children }) => {
    const initialState = { 
        form: '',
        company: '',
        questions: [],
        standard: {},
        question: {},
        isComplete: false
    };

    const [state, dispatch] = useReducer(evaluationReducer, initialState); 

    return (
        <EvaluationContext.Provider value={{ state, dispatch }}>
            { children }
        </EvaluationContext.Provider>
    )
}

export {EvaluationProvider, EvaluationContext};