import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { useFormContext } from "react-hook-form";
import Typography from '@material-ui/core/Typography'
import { AttachFile, InsertDriveFile } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import { validateTypeFile, validateSizeFile } from '../../helpers/validations';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px dashed #576271',
    minHeight: '150px',
  },
  input: {
    display: 'none',
  },
  label: {
    color: '#576271',
    fontSize: 18,
    fontFamily: 'Poppins',
    // margin: 'auto',
    display: 'block'
  },
  fileContainer: {
    border: '1px solid #e3e3e3',
    borderRadius: '0.5rem',
    margin: '0.5rem 1rem',
    padding: '0.2rem 0.4rem'
  },
  file: {
    textOverflow: 'ellipsis',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    width: 'calc(80%)',
  }
}));

const FileAttached = ({file}) => {
  const classes = useStyles();
  return <div className={ classes.fileContainer }>
    <InsertDriveFile color='secondary'/>
    <span className={ classes.file } > { file.name } </span>
  </div>
}

export default function FileInput({ nombre, defaultFiles=[], multiple=false, disabled=false, cantidadNecesariaArchivos=1, required=true, fileTypesAllowed=[{tipo: 'png', mime: 'image/png'},{tipo: 'jpg, jpeg', mime: 'image/jpeg'}]}) {
  const classes = useStyles();
  const { register, watch, formState: { errors } } = useFormContext();
  const datos = watch(nombre);
  const [files, setFiles] = useState([]);
  useEffect(() => {
    setFiles(Array.from(datos).map((item, index) => ({ id: index, name: item.name})));
  }, [datos])

  return (
    <Grid container justify='center' alignContent='center' className={classes.root}>
      <Grid item xs={12}>
        <center>
          <input className={classes.input} id="icon-button-file" type="file" {...register(nombre, {
            required: required && 'Archivo adjunto requerido', 
            validate: {
              size: (v) => validateSizeFile(v, cantidadNecesariaArchivos, required) || `Ha superado el tamaño máximo de 10MB o no ha subido la cantidad necesaria de ${cantidadNecesariaArchivos} archivos`,
              type: (v) => validateTypeFile(v, fileTypesAllowed)  || 'Se admiten solo archivos de tipo ' + fileTypesAllowed.map(item => item.tipo).join(', '),
            }
            })}
            multiple={multiple}
            disabled={disabled}
          />
          <label htmlFor="icon-button-file" className={classes.label}>
            <IconButton color="primary" aria-label="upload picture" component="span" disabled={disabled}>
              <AttachFile />
            </IconButton>
            { `${ datos ? datos.length : 0 } Archivo(s) seleccionados.` }
            
            { errors[nombre] && <Typography variant="caption" display="block" style={{color: 'red'}}> {errors[nombre]?.message} </Typography> }
          </label>
        </center>
      </Grid>
      <Grid item xs={10}>
        { 
          defaultFiles.length > 0 && files.length == 0 && defaultFiles.map((file, index) => ( <FileAttached key={`file${index}`} file={file}/>))
        }
        {files.length > 0 && files.map((file, index) => (
          <FileAttached key={`file${index}`} file={file}/>
        ))}
      </Grid>
    </Grid>
  );
}