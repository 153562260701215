import React from 'react';
import PostulationView from './PostulationView';
import { EvaluationProvider } from '../../../../contexts/evaluationContext';

const EvaluationWrapper = () => {
  return <EvaluationProvider>
    <PostulationView />
  </EvaluationProvider>
}

export default EvaluationWrapper