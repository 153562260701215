class FormDataRecursive extends FormData {
    appendRecursive(data, wrapper) {  
        for(var x in data) {
            //se agrega el data[x] != undefined, verificar que no afecte a otros formularios
            if(typeof data[x] === 'undefined'){
                continue;
            }
            if(typeof data[x] == 'object' || data[x].constructor === Array) {
                if(typeof data[x] === 'object' && !(data[x] instanceof File)) {

                    if(wrapper){
                        this.appendRecursive(data[x], wrapper + '[' + x + ']' );
                    }else{
                        this.appendRecursive(data[x], x);
                    }
                    
                } else {
                    // if it's a string or a File object
                    if(wrapper){
                        this.append(`${wrapper}[${x}]`, data[x]);
                    }else{
                        this.append(`anexos[${x}]`, data[x]);
                    }
                }
                
            } else {
                if(wrapper){
                    this.append(wrapper + '[' + x + ']', data[x]);
                }else{
                    this.append(x, data[x]);
                }
            }
            // if(data[x].constructor.name === 'Date') {
            //     if(wrapper){
            //         this.append(wrapper + '[' + x + ']', data[x].toISOString());
            //     }else{
            //         this.append(x, data[x].toISOString());
            //     }
            // }
        }
    }
}

export default FormDataRecursive;