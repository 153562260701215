import { toast } from "react-toastify";

import api from "../../../../helpers/jwt/apiFetch";
import FormDataRecursive from "../../../../utils/FormDataRecursive";

const createForm = async (data, setIsSubmitting, history) => {
    
    setIsSubmitting(true);
    let formData = new FormDataRecursive();
    formData.appendRecursive(data);

    let response = await api('/forms/create', 'POST', formData);
    setIsSubmitting(false);

    if(response.ok){
        toast('Formulario creado exitosamente', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        history.push(`/formularios/editar/${response.data.id}`);
    } else {
        toast('Ups, ocurrió un error al intentar crear un formulario', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'error'
        });
    }
    
}

export { createForm }