import { toast } from "react-toastify";
import api from "../../../helpers/jwt/apiFetch";

const getActiveForms = async (setActiveForms) => {
    const response = await api(`/forms_by_year?year=${ new Date().getFullYear() }`, 'GET');
    if(response.ok){
        const forms = response.data.map(i => ({'value': i.id, 'text': i.nombre}));
        setActiveForms(forms);
        return;
    }
    toast('Ups, ocurrió al obtener las regiones', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { getActiveForms }