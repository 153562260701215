import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';

import Text from '../../../common/Text';
import CustomSelect from '../../../common/Select';
import ButtonLoad from '../../../common/ButtonLoad';
import Checkboxes from '../../../common/MultipleCheckbox';
import FileInput from '../../../common/FileInput';
import { tamaños } from '../../../../helpers/sizes';
import Popup from '../../../layout/Popup';
import { validateCellPhone, validatePhone, validateRut } from '../../../../helpers/validations';
import { getUsuario } from '../../../../helpers/jwt/JWTAuth';
import { getCommunes, getRegions, searchCompany } from './RegisterCompany';
import { registerCompany } from '../Company/RegisterCompany';
import Spinner from '../../../layout/Spinner';
import BigAlert from '../../../layout/BigAlert';

const RegisterCompanyView = () => {
  const user = getUsuario();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [communes, setCommunes] = useState([]);
  const [regions, setRegions] = useState([]);

  const [companyTypes] = useState([
    {id: 1, texto: "Viñas abiertas al turismo"},
    {id: 2, texto: "Agencia de viajes"},
    {id: 3, texto: "Alojamiento turístico"},
    {id: 4, texto: "Restaurantes y similares"},
    {id: 5, texto: "Tour operador / Agencia de viajes"},
    {id: 6, texto: "Otros turismos"},
  ]);

  const [targetMarkets] = useState([
    {id: 1, texto: "Local"},
    {id: 2, texto: "Nacional"},
    {id: 3, texto: "Internacional"},
  ]);

  const [companySizes] = useState([
    {valor: 1, texto: "Micro empresa"},
    {valor: 2, texto: "Pequeña empresa"},
    {valor: 3, texto: "Mediana empresa"},
    {valor: 4, texto: "Gran empresa"}
  ])
  const methods = useForm({ 
    defaultValues: {
      rut: user.company ? user.company.rut_formated : '',
      nombre: user.company ? user.company.nombre : '',
      telefono: user.company ? user.company.telefono || '' : '',
      celular: user.company ? user.company.celular : '',
      razon_social: user.company ? user.company.razon_social : '',
      giro: user.company ? user.company.giro : '',
      valle_vitivinicola: user.company ? user.company.valle_vitivinicola : '',
      region_id: 0,
      commune_id: 0,
      company_size_id: user.company ? user.company.company_size_id : 0,
      anio_inicio_actividades: user.company ? user.company.anio_inicio_actividades : '',
      anio_inicio_actividades_enoturisticas: user.company ? user.company.anio_inicio_actividades_enoturisticas : '',
      company_types_id: user.company ? companyTypes.map( ct => {
        if(user.company.types.map(a => a.id).includes(ct.id)){ 
          return { ...ct, checked: true };
        } 
        return { ...ct, checked: false };
      }) : companyTypes.map(i => ({ ...i, checked: false })),
      company_target_markets_id: user.company ? targetMarkets.map( tm => {
        if(user.company.target_markets.map(a => a.id).includes(tm.id)){ 
          return { ...tm, checked: true };
        } 
        return { ...tm, checked: false };
      }) : targetMarkets.map(i => ({ ...i, checked: false })),
      ruta_logo: ''
    }
  });

  const { handleSubmit, setValue } = methods;

  const onSubmit = async (data) => {
    registerCompany(data, setIsSubmitting, history, user);
  }

  useEffect(() => {
    getRegions(setRegions);
    getCommunes(setCommunes);
    if(user.company){ 
      setValue('region_id', user.company.region_id);
      setValue('commune_id', user.company.commune_id);
    }
  }, [ ]); 

  // if(user.company){
  //   // Si ya registro una empresa, no puede volver a hacerlo
  //   history.push('/');
  // }

  if(regions.length == 0 && communes.length == 0) {
    return <Grid container justify='center'> 
      <Spinner size={60} />
    </Grid>
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        { !user.should_update ?
          <Typography variant="h1" color="primary" align='center' gutterBottom> Registro de empresa </Typography>
          :
          <>
          <Typography variant="h1" color="primary" align='center' gutterBottom> Actualización de información </Typography>
          <BigAlert>
            <p> Hola, contamos con la siguiente información ingresada por ti en la postulación pasada, por favor revise que estos sean correctos, de lo contrario actualice la información necesaria. </p>
          </BigAlert>
          </>
        }
      </Grid>
      <Grid item xs={12} sm={8} style={{ marginTop: '1rem' }}>
      <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        { 
          !user.should_update && <Grid container justify='flex-end'>
            <Typography variant='subtitle2' color='secondary' style={{cursor: 'pointer'}} onClick={()=> setOpenModal(true)}> Buscar existencias por rut de empresa </Typography>  
            <SearchOutlined color='primary' style={{cursor: 'pointer'}} onClick={()=> setOpenModal(true)}/>
          </Grid>
        }
        <Grid container direction="row" spacing={1}>
          <Grid item {...tamaños.m}>
            <Text nombre='rut' etiqueta='Rut empresa' placeholder='12345678-9' rules={{
              required: 'El campo Rut es requerido',
              validate: validateRut
            }}/>
          </Grid>
          <Grid item {...tamaños.m}> <Text nombre='nombre' etiqueta='Nombre empresa'/> </Grid>
          <Grid item {...tamaños.s}>
            <Text nombre='telefono' etiqueta='Teléfono empresa' placeholder='2 XXXX XXXX' type='number' rules={{
              validate: validatePhone
            }}/>
          </Grid>
          <Grid item {...tamaños.s}>
            <Text nombre='celular' etiqueta='Celular empresa' placeholder='9 XXXX XXXX' type='number' rules={{
              required: 'El campo Celular es requerido',
              validate: validateCellPhone
            }}/>
          </Grid>
          <Grid item {...tamaños.m}> <Text nombre='razon_social' etiqueta='Razón social'/> </Grid>
          <Grid item {...tamaños.m}> <Text nombre='giro' etiqueta='Giro'/> </Grid>
          <Grid item {...tamaños.m}> <Text nombre='valle_vitivinicola' etiqueta='Valle vitivinícola'/> </Grid>
          <Grid item {...tamaños.m}>
            <CustomSelect
              nombre='region_id'
              etiqueta='Región'
              opciones={regions}
              // valorPorDefecto={getValues('region_id')}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              nombre='commune_id'
              etiqueta='Comuna'
              opciones={communes}
              // valorPorDefecto={getValues('commune_id')}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              nombre='company_size_id'
              etiqueta='Tamaño'
              opciones={companySizes}
              // valorPorDefecto={getValues('company_size_id')}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}> <Text nombre='anio_inicio_actividades' etiqueta='Año inicio actividades' type='number'/> </Grid>
          <Grid item xs={12} sm={6}> <Text nombre='anio_inicio_actividades_enoturisticas' etiqueta='Año inicio actividades enoturísticas' type='number'/> </Grid>
          <Grid item xs={12}> 
            <Typography variant='subtitle1'> Indique a qué tipos de empresa pertenece: </Typography>
            <Checkboxes nombre='company_types_id' alternatives={companyTypes} required/> 
          </Grid>
          <Grid item xs={12}> 
            <Typography variant='subtitle1'> Indique a qué tipos de mercado objetivo pertenece: </Typography>
            <Checkboxes nombre='company_target_markets_id' alternatives={targetMarkets} required/> 
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1'> Adjunte el logo de la empresa (Solo se permiten imágenes .jpg, jpeg, y .png): </Typography>
            <FileInput nombre='ruta_logo' multiple={false}/>
          </Grid> 
          <Grid item xs={12}>
            <ButtonLoad isSubmitting={isSubmitting} texto={ user.should_update ? 'Actualizar' : 'Registrarse' }/>
          </Grid>
        </Grid>
      
      <Popup openPopup={openModal} title="Busqueda de empresa por rut" maxWidth='sm'>
        <Text nombre='rut_2' etiqueta='Rut empresa' placeholder='12345678-9' rules={{
          required: 'El campo Rut es requerido',
          validate: validateRut
        }}/>
        <Button variant="outlined" color="primary" onClick={()=>searchCompany(methods, setOpenModal)} fullWidth>
          Buscar
        </Button>
        <Button variant="outlined" color="secondary" onClick={()=>setOpenModal(false)} fullWidth>
          Salir
        </Button>
      </Popup>
      </form>
      </FormProvider>
      </Grid>
    </Grid>
  )
}

export default RegisterCompanyView;