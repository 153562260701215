import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles( {
    containerCounterActiveForms: {
        backgroundColor: '#D66316',
        borderRadius: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', 
        width: '2rem',
        height: '2rem',
        margin: '0 0 0 1rem',
    },
    counterActiveForms:{ 
        color: '#FFF',
        fontSize: 14,
        fontWeight: 700
    },
    root: {
        marginLeft: '2rem'
    }
});

const Counter = ({ title, counter }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root}>
            <Typography variant="h3" color="textSecondary" gutterBottom>
                { title }
            </Typography>
            <div className={ classes.containerCounterActiveForms }>
                <span className={ classes.counterActiveForms }>
                    { counter }
                </span>
            </div>
        </Grid>
    )
}

export default Counter;
