import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core'
import CustomSelect from '../../../common/Select';
import Text from '../../../common/Text';
import { createForm } from './NewForm';

const NewFormModalView = () => {
    const [, setIsSubmitting] = useState(false);
    const history = useHistory();
    const methods = useForm({
       defaultValues: {
            nombre: '',
            nPostulacionesMax: 0,        
            roleId: 0   
       }
    });

    const onSubmit = async (data) => {
        await createForm(data, setIsSubmitting, history);
    }

    return (
    <FormProvider {...methods}>
    <form id="new-form" onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Text type="text" nombre="nombre" placeholder='Formulario x ...' />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Text type="number" rules={{min:{value:1, message: 'Debe ser superior a 0'}}} nombre="nPostulacionesMax" etiqueta="Postulaciones máximas" />
            </Grid>
            <Grid item xs={12} sm={9}>
                <CustomSelect nombre="roleId" etiqueta="Aplica a:" opciones={[{valor: 2, texto: "Viñas"}, {valor: 4, texto: "Guías"},]} required/>
            </Grid>
        </Grid>
    </form>
    </FormProvider>
    )
}

export default NewFormModalView;