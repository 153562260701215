import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { materialTableProps } from '../../../common/materialTable';
import MaterialTable from 'material-table';
import { generateExcelEvaluations, getAllRegions } from './RegionList';

const RegionsListView = ({ setLoading, form, isNational=false}) => {
    const [regions, setRegions] = useState([]);
    useEffect(() => {
        if(isNational){
            setRegions([{id: 0, nombre: 'Nacional', simbolo: '-'}]);
            return;
        }
        getAllRegions(setRegions);
    }, [ ])
    
    const handleClick = async (rowData) => {
        const filename = `EVALUACIONES_${rowData.nombre.split(' ').join('_')}`;
        await generateExcelEvaluations(`evaluations_by_region?region_id=${ rowData.id }&form_id=${ form }`, filename.toLocaleUpperCase(), setLoading);
    }
    
    return (
        <MaterialTable
        {...materialTableProps}
        options={{
            filtering: false,
            tableLayout: 'fixed',
            pageSize: 10
        }}
        columns={[
            {
                title: '#',
                field: 'simbolo',
            },
            {
                title: 'Región',
                field: 'nombre',
            },
            {
                title: 'Acciones',
                field: 'actions',
                render: rowData => <Tooltip title='Descargar reporte'>
                    <Button color='secondary' variant='outlined' onClick={() => handleClick(rowData)}> <GetApp/> </Button>
                </Tooltip>
            },
        ]}
        data={regions}
        />
    )
}

export default RegionsListView