import { toast } from "react-toastify";
import api from "../../../../helpers/jwt/apiFetch"
import { getUsuario } from "../../../../helpers/jwt/JWTAuth";

const getPostulation = async (postulationId, dispatch, history, setShowFinish) => {
    const { id } = getUsuario();
    const response = await api(`/postulation/${ postulationId }/${ id }`, 'GET');
    if(response.ok) {
        // Si postulacion no existe o no corresponde al usuario, redirige al inicio
        if(response.data.length == 0){
            history.push('/inicio');
            return;
        }
        const { form } = response.data[0];
        let questionSelected = {};
        // Se filtran preguntas que no han sido respondidas
        let questionsNotAnswered = form.questions.filter(q => Object.keys(q.answers).length == 0); 
        // Si todas las preguntas estan respondidas se selecciona ultima pregunta 
        if(questionsNotAnswered.length == 0) {
            questionSelected = form.questions[form.questions.length - 1];
        } else {
            questionSelected = questionsNotAnswered[0];
        }

        setShowFinish(response.data[0].is_complete);
        dispatch({ type: 'UPDATE_POSTULATION', payload: { 
            data: response.data[0],
            questionSelected,
            mandatoryQuestions: response.data[0].form.mandatory_questions,
            isComplete: response.data[0].is_complete,
        }});
        return;
    }
    toast('Ups, ocurrió un error al obtener el formulario', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const finishPostulation = async (postulationId, setIsLoading, history) => {
    setIsLoading(true);
    const response = await api(`/finish_postulation/${ postulationId }`, 'PUT');
    setIsLoading(false);

    if(response.ok){
        toast('Postulación finalizada correctamente', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        history.push('/');
        return;
    }

    toast('Ups, ocurrió un error al finalizar la postulación', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { getPostulation, finishPostulation }