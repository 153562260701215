import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import { PostulationContext } from '../../../../../contexts/postulationContext';

const useStyles = makeStyles({
    formControl: {
        margin: '1rem',
        minWidth: 120,
        width: '95%'
    },
});

 const QuestionSelectView = ( ) => {
    const classes = useStyles();
    const { state: { questionSelected, data }, dispatch } = useContext(PostulationContext);

    const handleChange = (event) => {
        dispatch({type: 'UPDATE_IS_COMPLETE', payload: false});
        dispatch({type: 'UPDATE_QUESTION_SELECTED', payload: { id: event.target.value }});
    };

    return (
        <>
        <span> Respondidas: {data.form.questions.filter(q => q.answers.length > 0).length} de {data.form.questions.length}</span>
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-native-simple">Preguntas</InputLabel>
            <Select
            native
            value={ questionSelected.id }
            onChange={handleChange}
            label="Preguntas"
            inputProps={{name: 'preguntas',id: 'outlined-native-simple'}}
            >
                {data.form.questions.map(q => (
                    <option key={q.id} value={q.id} style={{ color: q.answers.length == 0 ? '#ec6e7d' : '#64b684' }}>
                        Pregunta { q.orden }
                    </option>
                ))}
            </Select>
        </FormControl>
        </>
    );
}

export default QuestionSelectView;
