import { toast } from "react-toastify";
import api from "../../../../../helpers/jwt/apiFetch";
import FormDataRecursive from "../../../../../utils/FormDataRecursive";

const createQuestion = async (data, setOpenDialog, formContext) => {
    if([1,2].includes(data.question_type_id) && data.alternatives.length == 0){
        toast('Debes agregar al menos una alternativa', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'warning'
        });
        return;
    }

    const { dispatch, state: { form } } = formContext;
    data.orden = form.questions.length + 1;
    data.form_id = form.id;
    data.es_obligatoria = data.es_obligatoria == 2 ? 0 : 1;      
    data.es_admisible = data.es_admisible == 2 ? 0 : 1;  

    let formData = new FormDataRecursive();
    formData.appendRecursive(data);

    const response = await api('/questions', 'POST', formData);
    setOpenDialog(false);
    if(response.ok){
        toast('Pregunta agregada exitosamente!', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        dispatch({type: 'ADD_QUESTION', payload: response.data});
        return;
    }
    toast('Ups, ocurrió un error al intentar agregar la pregunta', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const editQuestion = async (data, setOpenDialog, formContext) => {
    if([3,4,5].includes(data.question_type_id) && data.alternatives.length > 0){
        toast('El tipo de pregunta especificado no debe tener alternativas', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'warning'
        });
        return;
    }

    if([1,2].includes(data.question_type_id) && data.alternatives.length == 0){
        toast('Debes agregar al menos una alternativa', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'warning'
        });
        return;
    }

    const { dispatch, state: { question } } = formContext;
    
    data.es_obligatoria = data.es_obligatoria == 2 ? 0 : 1;      
    data.es_admisible = data.es_admisible == 2 ? 0 : 1;  

    let formData = new FormDataRecursive();
    formData.appendRecursive(data);

    const response = await api(`/questions/${ question.id }`, 'POST', formData);
    
    if(response.ok){
        setOpenDialog(false);
        toast('Pregunta editada exitosamente!', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        dispatch({type: 'UPDATE_QUESTION', payload: response.data});
        return;
    }
    toast('Ups, ocurrió un error al intentar editar la pregunta', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

const deleteQuestion = async (data, dispatch) => {
    // TODO: SE DEBE DESPLEGAR UN MODAL DE CONFIRMACION ANTES DE ELIMINAR LA PREGUNTA
    const confirm = window.confirm('¿Está seguro de eliminar la pregunta?');
    if(!confirm) return;    
    const response = await api(`/questions/${ data.id }`, 'DELETE');
    if(response.ok){
        toast('Pregunta eliminada exitosamente!', {
            position: toast.POSITION.TOP_RIGHT,
            type: 'success'
        });
        dispatch({type: 'DELETE_QUESTION', payload: response.data});
        return;
    }
    toast('Ups, ocurrió un error al intentar eliminar la pregunta', {
        position: toast.POSITION.TOP_RIGHT,
        type: 'error'
    });
}

export { createQuestion, editQuestion, deleteQuestion }