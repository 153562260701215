import React from 'react';
import { Dialog, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTextTitle: {
        fontSize: 24,
        fontWeight: 600,
        color: '#2E4765',
    },
    dialogTitle: { 
        fontSize: 26,
        fontWeight: 700,
        marginTop: theme.spacing(2),
    },
    dialogBody: { 
        marginTop: theme.spacing(2),
    },
}));
    
const Popup = ({ title, children, openPopup, maxWidth = "md", fullWidth = true}) => {
    const classes = useStyles();
    return (
        <Dialog open={openPopup} maxWidth={maxWidth} fullWidth={fullWidth}>
            <DialogTitle>
                <Typography color="secondary" className={classes.dialogTitle}>
                    { title }
                </Typography>
                
            </DialogTitle>
            <DialogContent className={classes.dialogBody} dividers>
                { children }
            </DialogContent>
        </Dialog>
    )
}

export default Popup;
